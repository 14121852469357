import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import useUser from './useUser'

interface SignupFlowParams {
  email: string
  authId: string
  phoneNumber: string
  consentToMarketingSMS?: Date
  responderUuid?: string
  variantLabel?: string
  redirectUri: string
  loginOption?: 'otp_email' | 'otp_phone' | 'password'
}

export const useSignupFlow = () => {
  const { createUser } = useUser()
  const toast = useToast()

  const handleSignupSuccess = useCallback(
    async ({
      email,
      authId,
      phoneNumber,
      consentToMarketingSMS,
      responderUuid,
      variantLabel,
      redirectUri,
      loginOption,
    }: SignupFlowParams) => {
      try {
        const response = await createUser({
          email,
          authId,
          phoneNumber,
          consentToMarketingSMS,
          responderUuid,
          variantLabel,
          loginOption,
        })

        if (response) {
          window.location.href = redirectUri
          return true
        } else {
          toast({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return false
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return false
      }
    },
    [createUser, toast]
  )

  return { handleSignupSuccess }
}
