import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import Payment from 'containers/Onboarding/Payment'
import DashboardLayout from 'containers/Dashboard'
import Login from 'containers/Login'
import Signup from 'containers/Onboarding/Signup'
import ProtectedRoute from 'components/ProtectedRoute'
import PageNotFound from 'containers/PageNotFound'
import Logout from 'containers/Logout'
import RedirectToOnboarding from 'components/Formsort/RedirectToOnboarding'
import Programs from 'containers/Onboarding/Programs'
import PaymentPlansRoute from 'containers/Onboarding/PaymentPlans/PaymentPlansRoute'
import BookConsult from 'containers/Onboarding/BookConsult'
import HowDidYouHear from 'containers/Onboarding/HowDidYouHear'
import OnboardingRouter from 'containers/Onboarding'
import Content from 'containers/Content'
import Scheduling from 'containers/Scheduling'

import InsuranceInfo from 'containers/Onboarding/InsuranceInfo'
import HaveInsurance from 'containers/Onboarding/HaveInsurance'
import InsuranceEligibility from 'containers/Onboarding/InsuranceEligibility'
import InsuranceCoverage from 'containers/Onboarding/InsuranceCoverage'
import BookConsultConfirmed from 'containers/Onboarding/BookConsult/BookConsultConfirmed'
import DirectCheckEligibility from 'containers/Onboarding/DirectCheckEligibility'
import ConsentToTelehealth from 'containers/Onboarding/ConsentToTelehealth'
import SchedulingRedirect from 'containers/Scheduling/SchedulingRedirect'
import { ChakraProvider } from '@chakra-ui/react'
import PasswordReset from 'containers/Login/PasswordReset'
import VerifyPhone from 'containers/Login/VerifyPhone'
import VerifyEmail from 'containers/Login/VerifyEmail'

/**
 * List of all routes in the application. Despite being a single place
 * to change a route anywhere in the application, existing routes should
 * not be updated since we have many external links pointing to them
 */
export const RoutePaths = {
  WAITLIST: '/waitlist',
  NUTRITION: '/nutrition',
  CHECK_ELIGIBILITY: '/check-eligibility',
  ONBOARDING: '/onboarding',
  PROGRAMS: '/programs',
  CARE_MESSAGE: '/care-message',
  PAYMENT_PLANS: '/payment-plans',
  PAYMENT_PLANS_VIP: '/payment-plans/vip',
  BOOK_CONSULT: '/book-consult',
  BOOK_CONSULT_PUBLIC: '/consult',
  BOOK_CONSULT_REDIRECT: '/consult-redirect',
  BOOK_CONSULT_PUBLIC_CONFFIRMED: '/consult-confirmed',
  BOOK_CONSULT_COMPLETE: '/thanks',
  PAYMENT: '/payment',
  PAYMENT_COMPLETE: '/payment-complete',
  SCHEDULING_REDIRECT: '/scheduling-redirect',
  DASHBOARD: '/dashboard',
  DASHBOARD_CHAT: '/dashboard/chat',
  DASHBOARD_NEW_THREAD: '/dashboard/chat/new',
  DASHBOARD_CONTENT_LIBRARY: '/dashboard/library',
  DASHBOARD_MYHEALTH: '/dashboard/health',
  DASHBOARD_PROFILE: '/dashboard/profile',
  MANAGE_MEMBERSHIP: '/dashboard/membership',
  MANAGE_MEMBERSHIP_CANCEL: '/dashboard/membership-cancel',
  SCHEDULING: '/schedule',
  BEN: '/ben',
  LOGIN: '/login',
  PASSWORD_RESET: '/password-reset',
  SIGNUP: '/signup',
  LOGOUT: '/logout',
  APP_LOGIN: '/app-login',
  CONTENT: '/content',
  INSURANCE_INFO: '/insurance-info',
  HAVE_INSURANCE: '/have-insurance',
  INSURANCE_ELIGIBILITY: '/insurance-eligibility',
  INSURANCE_COVERED: '/insurance-covered',
  INSURANCE_NOT_COVERED: '/insurance-tests-covered',
  INSURANCE_UNKNOWN_COVERAGE: '/insurance-more-details',
  DIRECT_SIGNUP: '/direct/signup',
  DIRECT_MNC_SIGNUP: '/direct/mnc-signup',
  DIRECT_MNC_PROGRAM: '/direct/mnc-program',
  DIRECT_CHECK_ELIGIBILITY: '/direct/check-eligibility',
  DIRECT_PAYMENT_PLANS: '/direct/payment-plans',
  DIRECT_CHECKOUT: '/direct/checkout',
  MNC_PROGRAM: '/mnc-program',
  CONSENT_TO_TELEHEALTH: '/patient/consent',
  VERIFY_PHONE: '/verify-phone',
  VERIFY_EMAIL: '/verify-email',
  HOW_DID_YOU_HEAR: '/how-did-you-hear',
}

/**
 * Allowed callback URLs in Auth0. After updating the URLs in Auth0,
 * whitelist the route here
 * https://manage.auth0.com/dashboard/us/allara/applications/LgPMoa6ZhoTvCUqyFoE9cIC2PLDIbInb/settings
 */
export const ALLOWED_CALLBACK_URLS = [
  '/',
  RoutePaths.LOGIN,
  RoutePaths.APP_LOGIN,
  RoutePaths.ONBOARDING,
  RoutePaths.DASHBOARD,
  RoutePaths.DASHBOARD_CHAT,
  RoutePaths.DASHBOARD_MYHEALTH,
  RoutePaths.DASHBOARD_PROFILE,
  RoutePaths.MANAGE_MEMBERSHIP,
  RoutePaths.MANAGE_MEMBERSHIP_CANCEL,
  RoutePaths.SCHEDULING,
  RoutePaths.CONTENT,
  RoutePaths.PROGRAMS,
  RoutePaths.CARE_MESSAGE,
  RoutePaths.BOOK_CONSULT,
  RoutePaths.BOOK_CONSULT_COMPLETE,
  RoutePaths.PAYMENT,
  RoutePaths.PAYMENT_COMPLETE,
  RoutePaths.PAYMENT_PLANS,
  RoutePaths.PAYMENT_PLANS_VIP,
  RoutePaths.INSURANCE_INFO,
  RoutePaths.HAVE_INSURANCE,
  RoutePaths.INSURANCE_ELIGIBILITY,
  RoutePaths.INSURANCE_COVERED,
  RoutePaths.INSURANCE_NOT_COVERED,
  RoutePaths.INSURANCE_UNKNOWN_COVERAGE,
  RoutePaths.DIRECT_PAYMENT_PLANS,
  RoutePaths.DIRECT_MNC_PROGRAM,
  RoutePaths.DIRECT_CHECKOUT,
  RoutePaths.CONSENT_TO_TELEHEALTH,
  RoutePaths.PASSWORD_RESET,
  RoutePaths.HOW_DID_YOU_HEAR,
]

const Routes: React.FC = () => {
  const DashboardWrapper = () => {
    return (
      <ChakraProvider>
        <DashboardLayout />
      </ChakraProvider>
    )
  }

  return (
    <Switch>
      <Route path={RoutePaths.WAITLIST}>
        <Redirect to={RoutePaths.CHECK_ELIGIBILITY} />
      </Route>
      <Route path={RoutePaths.NUTRITION}>
        <RedirectToOnboarding />
      </Route>
      <Route path={RoutePaths.CHECK_ELIGIBILITY}>
        <RedirectToOnboarding />
      </Route>
      <Route path={RoutePaths.SIGNUP}>
        <Signup redirectPath={RoutePaths.ONBOARDING} />
      </Route>
      <Route path={RoutePaths.LOGIN}>
        <Login />
      </Route>
      <Route path={RoutePaths.APP_LOGIN}>
        <Login />
      </Route>
      <Route path={RoutePaths.PASSWORD_RESET}>
        <PasswordReset />
      </Route>
      <Route path={RoutePaths.LOGOUT}>
        <Logout />
      </Route>
      <Route path={RoutePaths.BEN}>
        <Redirect to={'/images/test.png'} />
      </Route>
      <Route path={RoutePaths.BOOK_CONSULT_PUBLIC}>
        <BookConsult redirect={RoutePaths.BOOK_CONSULT_REDIRECT} />
      </Route>
      <Route path={RoutePaths.BOOK_CONSULT_PUBLIC_CONFFIRMED}>
        <BookConsultConfirmed />
      </Route>
      <Route path={RoutePaths.DIRECT_SIGNUP}>
        <Signup redirectPath={RoutePaths.HAVE_INSURANCE} />
      </Route>
      <Route path={RoutePaths.DIRECT_MNC_SIGNUP}>
        <Redirect to={RoutePaths.SIGNUP} />
      </Route>
      <Route path={RoutePaths.DIRECT_CHECK_ELIGIBILITY}>
        <DirectCheckEligibility />
      </Route>
      <Route path={RoutePaths.MNC_PROGRAM}>
        <Redirect to={RoutePaths.PROGRAMS} />
      </Route>
      <ProtectedRoute path={RoutePaths.DIRECT_MNC_PROGRAM}>
        <Redirect to={RoutePaths.PROGRAMS} />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.ONBOARDING}>
        <OnboardingRouter />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.PROGRAMS}>
        <Programs />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.CARE_MESSAGE}>
        <Redirect to={`${RoutePaths.PAYMENT_PLANS}${window.location.search}`} />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.PAYMENT_PLANS}>
        <PaymentPlansRoute />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.BOOK_CONSULT}>
        <BookConsult redirect={RoutePaths.BOOK_CONSULT_COMPLETE} />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.PAYMENT}>
        <Payment />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.INSURANCE_INFO}>
        <InsuranceInfo />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.CONSENT_TO_TELEHEALTH}>
        <ConsentToTelehealth />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.HAVE_INSURANCE}>
        <HaveInsurance />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.INSURANCE_ELIGIBILITY}>
        <InsuranceEligibility />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.INSURANCE_COVERED}>
        <InsuranceCoverage coverage="eligible" />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.INSURANCE_NOT_COVERED}>
        <InsuranceCoverage coverage="ineligible" />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.INSURANCE_UNKNOWN_COVERAGE}>
        <InsuranceCoverage coverage="unknown" />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.PAYMENT_COMPLETE}>
        <ConsentToTelehealth />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.HOW_DID_YOU_HEAR}>
        <HowDidYouHear canRedirect={true} />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.BOOK_CONSULT_COMPLETE}>
        <HowDidYouHear />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.DASHBOARD_CHAT}>
        <DashboardWrapper />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.DASHBOARD_MYHEALTH}>
        <DashboardWrapper />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.DASHBOARD_PROFILE}>
        <DashboardWrapper />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.DASHBOARD}>
        <DashboardWrapper />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.SCHEDULING_REDIRECT}>
        <SchedulingRedirect />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.SCHEDULING}>
        <Scheduling />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.CONTENT}>
        <Content />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.DIRECT_PAYMENT_PLANS}>
        <PaymentPlansRoute />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.DIRECT_CHECKOUT}>
        <Payment isDirectFlow={true} />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.MANAGE_MEMBERSHIP}>
        <DashboardWrapper />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.MANAGE_MEMBERSHIP_CANCEL}>
        <DashboardWrapper />
      </ProtectedRoute>
      <Route exact path="/">
        <Redirect to={`${RoutePaths.ONBOARDING}${window.location.search}`} />
      </Route>
      <ProtectedRoute path={RoutePaths.VERIFY_PHONE}>
        <VerifyPhone />
      </ProtectedRoute>
      <ProtectedRoute path={RoutePaths.VERIFY_EMAIL}>
        <VerifyEmail />
      </ProtectedRoute>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  )
}

export default Routes
