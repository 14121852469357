import { User } from '@allara-health/source-health-client'
import ProfileImage from 'components/SourceProfileImage/ProfileImage'
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link.svg'
import { useCallback } from 'react'
import { Text, HStack, VStack } from '@chakra-ui/react'

interface ProviderDetailsProps {
  provider: User
  className?: string
  externalLink?: string
}

const ProviderDetails: React.FC<ProviderDetailsProps> = ({
  provider,
  className,
  externalLink,
}) => {
  const openExternalLink = useCallback(() => {
    window.open(externalLink, '_blank')
  }, [externalLink])

  return (
    <div className={`provider ${className}`}>
      <HStack align="center" spacing={2}>
        <ProfileImage user={provider} className="provider-image" />
        <VStack align="flex-start" spacing={0}>
          <HStack spacing={2}>
            <Text as="h6" mb={0}>
              {provider.first_name} {provider.last_name}
            </Text>
            {externalLink !== undefined && (
              <ExternalLinkIcon
                className="cursor-pointer"
                onClick={openExternalLink}
              />
            )}
          </HStack>
          {provider.display_title && (
            <Text color={'var(--gray-medium)'} mb={0}>
              {provider.display_title}
            </Text>
          )}
        </VStack>
      </HStack>
    </div>
  )
}

export default ProviderDetails
