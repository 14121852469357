import { AppointmentType } from '@allara-health/source-health-client'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { addMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { memo } from 'react'
import { Text, VStack } from '@chakra-ui/react'

interface TimingDetailsProps extends WithAccountProps {
  date: Date
  timeZone: string
  appointmentType: AppointmentType
  className?: string
}

const TimingDetails: React.FC<TimingDetailsProps> = ({
  date,
  timeZone,
  appointmentType,
}) => {
  return (
    <VStack alignItems="flex-start">
      <Text as="h6" mb={0}>
        {formatInTimeZone(date, timeZone, 'EEEE, MMMM d, yyyy')}
      </Text>
      <Text as="h6" mb={0}>
        {formatInTimeZone(date, timeZone, 'h:mm	aaa')} -{' '}
        {formatInTimeZone(
          addMinutes(date, appointmentType.duration),
          timeZone,
          'h:mm aaa'
        )}
      </Text>
    </VStack>
  )
}

export default memo(withAccount(TimingDetails))
