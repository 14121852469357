import useAppointmentNotes, {
  AppointmentNote,
} from 'hooks/useAppointments/useAppointmentNotes'
import { Appointment } from 'hooks/useAppointments/useAppointments'
import { useCallback, useMemo } from 'react'
import { Amplitude } from 'utils/amplitude.utils'
import { formatName } from 'utils/provider.utils'
import { formatDate } from 'utils/time.utils'

interface AppointmentTableRowProps {
  appointment: Appointment
  handleSelectNote: (note: AppointmentNote) => void
}

const AppointmentTableRow: React.FC<AppointmentTableRowProps> = ({
  appointment,
  handleSelectNote,
}) => {
  const { appointmentNotes } = useAppointmentNotes()
  const note = useMemo(
    () => appointmentNotes?.find((n) => n.appointment.id === appointment.id),
    [appointmentNotes]
  )

  const handleOpenNote = useCallback(() => {
    if (note) {
      handleSelectNote(note)
      Amplitude.appointmentNoteOpen({
        appointment_note_id: note.id,
        appointment_id: appointment.id,
        appointment_type_name: note.appointment.type.category,
        source: 'my_health',
      })
    }
  }, [note])

  return (
    <tr>
      <td>{formatName(appointment.provider)}</td>
      <td>{formatDate(new Date(appointment.time))}</td>
      {note && (
        <td
          className="spaced-capital-letters btn-text"
          onClick={handleOpenNote}
        >
          View Note
        </td>
      )}
    </tr>
  )
}

export default AppointmentTableRow
