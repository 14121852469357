import { Banner } from 'components/Banners'
import { PrimaryButton } from 'components/Button'
import Select from 'components/Select'
import TextArea from 'components/TextArea'
import TextField from 'components/TextField'
import { RoutePaths } from 'containers/Core/Routes'
import useBanner from 'hooks/useBanner/useBanner'
import useThreads, { MessageSubject } from 'hooks/useThreads/useThreads'
import useUser from 'hooks/useUser/useUser'
import { memo, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'
import ChatContainer from './ChatContainer'
import BlockContent from '@sanity/block-content-to-react'
import { BannerConstants } from '../../../hooks/useBanner/useBanner'
import useSearchParams from 'hooks/useSearchParams'
import { Amplitude } from 'utils/amplitude.utils'

// These constants are defined in the DB and are used to populate the dropdown.
// TODO: Move these out of the DB, first step was to reference them here on the FE.
const SUBJECTS: MessageSubject[] = [
  {
    id: 1,
    subject: 'Insurance Coverage or Eligibility',
    careTeamMember: 'concierge',
  },
  { id: 2, subject: 'Question about My Results', careTeamMember: 'concierge' },
  {
    id: 3,
    subject: 'Medication Question/Concern',
    careTeamMember: 'concierge',
  },
  {
    id: 4,
    subject: 'Question about my subscription or billing',
    careTeamMember: 'concierge',
  },
  {
    id: 5,
    subject: 'Update Personal Information',
    careTeamMember: 'concierge',
  },
  { id: 6, subject: 'Technical Issue/Support', careTeamMember: 'concierge' },
  { id: 7, subject: 'Question about My Referral', careTeamMember: 'concierge' },
  { id: 8, subject: 'Help scheduling my visit', careTeamMember: 'concierge' },
  { id: 9, subject: 'Something Else', careTeamMember: 'concierge' },
]

const CreateThread: React.FC = () => {
  const history = useHistory()
  const { user } = useUser()
  const searchParams = useSearchParams()
  const { handleCreateThread } = useThreads()
  const { banner } = useBanner(BannerConstants.MESSAGING)
  const [message, setMessage] = useState<string>('')
  const [subject, setSubject] = useState<MessageSubject | undefined>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [customSubject, setCustomSubject] = useState<string>('')

  const initialSubjectId = searchParams.get('sbj')

  const options = useMemo(() => {
    if (!SUBJECTS) {
      return []
    } else {
      return SUBJECTS.map((s) => ({ value: s.id, label: s.subject }))
    }
  }, [SUBJECTS])

  const handleChangeSubject = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subjectId = e.target.value
    const subject = SUBJECTS?.find((s) => s.id.toString() === subjectId)

    if (subject) {
      setSubject(subject)
    }
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  }

  const handleCustomSubjectChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomSubject(e.target.value)
  }

  const handleSubmit = () => {
    if (subject && message) {
      setIsSubmitting(true)
      handleCreateThread({ subject, message, customSubject })
        .then((thread) => {
          if (thread) {
            Amplitude.messageSendDone({
              attachments_num: 0,
              message_id: thread.last_message.id,
              is_new_message: true,
              selected_topic: subject.subject,
            })
            history.push(`${RoutePaths.DASHBOARD_CHAT}/${thread?.id}`)
          }
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  useEffect(() => {
    if (SUBJECTS?.length) {
      let initialSubject

      if (initialSubjectId) {
        initialSubject = SUBJECTS.find(
          (s) => s.id.toString() === initialSubjectId
        )
      }

      setSubject(initialSubject ?? SUBJECTS[0])
    }
  }, [SUBJECTS])

  if (user?.messagingDeactivated) {
    return <Redirect to={RoutePaths.DASHBOARD_CHAT} />
  }

  return (
    <ChatContainer>
      <div className="mt-4 create-thread-container">
        <Select
          label="Select Topic"
          options={options}
          value={subject?.id}
          onChange={handleChangeSubject}
        />
        {subject?.id === SUBJECTS?.length && (
          <Form.Group>
            <Form.Label>Subject</Form.Label>
            <TextField
              value={customSubject}
              placeholder="Describe the topic"
              onChange={handleCustomSubjectChange}
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <TextArea value={message} onChange={handleMessageChange} />
        </Form.Group>
        <PrimaryButton
          className="spaced-capital-letters fs-2 start-button"
          onClick={handleSubmit}
          disabled={!subject || !message}
          isLoading={isSubmitting}
        >
          Start
        </PrimaryButton>
        <Form.Group className="mt-3">
          <Form.Label>Disclaimer:</Form.Label>
          <p>
            Allara does not provide emergency medical services and if you are
            having a medical emergency you should call 911
          </p>
        </Form.Group>

        <Banner
          visible={banner?.bannerStatus ?? false}
          variant="warning"
          message={
            <BlockContent
              // Array of portable text blocks
              blocks={banner?.description}
              // Custom serializers for marks, blocks
              serializers={{
                marks: (props: {
                  children: React.ReactNode | string | undefined
                }) => {
                  return <span>{props.children}</span>
                },
              }}
            />
          }
        />
      </div>
    </ChatContainer>
  )
}

export default memo(CreateThread)
