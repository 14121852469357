import { memo, useCallback } from 'react'
import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import momentTZ from 'moment-timezone'
import { ReactComponent as ChevronLeft } from 'assets/images/chevron-left.svg'
import AppLogo from 'components/AppLogo'
import { format } from 'date-fns'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { utcToZonedTime } from 'date-fns-tz'
import { Skeleton, ChakraProvider } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import useAppointments from 'hooks/useAppointments/useAppointments'
import { generateAppointmentLink } from 'utils/appointments.utils'
import { BookingEventSource } from 'utils/amplitude.types'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import {
  useIsInsurancePatient,
  isInitialMedical as isInitialMedicalUtils,
  isInitialNutrition as isInitialNutritionUtils,
  isSecondMedical as isSecondMedicalUtils,
} from 'utils/program.utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useSearchParams from 'hooks/useSearchParams'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import { RoutePaths } from 'containers/Core/Routes'
import { InitialRdCTACard } from 'components/CTACard'

const Navigation: React.FC<WithAccountProps> = ({ user }) => {
  const {
    appointmentType,
    isLoadingApptType,
    currentScreen,
    previousScreen,
  } = useSchedulingContext()
  const history = useHistory()
  const { hasBookedRd } = useAppointments()
  const searchParams = useSearchParams()

  const source = searchParams.get('source')

  const { appointmentTypeId: rdAppointmentTypeId } = useAppointmentTypeId(
    'Initial RD'
  )
  const isInsurancePatient = useIsInsurancePatient(user)
  const { noFeeInsuranceMembership } = useFlags()

  const isScreenAppointmentTypeSelect =
    currentScreen === 'appointmentTypeSelect'
  const shouldShowStepper =
    currentScreen !== 'success' && !isScreenAppointmentTypeSelect

  const showAppointmentInfo = !isLoadingApptType && !!appointmentType

  const isInitialMedical = isInitialMedicalUtils(appointmentType ?? null)
  const isInitialNutrition = isInitialNutritionUtils(appointmentType ?? null)
  const isSecondMedical = isSecondMedicalUtils(appointmentType ?? null)

  const shouldShowCTA = currentScreen === 'success' && isInsurancePatient
  // Cases where we should show the initial rd cta
  const isApplicableAppointmentType = isInitialMedical || isSecondMedical
  const shouldShowInitialRDCTA =
    !hasBookedRd &&
    rdAppointmentTypeId &&
    isApplicableAppointmentType &&
    shouldShowCTA

  const initialRDSource = isSecondMedical
    ? BookingEventSource.SMV_BOOK_DONE
    : BookingEventSource.INITIAL_MD_BOOK_DONE

  if (currentScreen === 'success' && isApplicableAppointmentType) {
    window.scrollTo(0, 0)
  }

  const shouldShowBackButton =
    !noFeeInsuranceMembership ||
    !(isInsurancePatient && isInitialMedical && source === 'onboarding')

  const shouldGoToDashboard =
    !noFeeInsuranceMembership ||
    !(
      isInsurancePatient &&
      ((isInitialMedical && source === 'onboarding') ||
        (isInitialNutrition && source === 'initial_book_md_done'))
    )

  const returnToDashboard = useCallback(
    () => history.push(RoutePaths.DASHBOARD + `?from=scheduling`),
    []
  )

  const goToHowDidYouHear = useCallback(() => {
    history.push(RoutePaths.HOW_DID_YOU_HEAR + `?from=scheduling`)
  }, [])

  return (
    <ChakraProvider>
      <div className="px-3 pl-lg-5 mt-3 mt-lg-5">
        <AppLogo />
        {(shouldShowBackButton || currentScreen === 'success') && (
          <CloseIcon
            className="cursor-pointer d-block d-lg-none position-absolute top-2 right-3 m-3 fs-1"
            onClick={
              shouldGoToDashboard ? returnToDashboard : goToHowDidYouHear
            }
            width="18px"
            height="18px"
          />
        )}
        {currentScreen !== 'success' && (
          <div className="mt-5">
            {shouldShowBackButton && (
              <div
                className="d-flex align-items-center cursor-pointer mb-2"
                onClick={previousScreen}
              >
                <ChevronLeft className="nav-icon-small text-primary" />
                <p className="spaced-capital-letters mb-0 ml-2 text-primary">
                  Back
                </p>
              </div>
            )}
            <p className="ff-inter-medium fs-6">
              {isScreenAppointmentTypeSelect &&
                'Please select your appointment type'}
              {!isScreenAppointmentTypeSelect && (
                <Skeleton
                  isLoaded={showAppointmentInfo}
                  fontFamily={'Instrument Serif'}
                  fontSize="28px"
                >
                  {`${appointmentType?.name}, ${appointmentType?.duration} min`}
                </Skeleton>
              )}
            </p>
          </div>
        )}
        {shouldShowInitialRDCTA && (
          <InitialRdCTACard
            clickHandler={() => {
              history.push(
                generateAppointmentLink(rdAppointmentTypeId, {
                  source: initialRDSource,
                  patient_action_id: null,
                  patient_action_definition: null,
                })
              )
              window.location.reload()
            }}
          />
        )}
        {shouldShowStepper && <MobileViewStepper />}
        {shouldShowStepper && <DesktopViewStepper user={user} />}
      </div>
    </ChakraProvider>
  )
}

const DesktopViewStepper: React.FC<WithAccountProps> = ({ user }) => {
  const { currentScreen, timeZone, dateTime, provider } = useSchedulingContext()
  const zonedTime = utcToZonedTime(dateTime ?? new Date(), timeZone ?? '')

  const getTimeZone = (): string => {
    return momentTZ.tz(timeZone ?? '').format('z [(GMT] Z[)]')
  }

  return (
    <div className=" d-flex align-items-start ml-3 mt-3 hide">
      <div className="d-flex flex-column align-items-center">
        <CustomCheckIcon checked={currentScreen !== 'location'} />
        <div
          className={`vertical-line ${
            currentScreen !== 'location' ? 'vertical-line-active' : ''
          }`}
        />
        <CustomCheckIcon
          checked={currentScreen === 'confirm' || currentScreen === 'success'}
        />
        <div
          className={`vertical-line ${
            currentScreen === 'confirm' || currentScreen === 'success'
              ? 'vertical-line-active'
              : ''
          }`}
        />
        <CustomCheckIcon checked={currentScreen === 'success'} />
      </div>
      <div className="d-flex flex-column justify-content-between align-items-stretch h-100 ml-3">
        <div className="height-139">
          <h1 className="ff-inter-medium fs-6">Location</h1>
          {currentScreen !== 'location' && (
            <span className="text-uppercase">
              {user.waitlistUser.state.fullName}, {getTimeZone()}
            </span>
          )}
        </div>
        <div className="height-139">
          <h1 className="ff-inter-medium fs-6">Appointment</h1>
          {(currentScreen === 'confirm' || currentScreen === 'success') && (
            <span className="text-uppercase">
              {`${format(zonedTime, 'EE')}, ${format(
                zonedTime,
                'MMMM'
              )} ${zonedTime.getDate()},`}{' '}
              <br />
              {`${format(zonedTime, 'p')}, ${provider?.first_name} ${
                provider?.last_name
              }`}
            </span>
          )}
        </div>
        <div className="height-139">
          <h1 className="ff-inter-medium fs-6">Confirmation</h1>
        </div>
      </div>
    </div>
  )
}

const MobileViewStepper: React.FC = () => {
  const { currentScreen } = useSchedulingContext()

  return (
    <div className="d-flex d-lg-none flex-column w-100 align-item-center justify-content-center  mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <CustomCheckIcon checked={currentScreen !== 'location'} />
        <div
          className={`horizontal-line ${
            currentScreen !== 'location' ? 'horizontal-line-active' : ''
          }`}
        />
        <CustomCheckIcon
          checked={currentScreen === 'confirm' || currentScreen === 'success'}
        />
        <div
          className={`horizontal-line ${
            currentScreen === 'confirm' || currentScreen === 'success'
              ? 'horizontal-line-active'
              : ''
          }`}
        />
        <CustomCheckIcon checked={currentScreen === 'success'} />
      </div>
      <div className="d-flex flex-row justify-content-between align-items-start mt-2">
        <div className="width-139">
          <h1 className="ff-inter-medium fs-3">Location</h1>
        </div>
        <div className="width-139">
          <h1 className="ff-inter-medium fs-3">Appointment</h1>
        </div>
        <div className="width-139">
          <h1 className="ff-inter-medium fs-3">Confirmation</h1>
        </div>
      </div>
    </div>
  )
}

type CustomCheckIconProp = {
  checked?: boolean
}

const CustomCheckIcon: React.FC<CustomCheckIconProp> = ({ checked }) => {
  return (
    <div
      className={`check d-flex justify-content-center align-items-center text-white ${
        checked ? 'check-filled' : ''
      }`}
    >
      <CheckIcon />
    </div>
  )
}

export default memo(withAccount(Navigation))
