import { Heading, Box, Text, Link } from '@chakra-ui/react'
import { PROGRAMS } from 'utils/program.utils'
import { ChakraPrimaryButton } from 'components/Button'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'

interface RenewMembershipCardProps {
  product: string
}

const RenewMembershipCard = ({ product }: RenewMembershipCardProps) => {
  const history = useHistory()
  return (
    <Box>
      <Heading as="h3" size="m" fontWeight={'semibold'} textAlign={'center'}>
        Renew your membership
      </Heading>
      <Text mt={4}>
        You do not currently have an active membership. Please reactivate your
        membership below to resume care.
      </Text>
      {product === PROGRAMS.FULL_PROGRAM && (
        <Text>
          Your insurance eligibility may have changed. Check{' '}
          <Link
            href="https://www.allarahealth.com/services"
            color={'var(--blue-light)'}
            target="_blank"
          >
            here
          </Link>{' '}
          to see if you now qualify for Allara&rsquo;s insurance offering. If
          you&rsquo;re eligible and you&rsquo;d like to make the switch, please
          reach out to{' '}
          <Link
            href="mailto:billing@allarahealth.com"
            color={'var(--blue-light)'}
          >
            billing@allarahealth.com
          </Link>{' '}
          to update your information.
        </Text>
      )}
      <Box textAlign={'center'}>
        <ChakraPrimaryButton
          onClick={() => history.push(RoutePaths.HAVE_INSURANCE)}
        >
          Reactivate membership
        </ChakraPrimaryButton>
      </Box>
    </Box>
  )
}

export default RenewMembershipCard
