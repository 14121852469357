import { useState, useEffect } from 'react'
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import './styles.scss'
import usePayment from 'hooks/usePayment/usePayment'
import {
  PaymentPlan,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import { Appearance } from '@stripe/stripe-js'
import usePaymentPlan from 'hooks/usePayment/usePaymentPlan'
import { Box, HStack, Text, useToast } from '@chakra-ui/react'
import { Checkbox, Button, VStack } from '@chakra-ui/react'
import { Formik, Form as FormikForm } from 'formik'
import { InfoIcon } from '@chakra-ui/icons'
// Initialize Stripe
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

interface PaymentFormProps {
  onError: (error: string) => void
  handleBook: () => void
  previousScreen: () => void
}

interface PaymentBoxProps {
  handleBook: () => void
  previousScreen: () => void
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  onError,
  handleBook,
  previousScreen,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isProcessing, setIsProcessing] = useState(false)
  const { createPaymentIntent } = usePayment()
  const { selectedPlan, setSelectedPlan } = useOnboardingContext()
  const { fetchPlan } = usePaymentPlan()
  const toast = useToast()

  const fetch0InsurancePlan = async () => {
    const data = await fetchPlan(
      process.env.REACT_APP_0_DOLLAR_INSURANCE_PRICE_ID ?? ''
    )

    if (data) {
      setSelectedPlan(data)
    }
  }

  useEffect(() => {
    fetch0InsurancePlan()
  }, [])

  const handleSubmit = async (values: { acceptedTerms: boolean }) => {
    if (!stripe || !elements) {
      return
    }

    setIsProcessing(true)

    try {
      // 1. Confirm the setup (creates payment method)
      const { error: setupError, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-complete`,
        },
        redirect: 'if_required',
      })

      if (setupError) {
        throw new Error(setupError.message)
      }

      if (!setupIntent?.payment_method) {
        throw new Error('No payment method was created')
      }

      // 2. Create subscription with the new payment method
      const response = await createPaymentIntent(selectedPlan as PaymentPlan)

      handleBook()
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : 'An error occurred'
      toast({
        title: 'Payment Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
      onError(errorMessage)
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <Formik
      initialValues={{
        acceptedTerms: false,
        acceptedCancellationPolicy: false,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormikForm>
          <>
            <Box
              p={[2, 3, 4]}
              bg="white"
              borderRadius="md"
              width={['100%', '100%', '3xl']}
            >
              <VStack spacing={4} align="stretch">
                <Box bg={'var(--blue-lightest)'} p={4} borderRadius="md">
                  <HStack alignItems="start" spacing={3}>
                    <InfoIcon mt={2} />
                    <Box>
                      <Text fontSize="lg" fontWeight="bold" mb={1}>
                        You will not be charged today
                      </Text>
                      <Text fontSize="sm" mb={0}>
                        We will only verify your payment method and keep it on
                        file in case of copays, coinsurance, or deductibles
                        assigned by your insurance.
                      </Text>
                    </Box>
                  </HStack>
                </Box>

                <Box>
                  <PaymentElement
                    options={{
                      layout: 'tabs',
                      wallets: {
                        applePay: 'auto',
                        googlePay: 'auto',
                      },
                    }}
                  />
                </Box>

                <Checkbox
                  isChecked={values.acceptedTerms}
                  onChange={(e) =>
                    setFieldValue('acceptedTerms', e.target.checked)
                  }
                  size="sm"
                >
                  By checking this box you acknowledge that your payment
                  information will be kept on file for outstanding charges such
                  as copays or coinsurance.
                </Checkbox>
                <Checkbox
                  isChecked={values.acceptedCancellationPolicy}
                  onChange={(e) =>
                    setFieldValue(
                      'acceptedCancellationPolicy',
                      e.target.checked
                    )
                  }
                  size="sm"
                >
                  Please confirm you understand and agree to our Cancellation
                  and No Show Policy (see below).
                </Checkbox>
              </VStack>
            </Box>

            <Button
              backgroundColor="white"
              color="var(--blue-dark)"
              borderColor="var(--blue-dark)"
              width={['45%', '30%', '15%']}
              mt={4}
              mb={4}
              mr={4}
              onClick={previousScreen}
              variant="outline"
            >
              Previous
            </Button>

            <Button
              type="submit"
              backgroundColor="var(--blue-dark)"
              color="white"
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
                color: 'white',
              }}
              isLoading={isProcessing}
              isDisabled={
                !stripe ||
                isProcessing ||
                !values.acceptedTerms ||
                !values.acceptedCancellationPolicy
              }
              width={['45%', '30%', '15%']}
              mt={4}
              mb={4}
            >
              Book
            </Button>

            <Box
              color="var(--gray-dark)"
              width={['100%', '100%', '3xl']}
              px={[2, 3, 0]}
            >
              <Text
                fontSize="xs"
                textTransform="uppercase"
                mb={0}
                color="var(--gray-dark)"
              >
                Billing Info:
              </Text>
              <Text fontSize="xs">
                You will be responsible for any copays, coinsurance, or
                deductibles assigned by your insurance, if any. We will use the
                credit/debit card on file to charge you once your insurance
                processes the claim, which typically happens 1-3 weeks after
                your appointment. We know that not all insurances cover
                nutrition benefits, so if your insurance does not cover
                nutrition services, we have a discounted nutrition visit cash
                pay price of $75 per visit. We will attempt to bill for these
                visits before we charge you. 
              </Text>
              <Text fontSize="xs" mb={6}>
                If you have an outstanding balance future appointments may be
                cancelled/blocked from booking until balances are settled.
              </Text>
            </Box>
          </>
        </FormikForm>
      )}
    </Formik>
  )
}

// Create a separate component for the wrapped form
const PaymentBoxContent: React.FC<PaymentBoxProps> = ({
  handleBook,
  previousScreen,
}) => {
  const { createSetupIntent } = usePayment()
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  useEffect(() => {
    const initializePayment = async () => {
      try {
        const { clientSecret } = await createSetupIntent()
        setClientSecret(clientSecret)
      } catch (error) {
        console.error('Error initializing setup:', error)
      }
    }

    if (!clientSecret) {
      // Only call if we don't have a clientSecret
      initializePayment()
    }
  }, [])

  if (!clientSecret) {
    return <div>Loading...</div>
  }

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: 'var(--blue-dark)',
      colorBackground: '#ffffff',
      colorText: '#30313d',
    },
  }

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        onError={(error) => console.error('Payment error:', error)}
        handleBook={handleBook}
        previousScreen={previousScreen}
      />
    </Elements>
  )
}

// Main component no longer uses Stripe hooks directly
const PaymentBox: React.FC<WithAccountProps & PaymentBoxProps> = ({
  handleBook,
  previousScreen,
}) => {
  return (
    <PaymentBoxContent
      handleBook={handleBook}
      previousScreen={previousScreen}
    />
  )
}

export default withAccount(PaymentBox)
