import { useStytch, useStytchSession } from '@stytch/react'
import { EligibleInsurances, QueryKey } from 'api/insurance/routes'
import { useQuery, useQueryClient } from 'react-query'
import useEligibilityInfo from './useUser/useEligibilityInfo'
import { useEffect } from 'react'

export interface Insurance {
  title: string
  provider: string
}

interface UseEligibleInsuranceInterface {
  isLoading: boolean
  error: unknown
  insurances: Insurance[]
}

const useEligibleInsurances = (): UseEligibleInsuranceInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()
  const { waitlistUser } = useEligibilityInfo()
  const queryClient = useQueryClient()

  const fetchEligibleInsurances = async (): Promise<Insurance[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    return fetch(`${process.env.REACT_APP_SERVER_URL}${EligibleInsurances}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json()
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    QueryKey,
    fetchEligibleInsurances,
    {
      enabled: !!session && !!stytch && !!waitlistUser,
      staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
      cacheTime: 10 * 60 * 1000, // Keep in cache for 10 minutes
      refetchOnMount: 'always', // Only refetch on mount
      refetchOnWindowFocus: false, // Don't refetch on window focus
      retry: false,
    }
  )

  // Only force refresh when explicitly needed (like after insurance updates)
  useEffect(() => {
    const shouldRefetch = waitlistUser?.insurancePlan === undefined

    if (shouldRefetch) {
      queryClient.invalidateQueries(QueryKey)
    }
  }, [waitlistUser?.insurancePlan, queryClient])

  return {
    isLoading,
    error,
    insurances: data ?? [],
  }
}

export default useEligibleInsurances
