/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Heading,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { TimelineItem } from './Timeline'

const StaticTimeline: React.FC<{ timelineItems: TimelineItem[] }> = ({
  timelineItems,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [isTimelineVisible, setIsTimelineVisible] = useState(true)

  useEffect(() => {
    if (isMobile) {
      setIsTimelineVisible(false)
    } else {
      setIsTimelineVisible(true)
    }
  }, [isMobile])

  const toggleTimelineVisibility = () =>
    setIsTimelineVisible(!isTimelineVisible)

  return (
    <Box
      p={4}
      pb={0}
      bg={'#f6f5ef'}
      mb={8}
      mr={4}
      ml={4}
      width="100%"
      minHeight={isMobile ? '60px' : 'auto'}
      borderRadius={'md'}
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading
          size="lg"
          fontFamily={'Canela'}
          fontWeight={'normal'}
          fontSize={'24px'}
        >
          Getting Started with Allara
        </Heading>
        <IconButton
          aria-label={isTimelineVisible ? 'Hide timeline' : 'Show timeline'}
          icon={isTimelineVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
          fontSize="30px"
          onClick={toggleTimelineVisibility}
          variant="ghost"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        />
      </Flex>

      {isTimelineVisible && (
        <>
          <Text mb={8}>
            We're so happy to have you here! We will tailor care to your needs,
            but here is an overview of what you can expect from your first few
            visits as you get started with Allara.
          </Text>

          <Flex
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'stretch' : 'center'}
          >
            {timelineItems.map((item, index) => (
              <React.Fragment key={`Timeline-Item-Mobile-${index}`}>
                {isMobile ? (
                  <Flex mb={8} flexDirection="row" alignItems="stretch">
                    <Box
                      bg={item.bg}
                      mr={4}
                      width="50px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="relative"
                      zIndex={timelineItems.length - index}
                      borderTopRadius={'md'}
                      _before={
                        index > 0
                          ? {
                              content: '""',
                              position: 'absolute',
                              top: '-25px',
                              left: '0',
                              transform: 'translateY(100%) translateY(-1px)',
                              width: '0',
                              height: '0',
                              borderLeft: `25px solid ${item.bg}`,
                              borderRight: `25px solid ${item.bg}`,
                              borderTop: `25px solid ${'#f6f5ef'}`,
                              zIndex: timelineItems.length + 1,
                            }
                          : undefined
                      }
                      _after={
                        index >= 0
                          ? {
                              content: '""',
                              position: 'absolute',
                              bottom: '-24.90px',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              width: '0',
                              height: '0',
                              borderLeft: '25px solid transparent',
                              borderRight: '25px solid transparent',
                              borderTop: `25px solid ${item.bg}`,
                              zIndex: 1,
                            }
                          : undefined
                      }
                    ></Box>
                    <Box flex={1}>
                      <Heading size="sm" fontWeight="bold" mb={2}>
                        {item.text}
                      </Heading>
                      <Heading size="sm" fontWeight="semibold" mb={2}>
                        {item.title}
                      </Heading>
                      <Text fontSize="sm" mb={2}>
                        {item.description}
                      </Text>
                      {item.additionalTitle && (
                        <>
                          <Heading size="sm" mb={2} fontWeight="semibold">
                            {item.additionalTitle}
                          </Heading>
                          <Text fontSize="sm">
                            {item.additionalDescription}
                          </Text>
                        </>
                      )}
                    </Box>
                  </Flex>
                ) : (
                  <Box
                    bg={item.bg}
                    fontWeight="bold"
                    flex={item.flex}
                    height="50px"
                    mr={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    zIndex={3 - index}
                    ml={index > 0 ? '-25px' : 0}
                    borderLeftRadius={index === 0 ? 'md' : 'none'}
                    _after={
                      index <= timelineItems.length - 1
                        ? {
                            content: '""',
                            position: 'absolute',
                            right: '-25px',
                            top: '0',
                            width: '0',
                            height: '0',
                            borderTop: '25px solid transparent',
                            borderBottom: '25px solid transparent',
                            borderLeft: `25px solid ${item.bg}`,
                            zIndex: 1,
                          }
                        : undefined
                    }
                  >
                    <Text
                      fontSize={
                        index > 0 ? { base: 'sm', md: 'xs', lg: 'sm' } : 'sm'
                      }
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      ml={{ base: '8px', md: '40px' }}
                      mb={0}
                    >
                      {item.text}
                    </Text>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Flex>

          {!isMobile && isTimelineVisible && (
            <Flex flexDirection="row" mt={6}>
              {timelineItems.map((item, index) => (
                <Flex flexDirection="row" key={`Timeline-Item-Web-${index}`}>
                  <Box flex="1" pl={4} pr={4} mb={4}>
                    <Heading size="sm" mb={2}>
                      {item.title}
                    </Heading>
                    <Text fontSize="sm" mb={2}>
                      {item.description}
                    </Text>
                  </Box>
                  {item.additionalTitle && (
                    <Box flex="1" pl={4} pr={4} mb={4}>
                      <Heading size="sm" mb={2}>
                        {item.additionalTitle}
                      </Heading>
                      <Text fontSize="sm">{item.additionalDescription}</Text>
                    </Box>
                  )}
                </Flex>
              ))}
            </Flex>
          )}
        </>
      )}
    </Box>
  )
}

export default StaticTimeline
