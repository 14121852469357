import { Button, Text, VStack, Input, Box } from '@chakra-ui/react'
import { Formik } from 'formik'
import { RoutePaths } from 'containers/Core/Routes'
import useHowDidYouHear, {
  HowDidYouHear as HowDidYouHearOption,
} from 'hooks/useHowDidYouHear'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingContainer from '../OnboardingContainer'

import './styles.scss'
import { Amplitude } from 'utils/amplitude.utils'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { isPatient } from 'utils/program.utils'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface HowDidYouHearProps extends WithAccountProps {
  canRedirect?: boolean
}

interface FormValues {
  howDidYouHear: HowDidYouHearOption[]
  providerName: string
}

const HowDidYouHear: React.FC<HowDidYouHearProps> = ({ user, canRedirect }) => {
  const history = useHistory()
  const { trackPageView } = UseOnboardingPageView()
  const {
    howDidYouHear: options,
    shareHowDidYouHear,
    updateOnboardingHowDidYouHear,
  } = useHowDidYouHear()
  const { variantLabel } = useOnboardingContext()
  const [shared, setShared] = useState<boolean>(false)

  const handleContinue = useCallback(
    (values: FormValues, cta?: 'share' | 'skip') => {
      const howDidYouHears = values.howDidYouHear.map((value) => value.value)
      Amplitude.howDidYouHear(howDidYouHears ?? [], cta ?? 'skip', variantLabel)

      const route = `${RoutePaths.DASHBOARD}${window.location.search}`

      history.push(route)
    },
    [window.location.search]
  )

  const handleFormSubmit = useCallback(
    (values: FormValues) => {
      if (values.howDidYouHear) {
        shareHowDidYouHear(values.howDidYouHear)

        if (
          values.howDidYouHear.some(
            (item) => item.value === 'Referred by Provider'
          )
        ) {
          updateOnboardingHowDidYouHear(values.providerName)
        }
      }

      if (canRedirect) {
        handleContinue(values, 'share')
      } else {
        setShared(true)
      }
    },
    [
      shareHowDidYouHear,
      updateOnboardingHowDidYouHear,
      canRedirect,
      handleContinue,
    ]
  )

  // Track page view
  useEffect(() => {
    if (window.location.href.includes(RoutePaths.BOOK_CONSULT_COMPLETE)) {
      trackPageView('bookConsultComplete')
    }
  }, [])

  return (
    <Formik
      initialValues={{
        howDidYouHear: [] as HowDidYouHearOption[],
        providerName: '',
      }}
      onSubmit={handleFormSubmit}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <OnboardingContainer onClose={() => handleContinue(values, 'skip')}>
          <Box
            backgroundColor="white"
            borderRadius="lg"
            padding={['16px', '24px']}
            width={['95%', '80%', '60%', '30%']}
            maxWidth="600px"
            mx="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {!shared && (
              <VStack spacing={3} width="100%">
                <Text
                  fontSize="32px"
                  textAlign="center"
                  fontFamily={'Instrument Serif'}
                  mb={0}
                >
                  How did you hear about us?
                </Text>
                <Text fontSize="14px" textAlign="center" color="gray.500">
                  (Select all that apply)
                </Text>

                {options?.map((option) => (
                  <Button
                    key={option.id}
                    variant="outline"
                    borderRadius="full"
                    width="100%"
                    borderColor="var(--allara-yellow)"
                    onClick={() => {
                      const currentValues = values.howDidYouHear
                      const optionIndex = currentValues.findIndex(
                        (item) => item.id === option.id
                      )

                      if (optionIndex >= 0) {
                        setFieldValue(
                          'howDidYouHear',
                          currentValues.filter(
                            (_, index) => index !== optionIndex
                          )
                        )
                      } else {
                        setFieldValue('howDidYouHear', [
                          ...currentValues,
                          option,
                        ])
                      }
                    }}
                    bg={
                      values.howDidYouHear.some((item) => item.id === option.id)
                        ? 'var(--allara-yellow)'
                        : 'white'
                    }
                    color="var(--blue-dark)"
                    _hover={{
                      bg: '#D4DE73',
                    }}
                  >
                    {option.value}
                  </Button>
                ))}

                {values.howDidYouHear.some(
                  (item) => item.value === 'Referred by Provider'
                ) && (
                  <Input
                    placeholder="Provider or practice name"
                    value={values.providerName}
                    onChange={(e) =>
                      setFieldValue('providerName', e.target.value)
                    }
                    borderColor="var(--allara-yellow)"
                    _focus={{
                      borderColor: 'var(--allara-yellow)',
                      boxShadow: '0 0 0 1px var(--allara-yellow)',
                    }}
                  />
                )}

                <Button
                  width="100%"
                  mb={0}
                  mt={5}
                  backgroundColor="var(--blue-dark)"
                  color="white"
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                    color: 'white',
                  }}
                  isDisabled={
                    values.howDidYouHear.some(
                      (item) => item.value === 'Referred by Provider'
                    ) && !values.providerName.trim()
                  }
                  onClick={() => handleSubmit()}
                >
                  Share
                </Button>
              </VStack>
            )}
          </Box>
        </OnboardingContainer>
      )}
    </Formik>
  )
}

export default withAccount(HowDidYouHear)
