import { RoutePaths } from 'containers/Core/Routes'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import useSearchParams from 'hooks/useSearchParams'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Redirect } from 'react-router-dom'
import { PROGRAMS, useIsInsurancePatient } from 'utils/program.utils'
import useInsurance from 'hooks/useInsuranceCard'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import Loader from 'components/Loader'

const OnboardingRouter: React.FC<WithAccountProps> = ({ user }) => {
  const searchParams = useSearchParams()
  const isRecheck = searchParams.get('insurance-recheck') !== null
  const isFormsortInsurance = searchParams.get('formsort_insurance') !== null
  const schedulingRedirect = searchParams.get('appointmentType')
  const {
    appointmentTypeId: fullProgramAppointmentTypeId,
    isLoading: isAppointmentTypeLoading,
  } = useAppointmentTypeId('Initial MD')
  const { enableFormsortInsurance, noFeeInsuranceMembership } = useFlags()
  const isInsurancePatient = useIsInsurancePatient(user)
  const { insurance, isLoading: isLoadingInsurance } = useInsurance()
  const validNonCommerceInsurance =
    user.waitlistUser.insurancePlan === 'NONCOMMERCE'
  const validCommerceInsurance =
    user.waitlistUser.insurancePlan === 'COMMERCE' &&
    !!user.waitlistUser.insuranceProvider
  const hasValidInsuranceInfo =
    validCommerceInsurance || validNonCommerceInsurance
  // As opposed to formsort insurance
  const shouldShowAllaraInsuranceCheck =
    !enableFormsortInsurance ||
    (!hasValidInsuranceInfo && !isFormsortInsurance) ||
    (hasValidInsuranceInfo && !user.waitlistUser.selectedProduct)
  // Check if insurance patient should go to scheduling
  const shouldGoToScheduling =
    isInsurancePatient &&
    noFeeInsuranceMembership &&
    !user.stripeCustomer?.subscriptionId &&
    user.termsAndConditionSigned &&
    insurance?.success === true &&
    !!insurance

  let page = RoutePaths.DASHBOARD

  if (isLoadingInsurance || isAppointmentTypeLoading) {
    return <Loader />
  }

  if (shouldGoToScheduling) {
    page = `${RoutePaths.SCHEDULING_REDIRECT}?type=${fullProgramAppointmentTypeId}`
  }

  if (user.accountDeactivated === false) {
    if (schedulingRedirect) {
      page = RoutePaths.SCHEDULING
    }
  } else if (user.accountStatus === 'CANCELLED') {
    page = RoutePaths.MANAGE_MEMBERSHIP
  } else if (isRecheck || shouldShowAllaraInsuranceCheck) {
    page = RoutePaths.HAVE_INSURANCE
  } else if (
    user.waitlistUser.selectedProduct.type === PROGRAMS.INSURANCE &&
    !user.termsAndConditionSigned
  ) {
    page = RoutePaths.CONSENT_TO_TELEHEALTH
  } else if (!insurance && isInsurancePatient) {
    page = RoutePaths.INSURANCE_INFO
  } else if (user.waitlistUser.selectedProduct && !shouldGoToScheduling) {
    // Only go to payment plans if not an insurance patient with completed requirements
    page = RoutePaths.PAYMENT_PLANS
  }

  return <Redirect to={`${page}${window.location.search}`} />
}

export default withAccount(OnboardingRouter)
