import { RoutePaths } from 'containers/Core/Routes'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { Redirect } from 'react-router-dom'
import LegalText from './LegalText'
import Loader from 'components/Loader'
import { ChakraPrimaryButton } from 'components/Button'
import { Button } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ProviderDetails from 'components/Scheduling/ProviderDetails'
import TimingDetails from 'components/Scheduling/TimingDetails'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import Checkbox from 'components/CheckboxGroup/Checkbox'

import './styles.scss'
import { Amplitude } from 'utils/amplitude.utils'
import { AppointmentBookDoneProps } from 'utils/amplitude.types'
import PaymentBox from './PaymentBox'
import { Flex, Text } from '@chakra-ui/react'
import {
  useIsInsurancePatient,
  isInitialMedical as isInitialMedicalUtils,
} from 'utils/program.utils'
import useSearchParams from 'hooks/useSearchParams'

const ConfirmScreen: React.FC<
  WithAccountProps & { daysSinceNearestSlot: number }
> = ({ user, daysSinceNearestSlot }) => {
  const {
    amplitudeBookingMetadata,
    provider,
    dateTime,
    timeZone,
    isReschedule,
    appointmentType,
    nextScreen,
    previousScreen,
    bookAppointment,
    rescheduleAppointment,
  } = useSchedulingContext()
  const { setAlertText } = useAlert()
  const { source } = useSourceContext()
  const [expired, setExpired] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmLegal, setConfirmLegal] = useState<boolean>(false)
  const isInsurancePatient = useIsInsurancePatient(user)
  const searchParams = useSearchParams()
  const urlSource = searchParams.get('source')
  const startTime = useMemo(() => {
    return Date.now()
  }, [])

  const isInitialMedical = isInitialMedicalUtils(appointmentType ?? null)

  const shouldShowPayment =
    isInsurancePatient && isInitialMedical && urlSource === 'onboarding'

  const handleBook = useCallback(() => {
    setIsLoading(true)

    const handleAction = isReschedule ? rescheduleAppointment : bookAppointment

    if (shouldShowPayment) {
      const variantLabel = user?.waitlistUser.variantLabel ?? ''
      Amplitude.userEntersPayment(
        Math.round((Date.now() - startTime) / 1000),
        variantLabel
      )

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'Insurance Payment Captured',
        })
      }
    }

    handleAction()
      .then((appointment) => {
        const amplitudeEventProps: AppointmentBookDoneProps = {
          appointment_id: appointment?.id ?? '',
          appointment_time: dateTime?.toISOString() ?? '',
          appointment_type_name: appointmentType?.name ?? '',
          is_reschedule: isReschedule ?? false,
          days_since_nearest_slot: daysSinceNearestSlot,
          ...amplitudeBookingMetadata,
        }
        Amplitude.appointmentBookDone(amplitudeEventProps)
        nextScreen()
      })
      .catch(() => {
        setAlertText(
          `Your appointment could not be ${
            isReschedule ? 'rescheduled' : 'booked'
          }. Please contact concierge for assistance.`,
          "Oops that didn't quite got to plan"
        )
      })
      .finally(() => setIsLoading(false))
  }, [
    source,
    appointmentType,
    timeZone,
    dateTime,
    provider,
    user.sourceHealthId,
  ])

  const handleConfirmLegal = useCallback(() => setConfirmLegal(!confirmLegal), [
    confirmLegal,
  ])

  useEffect(() => {
    const expiredTimeout = setTimeout(() => setExpired(true), 1000 * 60 * 5)

    return () => clearTimeout(expiredTimeout)
  }, [])

  if (!appointmentType) {
    return <Loader />
  } else if (!provider || !dateTime || !timeZone) {
    return <Redirect to={RoutePaths.SCHEDULING} />
  }

  const navigationButtons = (
    <>
      <Button
        className="mr-0 mr-lg-4"
        variant="outline"
        colorScheme={'var(--blue-dark)'}
        _hover={{
          backgroundColor: 'var(--blue-dark)',
          cursor: 'pointer',
          color: 'white',
        }}
        onClick={previousScreen}
        disabled={isLoading}
      >
        <div className="d-flex align-items-center">Previous</div>
      </Button>
      <ChakraPrimaryButton
        onClick={handleBook}
        variant="dark-blue"
        disabled={expired || isLoading || !confirmLegal}
        isLoading={isLoading}
      >
        {isReschedule ? 'Reschedule' : 'Book'}
      </ChakraPrimaryButton>
    </>
  )

  return (
    <div className="confirm screen-container">
      <Text fontSize="2xl" as="h5" mb={6}>
        Confirm your appointment
      </Text>
      {expired && (
        <div className="alert ff-inter fs-2">
          <span className="text-uppercase">Your Session is Expired</span>
          <br />
          Please go back to the previous step to select available time of an
          appointment
        </div>
      )}
      <Flex mb={8}>
        <ProviderDetails provider={provider} />
        <TimingDetails
          date={dateTime}
          timeZone={timeZone}
          appointmentType={appointmentType}
        />
      </Flex>
      {shouldShowPayment && (
        <PaymentBox handleBook={handleBook} previousScreen={previousScreen} />
      )}
      <LegalText />
      {!shouldShowPayment && (
        <>
          <div className="my-3">
            <Checkbox
              className="small"
              controlId="legal-confirm"
              checked={confirmLegal}
              onChange={handleConfirmLegal}
              label="Please confirm you understand and agree to our Cancellation and No Show Policy"
            />
          </div>

          <div className="d-none d-lg-flex">{navigationButtons}</div>
          <div className="d-block d-lg-none fixed-bottom half-width-buttons">
            <div className="w-100 d-flex">{navigationButtons}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default withAccount(ConfirmScreen)
