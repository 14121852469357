export const PaymentSubscription = '/api/v1/payment/subscription'
export const OneTimePayment = '/api/v1/payment/payment'
export const CustomerPortal = '/api/v1/payment/customer-portal'
export const PaymentMethod = '/api/v1/payment/payment-method'
export const PaymentPortal =
  '/api/v1/payment/customer-portal/update-payment-method'
export const Invoices = '/api/v1/payment/invoices'
export const CheckPromoCode = '/api/v1/payment/check-promo-code'
export const MembershipInfo = `/api/v1/payment/membership-info`
export const CancelSubscription = (subscriptionId: string): string =>
  `/api/v1/payment/cancel-subscription/${subscriptionId}`
export const ReactivateSubscription = (subscriptionId: string): string =>
  `/api/v1/payment/reactivate-cancelled-subscription/${subscriptionId}`
export const DiscountOptions = (priceId: string): string =>
  `/api/v1/payment/discount-options/${priceId}`
export const ApplyDiscount = (couponId: string): string =>
  `/api/v1/payment/apply-discount/${couponId}`
export const UpgradeToAnnual = (subscriptionId: string): string =>
  `/api/v1/payment/upgrade-to-annual/${subscriptionId}`
export const CreateSetupIntent = '/api/v1/payment/setup-intent'

export const QueryKey = 'payment-plans'
export const PaymentProductQueryKey = 'payment-product'
export const CustomerPortalQueryKey = 'customer-portal'
export const PaymentMethodQueryKey = 'update-payment-method'
export const InvoicesQueryKey = 'invoices'
export const DiscountOptionsQueryKey = 'discount-options'

export const AttachPaymentMethod = (id: string): string =>
  `/api/v1/payment/payment-method/${id}`

export const PaymentPlans = (program: string): string =>
  `/api/v1/payment/plans?type=${program}`

export const PaymentPlan = (planId: string): string =>
  `/api/v1/payment/plan?planId=${planId}`

export const PaymentProduct = (program: string): string =>
  `/api/v1/public-payment-details/product/${program}`
