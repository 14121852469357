import { ArrowBackIcon } from '@chakra-ui/icons'
import { Flex, Text, Button } from '@chakra-ui/react'

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="link"
    leftIcon={<ArrowBackIcon />}
    height="auto"
    minW="auto"
    p={0}
  />
)

const TitleWithBack: React.FC<{
  title: string
  onBack?: () => void
  subtitle?: string
}> = ({ title, onBack, subtitle }) => (
  <>
    <Flex align="center" mb={4}>
      {onBack && <BackButton onClick={onBack} />}
      <Text
        color="gray.700"
        as="h5"
        size="lg"
        ml={onBack ? 2 : 0}
        mb={0}
        fontFamily={'Instrument Serif'}
        fontSize="32px"
      >
        {title}
      </Text>
    </Flex>
    {subtitle && (
      <Text color="gray.500" size="sm" mb={6}>
        {subtitle}
      </Text>
    )}
  </>
)

export default TitleWithBack
