// import { PrimaryButton } from 'components/Button'
import useInsurance from 'hooks/useInsuranceCard'
import { useCallback, useState } from 'react'
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import UploadInsuranceCardModal from './UploadInsuranceCardModal'
import { WithAccountProps } from 'hoc/withAccount'
import { ChakraPrimaryButton as PrimaryButton } from 'components/Button'
import { Amplitude } from 'utils/amplitude.utils'

const Insurance: React.FC<WithAccountProps> = ({ user }) => {
  const { isLoading, insurance, downloadInsurance } = useInsurance()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [showUpload, setShowUpload] = useState<boolean>(false)

  const handleOnShow = () => {
    setShowUpload(true)
    Amplitude.insuranceUploadStart()
  }

  const handleOnHide = () => {
    setShowUpload(false)
    Amplitude.insuranceUploadDone()
  }

  const onShow = useCallback(() => handleOnShow(), [handleOnShow])
  const onHide = useCallback(() => handleOnHide(), [handleOnHide])

  const handleDownload = useCallback(() => {
    if (insurance) {
      setIsDownloading(true)
      downloadInsurance(insurance).then(() => {
        Amplitude.insuranceDownloadDone()
        setIsDownloading(false)
      })
    }
  }, [insurance, downloadInsurance, setIsDownloading])

  return (
    <div className="insurance-container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <p className="spaced-capital-letters my-3">Insurance</p>
        <PrimaryButton
          onClick={onShow}
          disabled={user.accountStatus === 'CANCELLED'}
          styleProps={{ size: 'sm' }}
        >
          Upload
        </PrimaryButton>
      </div>
      <div className="files-container pt-1">
        {!isLoading && insurance && insurance.success && (
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="mb-0">
              Insurance Card Uploaded on{' '}
              {new Date(insurance.uploaded).toLocaleDateString()}
            </p>
            {isDownloading ? (
              <OverlayTrigger
                placement="right"
                show={true}
                overlay={
                  <Tooltip id="downloading-tooltip">Downloading...</Tooltip>
                }
              >
                <DownloadIcon className="download-icon disabled" />
              </OverlayTrigger>
            ) : (
              <DownloadIcon
                className="download-icon"
                onClick={handleDownload}
              />
            )}
          </div>
        )}
        {!isLoading && !insurance && (
          <p>We don&apos;t currently have your insurance on file</p>
        )}
      </div>
      <p className="fs-2">
        We&apos;ll use your insurance information to get your labs and
        medications covered.
        <br />
        <br />
        <i>
          If your insurance provider ever changes, please upload the new card
          images using the button above.
        </i>
      </p>
      <UploadInsuranceCardModal show={showUpload} onHide={onHide} />
    </div>
  )
}

export default Insurance
