import withAccount from 'hoc/withAccount'
import './styles.scss'
import { Box, Text } from '@chakra-ui/react'

const LegalText = () => {
  return (
    <Box
      color="var(--gray-dark)"
      width={['100%', '100%', '3xl']}
      px={[2, 3, 0]}
    >
      <Text
        fontSize="xs"
        textTransform="uppercase"
        mb={0}
        color="var(--gray-dark)"
      >
        Cancellation Policy:
      </Text>
      <Text fontSize="xs">
        To respect our provider&apos;s time, all appointments must be cancelled
        at least 24 hours in advance. Your provider will wait 5 minutes past the
        start of your appointment before you&apos;re marked as a no-show.
        Violations of this policy will result in a $50 charge. Thank you for
        understanding.
      </Text>
    </Box>
  )
}

export default withAccount(LegalText)
