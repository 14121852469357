import { useStytch, useStytchSession } from '@stytch/react'
import {
  AttachPaymentMethod,
  OneTimePayment,
  PaymentSubscription,
  CreateSetupIntent,
} from 'api/payment/routes'
import axios, { AxiosError } from 'axios'
import { PaymentPlan } from 'context/OnboardingContext/OnboardingProvider'

type PaymentIntentResponse = {
  id: string
  client_secret?: string
  customer: string
}

type SetupIntentResponse = {
  clientSecret: string
}

interface UsePaymentInterface {
  createPaymentIntent: (
    plan: PaymentPlan,
    promoCode?: string,
    isDirect?: boolean
  ) => Promise<PaymentIntentResponse>
  attachPaymentMethod: (paymentMethodId: string) => Promise<undefined>
  createSetupIntent: () => Promise<SetupIntentResponse>
}

const usePayment = (): UsePaymentInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  /**
   * Creates a payment intent or subscription and returns a client_secret
   * @param priceId - the id of the stripe plan/price
   * @param recurring - true if creating a subscription, false for OTP
   * @param promoCode - (optional) promotional code to apply to the order
   * @returns client_secret on success. Rejects with ErrorResponse on error
   */
  const createPaymentIntent = async (
    plan: PaymentPlan,
    promoCode?: string,
    isDirect?: boolean
  ): Promise<PaymentIntentResponse> => {
    if (!session) {
      return Promise.reject({ error: 'You must be logged in' })
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    const apiRoute = plan.recurring ? PaymentSubscription : OneTimePayment
    const queryParams = isDirect ? `?direct=true` : ''
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${apiRoute}${queryParams}`,
        {
          priceId: plan.id,
          promoCode,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((res) => res.data)
      .catch((err) => {
        const typedErr = err as AxiosError

        return Promise.reject({ error: typedErr.response?.data?.message })
      })
  }

  /**
   * Creates a setup intent for a customer
   * @returns client_secret on success. Rejects with ErrorResponse on error
   */
  const createSetupIntent = async (): Promise<SetupIntentResponse> => {
    if (!session) {
      return Promise.reject({ error: 'You must be logged in' })
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${CreateSetupIntent}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          method: 'POST',
        }
      )
      const data = await response.json()
      return data
    } catch (error) {
      return Promise.reject({ error: 'Unable to create setup intent' })
    }
  }

  /**
   * Attach a payment method to a user
   * @param paymentMethodId
   * @returns undefined on success or throws error
   */
  const attachPaymentMethod = async (
    paymentMethodId: string
  ): Promise<undefined> => {
    if (!session) {
      return Promise.reject({ error: 'You must be logged in' })
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AttachPaymentMethod(
          paymentMethodId
        )}`,
        undefined,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(() => undefined)
  }

  return {
    createPaymentIntent,
    attachPaymentMethod,
    createSetupIntent,
  }
}

export default usePayment
