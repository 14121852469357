import { useCallback, memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingContainer from '../OnboardingContainer'
import { RoutePaths } from '../../Core/Routes'
import './styles.scss'
import useEligibilityInfo from 'hooks/useUser/useEligibilityInfo'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import useSearchParams from 'hooks/useSearchParams'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from 'utils/amplitude.utils'
import { Box, Button, Text } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { QueryKey } from 'api/insurance/routes'

const HaveInsurance: React.FC = () => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const searchParams = useSearchParams()
  const { trackPageView } = UseOnboardingPageView()
  const {
    handleUpdateHaveInsurance,
    isLoading,
    waitlistUser: waitlistUserFromHook,
  } = useEligibilityInfo()
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<boolean | undefined>(undefined)
  const isRecheck = searchParams.get('insurance-recheck') !== null
  const variantLabelParam = searchParams.get('variant_label')
  const queryClient = useQueryClient()

  const handleNo = useCallback(() => {
    handleSubmit(false)
  }, [])

  const handleYes = useCallback(() => handleSubmit(true), [])

  const handleSubmit = (response: boolean) => {
    Amplitude.userHasInsurance(response ? 'yes' : 'no', variantLabelParam ?? '')
    setLoading(true)
    setResponse(response)
    handleUpdateHaveInsurance({ response, recheck: isRecheck })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setAlertText('Error checking for eligibility', 'An Error Occurred')
      })
  }

  // Track page view
  useEffect(() => {
    trackPageView('haveInsurance')
  }, [])

  useEffect(() => {
    //check if waitlistUserFromHook has been updated and navigate to the appropriate page
    if (!isLoading && !loading && response !== undefined) {
      queryClient.invalidateQueries(QueryKey)

      const path = response
        ? RoutePaths.INSURANCE_ELIGIBILITY
        : RoutePaths.PROGRAMS
      history.push(`${path}${window.location.search}`)
    }
  }, [
    isLoading,
    loading,
    response,
    waitlistUserFromHook,
    window.location.search,
  ])

  return (
    <OnboardingContainer>
      <Box
        backgroundColor="white"
        borderRadius="lg"
        padding={['16px', '24px']}
        width={['95%', '80%', '60%', '30%']}
        maxWidth="600px"
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text fontFamily="Instrument Serif" fontSize="32px">
          Do you have health insurance?
        </Text>
        <Button
          variant="outline"
          onClick={handleYes}
          width="100%"
          mb={4}
          borderColor={'var(--blue-dark)'}
          color={'var(--blue-dark)'}
          _hover={{
            backgroundColor: 'var(--blue-dark)',
            color: 'white',
          }}
        >
          Yes
        </Button>
        <Button
          variant="outline"
          onClick={handleNo}
          width="100%"
          borderColor={'var(--blue-dark)'}
          color={'var(--blue-dark)'}
          _hover={{
            backgroundColor: 'var(--blue-dark)',
            color: 'white',
          }}
        >
          No
        </Button>
      </Box>
    </OnboardingContainer>
  )
}

export default memo(HaveInsurance)
