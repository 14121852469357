import { useStripe } from '@stripe/react-stripe-js'

interface UseStripePaymentInterface {
  processCardPayment: (
    client_secret: string,
    paymentMethodId?: string
  ) => Promise<undefined>
}

export const useStripePayment = (): UseStripePaymentInterface => {
  const stripe = useStripe()

  /**
   * Process the card payment
   * @param paymentIntentResponse
   * @param paymentMethodId
   * @returns undefined on success. Rejects with ErrorResponse on error
   */
  const processCardPayment = async (
    client_secret: string,
    paymentMethodId?: string
  ): Promise<undefined> => {
    if (client_secret) {
      if (!stripe) {
        return Promise.reject({
          error:
            'Could not establish connection with stripe to process your payment. Your card has not been charged. Please reload the page and try again.',
        })
      }

      const options = paymentMethodId
        ? { payment_method: paymentMethodId }
        : undefined
      const confirmedPayment = await stripe.confirmCardPayment(
        client_secret,
        options
      )

      if (confirmedPayment.error) {
        return Promise.reject({
          error: `Unable to process card payment. You have not been charged. Please try again. \n\n${confirmedPayment.error.message}`,
        })
      } else if (confirmedPayment.paymentIntent.status !== 'succeeded') {
        return Promise.reject({
          error:
            'Some error occurred processing payment. Your card has not been charged. Please contact try again.',
        })
      }
    }

    return
  }

  return {
    processCardPayment,
  }
}
