import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import CurrentMembershipCard from './CurrentMembershipCard'
import InvoicesList from './InvoicesList'
import useUser from 'hooks/useUser/useUser'
import { PROGRAMS } from 'utils/program.utils'
import ChakraPrimaryButton from 'components/Button/ChakraPrimaryButton'
import { RoutePaths } from 'containers/Core/Routes'
import { useHistory } from 'react-router-dom'

const CurrentMembership = () => {
  const { user } = useUser()
  const history = useHistory()

  const product = user?.waitlistUser?.selectedProduct?.type ?? ''
  const accountStatus = user?.accountStatus

  const templateAreas = useBreakpointValue({
    md: product === PROGRAMS.INSURANCE ? `"invoices"` : `"membership invoices"`,
  })

  return (
    <Box borderWidth="1px" borderRadius="lg">
      <Grid
        templateAreas={templateAreas}
        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
      >
        <GridItem area="membership">
          {product !== PROGRAMS.INSURANCE && <CurrentMembershipCard />}
        </GridItem>
        <GridItem area="invoices">
          <InvoicesList />
        </GridItem>
      </Grid>
      {product === PROGRAMS.INSURANCE && (
        <Box pl={8} pb={4}>
          {accountStatus === 'CANCELLED' ? (
            <ChakraPrimaryButton
              onClick={() => history.push(RoutePaths.HAVE_INSURANCE)}
            >
              Reactivate membership
            </ChakraPrimaryButton>
          ) : (
            <ChakraPrimaryButton
              onClick={() => history.push(RoutePaths.MANAGE_MEMBERSHIP_CANCEL)}
            >
              Deactivate account
            </ChakraPrimaryButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CurrentMembership
