import {
  DIAGNOSTIC_FEATURES,
  NUTRITION_FEATURES,
  PCOS_FEATURES,
  PCOS_TRANSITION_FEATURES,
  INSURANCE_FEATURES,
  INSURANCE_SECONDARY_INFO,
  MNC_FEATURES,
  MNC_SECONDARY_INFO,
  INSURANCE_HMT_FEATURES,
  INSURANCE_HMT_SECONDARY_INFO,
} from 'containers/Onboarding/Programs/constants'
import { Program } from 'context/OnboardingContext/OnboardingProvider'
import { User } from 'hooks/useUser/useUser'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { AppointmentType } from '@allara-health/source-health-client'

export const PROGRAMS: { [key: string]: Program } = {
  DIAGNOSTIC: 'diagnostic',
  INSURANCE_DIAGNOSTIC: 'insurance-diagnostic',
  FULL_PROGRAM: 'full-program',
  INSURANCE: 'insurance',
  NUTRITION_ONLY: 'nutrition-only',
  MNC: 'mnc',
}

export function getProgramName(type?: Program): string {
  switch (type) {
    case PROGRAMS.DIAGNOSTIC:
    case PROGRAMS.INSURANCE_DIAGNOSTIC:
      return 'Hormonal & Metabolic Evaluation'
    case PROGRAMS.NUTRITION_ONLY:
      return 'Nutrition Program'
    case PROGRAMS.FULL_PROGRAM:
      return 'Complete Care Program'
    case PROGRAMS.INSURANCE:
      return 'Allara Membership'
    case PROGRAMS.MNC:
      return 'Mindful Nutrition Course'
    default:
      return 'Allara Care Program'
  }
}

export function getProgramFeatures(
  type?: Program,
  isTransition = false
): string[] {
  switch (type) {
    case PROGRAMS.DIAGNOSTIC:
      return DIAGNOSTIC_FEATURES
    case PROGRAMS.NUTRITION_ONLY:
      return NUTRITION_FEATURES
    case PROGRAMS.FULL_PROGRAM:
      return isTransition ? PCOS_TRANSITION_FEATURES : PCOS_FEATURES
    case PROGRAMS.INSURANCE:
      return INSURANCE_FEATURES
    case PROGRAMS.INSURANCE_DIAGNOSTIC:
      return INSURANCE_HMT_FEATURES
    case PROGRAMS.MNC:
      return MNC_FEATURES
    default:
      return []
  }
}

export function getProgramSecondaryInfo(type?: Program): string[] {
  switch (type) {
    case PROGRAMS.INSURANCE:
      return INSURANCE_SECONDARY_INFO
    case PROGRAMS.INSURANCE_DIAGNOSTIC:
      return INSURANCE_HMT_SECONDARY_INFO
    case PROGRAMS.MNC:
      return MNC_SECONDARY_INFO
    default:
      return []
  }
}

export function isInsurancePatient(user: User, selectedProgram?: Program) {
  // Check stripe customer first
  const type = user.stripeCustomer?.product?.type

  if (type === PROGRAMS.INSURANCE || type === PROGRAMS.INSURANCE_DIAGNOSTIC) {
    return true
  }

  // If no stripe customer product, check selected program and feature flag
  if (
    selectedProgram === PROGRAMS.INSURANCE ||
    selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC
  ) {
    return true
  }

  return false
}

export const isInitialMedical = (appointmentType: AppointmentType | null) =>
  appointmentType?.name?.toLowerCase().includes('initial') &&
  (appointmentType?.name?.toLowerCase().includes('md') ||
    appointmentType?.name?.toLowerCase().includes('medical'))

export const isSecondMedical = (appointmentType: AppointmentType | null) =>
  appointmentType?.name?.toLowerCase().includes('second') &&
  (appointmentType?.name?.toLowerCase().includes('md') ||
    appointmentType?.name?.toLowerCase().includes('medical'))

export const isInitialNutrition = (appointmentType: AppointmentType | null) =>
  appointmentType?.name?.toLowerCase().includes('initial') &&
  (appointmentType?.name?.toLowerCase().includes('nutrition') ||
    appointmentType?.name?.toLowerCase().includes('rd'))

export function isDiagnosticPatient(user: User) {
  const type = user.stripeCustomer?.product?.type

  if (!type) {
    return false
  } else if (
    type === PROGRAMS.DIAGNOSTIC ||
    type === PROGRAMS.INSURANCE_DIAGNOSTIC
  ) {
    return true
  } else {
    return false
  }
}

export function isNonPatientMember(user: User) {
  return user.stripeCustomer?.product?.nonPatientProduct ? true : false
}

export function isPatient(user: User, selectedProgram?: Program) {
  if (isInsurancePatient(user, selectedProgram)) {
    return true
  }

  return !isNonPatientMember(user)
}

// Update the hook to include feature flag check
export function useIsInsurancePatient(user?: User) {
  const { noFeeInsuranceMembership } = useFlags()

  if (!user || !noFeeInsuranceMembership) {
    return false
  }

  return isInsurancePatient(user, user?.waitlistUser.selectedProduct?.type)
}

// The rest of the hooks/functions remain the same
export function useIsPatient(user?: User) {
  const { selectedProgram } = useOnboardingContext()
  const { noFeeInsuranceMembership } = useFlags()

  if (!user) {
    return false
  }

  // For insurance patients, check the feature flag
  if (isInsurancePatient(user, selectedProgram)) {
    return noFeeInsuranceMembership
  }

  return !isNonPatientMember(user)
}
