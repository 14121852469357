import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import useEligibleInsurances from 'hooks/useEligibleInsurance'
import { memo, useMemo } from 'react'
import { useField } from 'formik'

interface InsuranceProviderSelectProps {
  name: string
  label?: string
}

const InsuranceProviderSelect: React.FC<InsuranceProviderSelectProps> = ({
  name,
  label,
}) => {
  const { insurances } = useEligibleInsurances()
  const [field, meta] = useField(name)

  const options = useMemo(() => {
    const providers = insurances.map((prov) => ({
      label: prov.title,
      value: prov.provider,
    }))
    providers.push({ value: 'other', label: 'Other' })
    return providers
  }, [insurances])

  return (
    <FormControl isInvalid={!!(meta.error && meta.touched)}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select {...field} size="lg" placeholder="Select insurance provider">
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {meta.error && meta.touched && (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default memo(InsuranceProviderSelect)
