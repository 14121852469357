import { RoutePaths } from 'containers/Core/Routes'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectLastChatThread,
  setIsChatDrawerOpen,
} from 'features/dashboard/dashbordSlice'
import { Link, useHistory } from 'react-router-dom'
import { memo, useCallback, useMemo } from 'react'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import { generateGenericAppointmentLink } from 'utils/appointments.utils'
import { isInsurancePatient } from 'utils/program.utils'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import useAppointments from 'hooks/useAppointments/useAppointments'
import { Box, VStack, Button, Icon, Tag } from '@chakra-ui/react'
import { FaUserCircle, FaCalendarPlus, FaAward } from 'react-icons/fa'
import { RiHealthBookFill, RiChat3Fill } from 'react-icons/ri'
import { IoGrid, IoLogOut, IoBookSharp } from 'react-icons/io5'
import { IconType } from 'react-icons'

import useThreads from 'hooks/useThreads/useThreads'
import { useStytch } from '@stytch/react'
import { BookingEventSource } from 'utils/amplitude.types'
import { Amplitude } from 'utils/amplitude.utils'

const AppNavigation: React.FC<WithAccountProps> = ({ user }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { unreadThreads } = useThreads()
  const stytchClient = useStytch()

  const lastChatThread = useAppSelector(selectLastChatThread)
  const { appointmentTypeId: mdAppointmentTypeId } = useAppointmentTypeId(
    'Followup MD'
  )
  const { hasBookedAtleastTwoMd } = useAppointments()

  const onLogout = useCallback(() => {
    stytchClient.session.revoke()
    window.location.href = window.location.origin + RoutePaths.LOGIN
  }, [stytchClient])

  const onOpenChat = () => {
    dispatch(setIsChatDrawerOpen(true))
    history.push(
      lastChatThread
        ? `${RoutePaths.DASHBOARD_CHAT}/${lastChatThread}`
        : RoutePaths.DASHBOARD_CHAT
    )
  }

  const appointmentLink = useMemo(() => {
    if (hasBookedAtleastTwoMd && user.medicalProvider && mdAppointmentTypeId) {
      return generateGenericAppointmentLink(mdAppointmentTypeId as string, {
        source: BookingEventSource.APP_NAVIGATION,
        patient_action_id: null,
        patient_action_definition: null,
      })
    }

    return undefined
  }, [hasBookedAtleastTwoMd, user.medicalProvider, mdAppointmentTypeId])

  function isExternalPath(path: string | (() => void)): path is string {
    return typeof path === 'string' && /^(https?:|mailto:|tel:)/.test(path)
  }

  type MenuItem = {
    name: string
    path: string | (() => void)
    icon: IconType
    onClick?: () => void
  }

  const BookAppointmentLink = {
    name: 'Book an Appointment',
    path: appointmentLink,
    icon: FaCalendarPlus,
  }

  const MENU_ITEMS_ACTIVE_PATIENT: MenuItem[] = [
    {
      name: 'Dashboard',
      path: RoutePaths.DASHBOARD,
      icon: IoGrid,
      onClick: () => Amplitude.menuDashboardClick(),
    },
    {
      name: 'My Health',
      path: RoutePaths.DASHBOARD_MYHEALTH,
      icon: RiHealthBookFill,
      onClick: () => Amplitude.menuMyHealthClick(),
    },
    isInsurancePatient(user) && appointmentLink
      ? BookAppointmentLink
      : undefined,
    {
      name: 'Messaging',
      path: onOpenChat,
      icon: RiChat3Fill,
      onClick: () => Amplitude.menuMessagingClick(),
    },
    {
      name: 'Blog',
      path: 'https://allarahealth.com/blog',
      icon: IoBookSharp,
      onClick: () => Amplitude.menuBlogClick(),
    },
    {
      name: 'Perks',
      path: 'https://allarahealth.com/perks',
      icon: FaAward,
      onClick: () => Amplitude.menuPerksClick(),
    },
    {
      name: 'Account',
      path: RoutePaths.DASHBOARD_PROFILE,
      icon: FaUserCircle,
      onClick: () => Amplitude.menuAccountClick(),
    },
    {
      name: 'Log Out',
      path: onLogout,
      icon: IoLogOut,
      onClick: () => Amplitude.menuLogOutClick(),
    },
  ].filter(Boolean) as MenuItem[]

  const MENU_ITEMS_CANCELLED_PATIENT: MenuItem[] = [
    {
      name: 'My Health',
      path: RoutePaths.DASHBOARD_MYHEALTH,
      icon: RiHealthBookFill,
    },
    { name: 'Messaging', path: onOpenChat, icon: RiChat3Fill },
    {
      name: 'Blog',
      path: 'https://allarahealth.com/blog',
      icon: IoBookSharp,
    },
    {
      name: 'Perks',
      path: 'https://allarahealth.com/perks',
      icon: FaAward,
    },
    { name: 'Account', path: RoutePaths.DASHBOARD_PROFILE, icon: FaUserCircle },
    { name: 'Log Out', path: onLogout, icon: IoLogOut },
  ].filter(Boolean) as MenuItem[]

  const MENU_ITEMS =
    user.accountStatus === 'CANCELLED'
      ? MENU_ITEMS_CANCELLED_PATIENT
      : MENU_ITEMS_ACTIVE_PATIENT

  return (
    <Box>
      <VStack align="stretch" pt="5" pb="5" spacing="5">
        {MENU_ITEMS.map((item, index) => {
          const { name, path, icon, onClick } = item
          const external = isExternalPath(path)

          const handleClick = (
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => {
            onClick?.()

            if (typeof path === 'function') {
              e.preventDefault()
              path()
            }
          }

          return external ? (
            <a
              href={path}
              key={`Menu-Item-${index}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <Button
                variant="link"
                justifyContent="start"
                leftIcon={<Icon as={icon} />}
                fontWeight="normal"
                color={'var(----blue-dark)'}
                _hover={{
                  color: 'var(--blue-dark-hover)',
                  textDecoration: 'underline',
                }}
                data-testid={`button-${name.replace(/\s/g, '')}`}
              >
                {name}
              </Button>
            </a>
          ) : (
            <Link
              to={typeof path === 'string' ? path : '#'}
              key={`Menu-Item-${index}`}
              onClick={handleClick}
            >
              <Button
                variant="link"
                justifyContent="start"
                fontWeight="normal"
                leftIcon={<Icon as={icon} />}
                rightIcon={
                  name === 'Messaging' &&
                  unreadThreads &&
                  unreadThreads.length > 0 ? (
                    <Tag
                      size="sm"
                      backgroundColor="var(--blue-light)"
                      color="var(--blue-dark)"
                      variant="solid"
                    >
                      {unreadThreads.length}
                    </Tag>
                  ) : undefined
                }
                color={'var(--blue-dark)'}
                _hover={{
                  color: 'var(--blue-dark-hover)',
                }}
                data-testid={`button-${name.replace(/\s/g, '')}`}
              >
                {name}
              </Button>
            </Link>
          )
        })}
      </VStack>
    </Box>
  )
}

export default memo(withAccount(AppNavigation))
