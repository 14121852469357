import { Container } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import Loader from 'components/Loader'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { WithAccountProps } from 'hoc/withAccount'
import useUser from 'hooks/useUser/useUser'
import { RoutePaths } from 'containers/Core/Routes'
import useInsurance from 'hooks/useInsuranceCard'
import { useIsInsurancePatient, useIsPatient } from 'utils/program.utils'
import useSearchParams from 'hooks/useSearchParams'
import useAppointments from 'hooks/useAppointments/useAppointments'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'

function withSubscription<T extends WithAccountProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<Omit<T, 'user'>> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  // Creating the inner component. The calculated Props type here is where the magic happens.
  const ComponentWithSubscription: React.FC<Omit<T, 'user'>> = (props) => {
    const { paymentDone, acceptedTerms } = useOnboardingContext()
    const { user, userError, isLoading, refetch: refetchUser } = useUser()
    const { insurance, isLoading: isLoadingInsurance } = useInsurance()
    const { nonCancelledAppointments } = useAppointments()
    const searchParams = useSearchParams()
    const isRecheck = searchParams.get('insurance-recheck') !== null
    const fromScheduling = searchParams.get('from') === 'scheduling'
    const isInsurancePatient = useIsInsurancePatient(user)
    const isPatient = useIsPatient(user)
    const { noFeeInsuranceMembership } = useFlags()
    const {
      appointmentTypeId: fullProgramAppointmentTypeId,
    } = useAppointmentTypeId('Initial MD')
    const { appointmentTypeId: careTranferTypeId } = useAppointmentTypeId(
      'Care Transfer MD'
    )
    const isSchedulingPath = location.pathname.startsWith(RoutePaths.SCHEDULING)
    const isDashboardPath = location.pathname === RoutePaths.DASHBOARD

    useEffect(() => {
      if (fromScheduling) {
        // Refetch user data when coming from scheduling
        refetchUser()
      }
    }, [fromScheduling])

    if (isLoading) {
      return <Loader />
    } else if (userError) {
      console.error('User Object Error:', userError)

      return (
        <Container className="d-flex flex-column justify-content-center height-full">
          <p className="h1 text-danger text-center">
            Some error occurred! Please try again later.
          </p>
        </Container>
      )
    } else if (!user) {
      return <Loader />
    } else if (user.accountStatus === 'CANCELLED') {
      return <WrappedComponent {...(props as T)} user={user} />
    }

    // Check if this is an insurance patient without a subscription
    const hasNoSubscription = !user.stripeCustomer?.product
    const shouldRedirectToScheduling =
      !isSchedulingPath &&
      !fromScheduling &&
      !isDashboardPath &&
      isInsurancePatient &&
      noFeeInsuranceMembership &&
      hasNoSubscription &&
      user.termsAndConditionSigned &&
      insurance?.success === true

    const filteredAppointments = nonCancelledAppointments.length
    const isReactivatedUser =
      user.accountStatus === 'ACTIVE' &&
      user.cancellationReason !== null &&
      filteredAppointments > 0

    const appointmentTypeId = isReactivatedUser
      ? careTranferTypeId
      : fullProgramAppointmentTypeId

    if (shouldRedirectToScheduling) {
      return (
        <Redirect
          to={`${RoutePaths.SCHEDULING_REDIRECT}?type=${appointmentTypeId}`}
        />
      )
    }

    // Regular flow checks
    if (user.accountDeactivated && !isInsurancePatient && !paymentDone) {
      if (isRecheck || !user.waitlistUser.insuranceProvider) {
        return (
          <Redirect
            to={RoutePaths.HAVE_INSURANCE + `/${window.location.search}`}
          />
        )
      }

      return <Redirect to={`/${window.location.search}`} />
    } else if (isPatient && !user.termsAndConditionSigned && !acceptedTerms) {
      return <Redirect to={RoutePaths.CONSENT_TO_TELEHEALTH} />
    } else if (isInsurancePatient) {
      if (isLoadingInsurance) {
        return <Loader />
      } else if (!insurance) {
        return <Redirect to={RoutePaths.INSURANCE_INFO} />
      } else if (
        noFeeInsuranceMembership &&
        hasNoSubscription &&
        user.termsAndConditionSigned
      ) {
        const source = searchParams.get('source')

        // Allow scheduling path, initial onboarding flow, or returns from scheduling
        if (isSchedulingPath || source === 'onboarding' || fromScheduling) {
          return <WrappedComponent {...(props as T)} user={user} />
        }

        return (
          <Redirect
            to={`${RoutePaths.SCHEDULING_REDIRECT}?type=${appointmentTypeId}`}
          />
        )
      }
    }

    return <WrappedComponent {...(props as T)} user={user} />
  }

  ComponentWithSubscription.displayName = `withSubscription(${displayName})`

  return ComponentWithSubscription
}

export default withSubscription
