import {
  EligibilityQueryKey,
  OnboardingResponse as OnboardingResponseRoute,
  Users as UserRoute,
  UpdateWaitlistUsers,
  UserQueryKey,
  UpdateDirectCheckEligibility as UpdateDirectCheckEligibilityRoute,
} from 'api/common/routes'
import axios, { AxiosResponse } from 'axios'
import { Program } from 'context/OnboardingContext/OnboardingProvider'
import { useMemo } from 'react'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueries,
  useQueryClient,
} from 'react-query'
import { useStytch, useStytchSession } from '@stytch/react'
import { HowDidYouHear } from 'hooks/useHowDidYouHear'

export interface CreateWaitlisUserDto {
  firstName: string
  lastName: string
  email: string
  dob: Date
  zipCode: string
  gender: string
  isDirectSignUp?: boolean
}

export type InsurancePlan = 'NONCOMMERCE' | 'COMMERCE'

export interface WaitlistUser {
  id: string
  dob: string
  state: State
  reason: { value: string }
  eligibleProducts: { id: string; type: Program; hidden: boolean }[]
  selectedProduct: { id: string; type: Program }
  insuranceProvider?: string
  insuranceState?: State
  insurancePlan?: InsurancePlan
  howDidYouHear?: HowDidYouHear[]
  variantLabel?: string
}

export interface WaitlistUsersDto extends WaitlistUser {
  insuranceStateCode?: string
  insuranceProvider?: string
  insurancePlan?: InsurancePlan
}

export interface HaveInsuranceDto {
  response: boolean
  recheck?: boolean
}

export interface State {
  code: string
  eligible: boolean
  eligibleNutrition: boolean
  fullName: string
}

export interface OnboardingResponse {
  insurance?: boolean
  diagnosed?: boolean
  primaryGoal?: {
    value: string
  }
}

interface UseEligibilityInfo {
  isLoading: boolean
  waitlistUser?: WaitlistUser
  onboardingResponse?: OnboardingResponse
  handleUpdateWaitlistUser: UseMutateAsyncFunction<
    AxiosResponse<WaitlistUser> | undefined,
    unknown,
    WaitlistUser,
    unknown
  >
  handleUpdateHaveInsurance: UseMutateAsyncFunction<
    AxiosResponse<WaitlistUser> | undefined,
    unknown,
    HaveInsuranceDto,
    unknown
  >
  handleUpdateDirectCheckEligibility: UseMutateAsyncFunction<
    AxiosResponse<WaitlistUser> | undefined,
    unknown,
    CreateWaitlisUserDto,
    unknown
  >
}

const useEligibilityInfo = (): UseEligibilityInfo => {
  const { session } = useStytchSession()
  const stytch = useStytch()
  const queryClient = useQueryClient()

  const fetchWaitlistUser = async (): Promise<WaitlistUser | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${UserRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(async (res) => {
      const data = await res.json()

      if (res.ok) {
        return data
      } else {
        return undefined
      }
    })
  }

  const fetchOnboardingResponse = async (): Promise<
    OnboardingResponse | undefined
  > => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${OnboardingResponseRoute}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    ).then(async (res) => {
      const data = await res.json()

      if (res.ok) {
        return data
      } else {
        return undefined
      }
    })
  }

  const updateWaitlistUser = async (waitlistUser: WaitlistUsersDto) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios.patch(
      `${process.env.REACT_APP_SERVER_URL}${UpdateWaitlistUsers}`,
      { waitlistUser },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
  }

  const mutateUpdateWaitlistUser = useMutation(updateWaitlistUser, {
    onSettled: () => {
      queryClient.invalidateQueries([EligibilityQueryKey, 'waitlist-user'])
      queryClient.invalidateQueries(UserQueryKey)
    },
  })

  const handleUpdateWaitlistUser = mutateUpdateWaitlistUser.mutateAsync

  const updateHaveInsurance = async (response: HaveInsuranceDto) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}${OnboardingResponseRoute}/have-insurance`,
      { response },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
  }

  const mutateUpdateHaveInsurance = useMutation(updateHaveInsurance, {
    onSettled: () => {
      queryClient.invalidateQueries([EligibilityQueryKey, 'waitlist-user'])
      queryClient.invalidateQueries(UserQueryKey)
    },
  })

  const handleUpdateHaveInsurance = mutateUpdateHaveInsurance.mutateAsync

  const updateDirectCheckEligibility = async (
    createWaitlistUserDto: CreateWaitlisUserDto
  ) => {
    const data: any = { ...createWaitlistUserDto } // eslint-disable-line
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}${UpdateDirectCheckEligibilityRoute}`,
      data
    )
  }

  const mutateUpdateDirectCheckEligibility = useMutation(
    updateDirectCheckEligibility,
    {
      onSettled: () => {
        queryClient.invalidateQueries([EligibilityQueryKey, 'waitlist-user'])
        queryClient.invalidateQueries(UserQueryKey)
      },
    }
  )

  const handleUpdateDirectCheckEligibility =
    mutateUpdateDirectCheckEligibility.mutateAsync

  const [waitlistUserResults, onboardingResponseResults] = useQueries([
    {
      queryKey: [EligibilityQueryKey, 'waitlist-user'],
      queryFn: fetchWaitlistUser,
    },
    {
      queryKey: [EligibilityQueryKey, 'onboarding-response'],
      queryFn: fetchOnboardingResponse,
    },
  ])

  const isLoading = useMemo(
    () => waitlistUserResults.isLoading || onboardingResponseResults.isLoading,
    [waitlistUserResults, onboardingResponseResults]
  )

  return {
    isLoading,
    waitlistUser: waitlistUserResults.data,
    onboardingResponse: onboardingResponseResults.data,
    handleUpdateWaitlistUser,
    handleUpdateHaveInsurance,
    handleUpdateDirectCheckEligibility,
  }
}

export default useEligibilityInfo
