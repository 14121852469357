import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { memo, useEffect, useState } from 'react'
import BookScreen from './BookScreen'
import ConfirmScreen from './ConfirmScreen'
import LocationScreen from './LocationScreen'
import SuccessScreen from './SuccessScreen'
import AppointmentTypeScreen from './AppointmentTypeScreen'
import { AppointmentBookStartEventProps } from 'utils/amplitude.types'
import { Amplitude } from 'utils/amplitude.utils'

const SchedulingRouter: React.FC = () => {
  const {
    amplitudeBookingMetadata,
    appointment,
    appointmentType,
    currentScreen,
  } = useSchedulingContext()
  const [
    hasAmplitudeEventBeenSent,
    setHasAmplitudeEventBeenSent,
  ] = useState<boolean>(false)
  const [daysSinceNearestSlot, setDaysSinceNearestSlot] = useState<number>(0)
  const [
    firstAvailabilityForBookingSession,
    setFirstAvailabilityForBookingSession,
  ] = useState<string | null>(null)

  useEffect(() => {
    /*
    we just want to log the first time the user enters the booking flow if they're booking a net-new appointment.
    This handling is designed so that we don't re-fire the event when the user clicks 'back'
    somewhere within the booking flow (to edit previous selections), so that we don't re-fire the event when the user clicks
    'Modify Appointment' (which takes them to the 'success' screen), and generally so that it's not spammy.

    for reschedules, we send this same event when the user clicks the 'Reschedule' button on the 'success' screen.
    */

    if (
      currentScreen !== 'success' &&
      appointmentType &&
      !hasAmplitudeEventBeenSent
    ) {
      const amplitudeEventProps: AppointmentBookStartEventProps = {
        appointment_id: null,
        is_reschedule: false,
        appointment_type_name: appointmentType?.name ?? '',
        ...amplitudeBookingMetadata,
      }
      Amplitude.appointmentBookStart(amplitudeEventProps)
      setHasAmplitudeEventBeenSent(true)
    }
  }, [appointment, appointmentType, hasAmplitudeEventBeenSent])

  switch (currentScreen) {
    case 'appointmentTypeSelect':
      return <AppointmentTypeScreen />
    case 'location':
      return <LocationScreen />
    case 'book':
      return (
        <BookScreen
          setDaysSinceNearestSlot={setDaysSinceNearestSlot}
          firstAvailabilityForBookingSession={
            firstAvailabilityForBookingSession
          }
          setFirstAvailabilityForBookingSession={
            setFirstAvailabilityForBookingSession
          }
        />
      )
    case 'confirm':
      return <ConfirmScreen daysSinceNearestSlot={daysSinceNearestSlot} />
    case 'success':
      return <SuccessScreen />
    default:
      return <LocationScreen />
  }
}

export default memo(SchedulingRouter)
