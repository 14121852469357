import { Appointment } from 'hooks/useAppointments/useAppointments'
import useAppointmentDefinitions from 'hooks/useAppointments/useAppointmentDefinitions'
import React, { useMemo, useState } from 'react'
import { formatAppointmentText } from 'utils/appointments.utils'
import { RoutePaths } from 'containers/Core/Routes'
import MultiActionCard, { ActionProperties } from './MultiActionCard'
import CalendarModal from './Modals/CalendarModal'
import { useDisclosure } from '@chakra-ui/react'
import useMember from 'hooks/useUser/useMember'
import AppointmentNoteModal from '../MyHealth/PastAppointments/AppointmentNoteModal'
import useAppointmentNotes, {
  AppointmentNote,
} from 'hooks/useAppointments/useAppointmentNotes'
import {
  AppointmentModifyEventProps,
  BookingEventSource,
} from 'utils/amplitude.types'
import { Amplitude } from 'utils/amplitude.utils'
import { useSourceContext } from 'context/SourceContext/SourceProvider'

interface AppointmentCardProps {
  appointment: Appointment
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment }) => {
  const { appointmentDefinitions } = useAppointmentDefinitions()
  const { member } = useMember()
  const { appointmentNotes } = useAppointmentNotes()
  const { source } = useSourceContext()
  const note = useMemo(
    () => appointmentNotes?.find((n) => n.appointment.id === appointment.id),
    [appointmentNotes]
  )
  const [showNote, setShowNote] = useState<AppointmentNote | null>(null)
  const hideShowNoteDialog = () => setShowNote(null)

  const handleOpenNote = () => {
    if (note) {
      setShowNote(note)
      Amplitude.appointmentNoteOpen({
        appointment_note_id: note.id,
        appointment_id: appointment.id,
        appointment_type_name: note.appointment.type.category,
        source: 'dashboard',
      })
    }
  }

  const {
    isOpen: isCalendarModalOpen,
    onOpen: calendarModalOnOpen,
    onClose: calendarModalOnClose,
  } = useDisclosure()

  const getCode = () => {
    if (appointment.isCancelled) {
      return 'cancelled'
    } else if (new Date(appointment.time) > new Date()) {
      return 'scheduled'
    } else {
      return 'complete'
    }
  }

  const code = getCode()

  // timzeone can be both null or undefined. Just shorten to undefined
  const memberTimezone = member?.time_zone || undefined

  const appointmentDefinition = useMemo(() => {
    return appointmentDefinitions?.find((d) => d.id === code)
  }, [appointmentDefinitions])

  const handleAppointmentClick = async () => {
    try {
      const appointmentType = await source.scheduling.appointmentTypes.retrieve(
        appointment?.type.id
      )
      const amplitudeEventProps: AppointmentModifyEventProps = {
        appointment_id: appointment.sourceId,
        appointment_type_name: appointmentType?.name ?? '',
        appointment_time: appointment.time,
      }
      Amplitude.appointmentModifyStart(amplitudeEventProps)
    } catch (error) {
      console.error('Error retrieving appointment type', error)
    }

    window.location.href = `${RoutePaths.SCHEDULING}?appointment=${appointment.sourceId}&source=${BookingEventSource.DASHBOARD_ACTION}&patient_action_definition=${appointmentDefinition?.id}`
  }

  const appointmentAction: ActionProperties = {
    buttonText: appointmentDefinition?.buttonText ?? '',
    disableButton: false,
    handleClick: handleAppointmentClick,
    isLink: false,
  }

  const calendarAction: ActionProperties = {
    buttonText: 'Add to calendar',
    disableButton: false,
    handleClick: () => calendarModalOnOpen(),
    isLink: true,
  }

  const appointmentSummaryAction: ActionProperties = {
    buttonText: 'Appointment summary',
    disableButton: false,
    handleClick: () => handleOpenNote(),
  }

  const getButtonActions = () => {
    if (code === 'scheduled') {
      return [appointmentAction, calendarAction]
    } else if (code == 'complete') {
      return note ? [appointmentSummaryAction] : []
    } else {
      return []
    }
  }

  const actions = appointmentDefinition?.buttonText ? getButtonActions() : []

  if (!appointmentDefinition) {
    return <></>
  } else {
    const title = formatAppointmentText(
      appointmentDefinition.title,
      appointment
    )
    let description = formatAppointmentText(
      appointmentDefinition.description,
      appointment
    )

    /// renderCardDescription looks for \\n to add a <br>
    if (!note && getCode() === 'complete') {
      description =
        description +
        '\\n' +
        'A summary of your appointment will be available here shortly.'
    }

    return (
      <>
        <MultiActionCard
          icon={appointmentDefinition.icon}
          title={title}
          description={description}
          actions={actions}
        />
        <CalendarModal
          isOpen={isCalendarModalOpen}
          onClose={calendarModalOnClose}
          appointment={appointment}
          timeZone={memberTimezone}
        />
        <AppointmentNoteModal note={showNote} onHide={hideShowNoteDialog} />
      </>
    )
  }
}

export default AppointmentCard
