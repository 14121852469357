import React, { useState } from 'react'
import {
  Box,
  ChakraProvider,
  Flex,
  useToast,
  Text,
  Button,
  Link,
} from '@chakra-ui/react'
import useUser from 'hooks/useUser/useUser'
import { RoutePaths } from 'containers/Core/Routes'
import OnboardingContainer from '../OnboardingContainer'
import InitialSignup from './InitialSignup'
import SignupMethodChoice from './SignupMethodChoice'
import EmailMethodChoice from './EmailMethodChoice'
import EmailOTP from '../../Login/EmailOTP'
import SMSOTP from '../../Login/SMSOTP'
import PasswordSignup from './PasswordSignup'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface SignupProps {
  redirectPath?: string
}

type SignupStep =
  | 'initial'
  | 'method-choice'
  | 'email-choice'
  | 'phone-otp'
  | 'email-otp'
  | 'password'

const Signup: React.FC<SignupProps> = ({ redirectPath }) => {
  const [step, setStep] = useState<SignupStep>('initial')
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [consentToMarketingSMS, setConsentToMarketingSMS] = useState<
    Date | undefined
  >(undefined)
  const toast = useToast()
  const { checkUserEligibilityPreSignup } = useUser()
  const { passwordEnabled, emailOtpEnabled, smsOtpEnabled } = useFlags()

  const params = new URLSearchParams(window.location.search)
  const isDev =
    window.location.origin.includes('localhost') ||
    window.location.origin.includes('allara-dev.vercel.app') ||
    window.location.origin.includes('allara-uat.vercel.app')
  const emailParam = params.get('email')
  // we use '+' on dev so that we can sign up using the same email for multiple users
  // if we leave it as is, we will get an error when the user tries to sign up
  const cleanedEmailParam =
    isDev && emailParam ? emailParam.replace(' ', '+') : emailParam
  const responderUuid = params.get('responder_uuid')
  const variantLabel = params.get('variant_label')
  const redirectUri =
    window.location.origin + redirectPath + window.location.search ??
    RoutePaths.ONBOARDING

  const handleInitialSubmit = async (values: {
    email: string
    phoneNumber: string
    consentToMarketingSMS: string
  }) => {
    setIsLoading(true)
    const eligibility = await checkUserEligibilityPreSignup(
      values.email,
      values.phoneNumber
    )

    if (values.phoneNumber && values.phoneNumber.length > 10) {
      toast({
        title: 'Error',
        description: 'Phone number must be 10 digits long',
        status: 'error',
        duration: 5000,
      })
      setIsLoading(false)
      return
    }

    if (eligibility.error) {
      toast({
        title: 'Error',
        description:
          typeof eligibility.error === 'string' ? (
            eligibility.error
          ) : (
            <Text mb={0}>
              {eligibility.error.message}{' '}
              {eligibility.error.link && (
                <Link href={eligibility.error.link} textDecoration="underline">
                  {eligibility.error.linkText || 'here'}
                </Link>
              )}
              {eligibility.error.link && '.'}
            </Text>
          ),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      setIsLoading(false)
      return
    }

    setEmail(values.email)
    setPhoneNumber(values.phoneNumber)
    setConsentToMarketingSMS(
      values.consentToMarketingSMS
        ? new Date(values.consentToMarketingSMS)
        : undefined
    )
    setStep('method-choice')
  }

  const getPreviousStep = (currentStep: SignupStep): SignupStep | null => {
    switch (currentStep) {
      case 'initial':
        return null

      case 'method-choice':
        return 'initial'

      case 'email-choice':
        // If SMS is disabled, go back to initial
        return smsOtpEnabled ? 'method-choice' : 'initial'

      case 'phone-otp':
        // If email options are disabled, go back to initial
        return emailOtpEnabled || passwordEnabled ? 'method-choice' : 'initial'

      case 'email-otp':
        // If password is disabled, skip email-choice
        if (!passwordEnabled && !smsOtpEnabled) {
          return 'initial'
        } else if (!passwordEnabled) {
          return 'method-choice'
        }

        return 'email-choice'

      case 'password':
        // If email OTP is disabled, skip email-choice
        if (!emailOtpEnabled && !smsOtpEnabled) {
          return 'initial'
        } else if (!emailOtpEnabled) {
          return 'method-choice'
        }

        return 'email-choice'

      default:
        return 'initial'
    }
  }

  const renderCurrentStep = () => {
    switch (step) {
      case 'initial':
        return (
          <InitialSignup
            onSubmit={handleInitialSubmit}
            initialEmail={email || cleanedEmailParam || ''}
            initialPhoneNumber={phoneNumber || ''}
            isLoading={isLoading}
          />
        )
      case 'method-choice':
        if (!emailOtpEnabled && !passwordEnabled) {
          setStep('phone-otp')
          return null
        }

        if (!smsOtpEnabled && !emailOtpEnabled && passwordEnabled) {
          setStep('password')
          return null
        }

        if (!smsOtpEnabled && (emailOtpEnabled || passwordEnabled)) {
          setStep('email-choice')
          return null
        }

        if (!smsOtpEnabled && !emailOtpEnabled && !passwordEnabled) {
          return (
            <Box textAlign="center">
              <Text>
                Sign up is currently unavailable. Please try again later.
              </Text>
              <Button
                onClick={() =>
                  (window.location.href =
                    window.location.origin + RoutePaths.LOGIN)
                }
                variant="link"
                mt={4}
              >
                Return to login
              </Button>
            </Box>
          )
        }

        return (
          <SignupMethodChoice
            email={email}
            phoneNumber={phoneNumber}
            onPhoneChoice={() => setStep('phone-otp')}
            onEmailChoice={() => setStep('email-choice')}
            onBack={() => setStep('initial')}
            showEmailOption={emailOtpEnabled || passwordEnabled}
          />
        )
      case 'email-choice':
        if (emailOtpEnabled && !passwordEnabled) {
          setStep('email-otp')
          return null
        }

        if (!emailOtpEnabled && passwordEnabled) {
          setStep('password')
          return null
        }

        return (
          <EmailMethodChoice
            onOTPChoice={() => setStep('email-otp')}
            onPasswordChoice={() => setStep('password')}
            onBack={() => {
              const previousStep = getPreviousStep('email-choice')
              setStep(previousStep || 'initial')
            }}
            showOTPOption={emailOtpEnabled}
            showPasswordOption={passwordEnabled}
          />
        )
      case 'phone-otp':
        return (
          <SMSOTP
            initialPhoneNumber={phoneNumber}
            mode="signup"
            email={email}
            consentToMarketingSMS={consentToMarketingSMS}
            responderUuid={responderUuid || ''}
            variantLabel={variantLabel || ''}
            redirectUri={redirectUri}
            onBack={() => {
              const previousStep = getPreviousStep('phone-otp')
              setStep(previousStep || 'initial')
            }}
          />
        )
      case 'email-otp':
        return (
          <EmailOTP
            initialEmail={email}
            mode="signup"
            phoneNumber={phoneNumber}
            consentToMarketingSMS={consentToMarketingSMS}
            responderUuid={responderUuid || ''}
            variantLabel={variantLabel || ''}
            redirectUri={redirectUri}
            onBack={() => {
              const previousStep = getPreviousStep('email-otp')
              setStep(previousStep || 'initial')
            }}
          />
        )
      case 'password':
        return (
          <PasswordSignup
            email={email}
            phoneNumber={phoneNumber}
            consentToMarketingSMS={consentToMarketingSMS}
            redirectUri={redirectUri}
            responderUuid={responderUuid || ''}
            variantLabel={variantLabel || ''}
            onBack={() => {
              const previousStep = getPreviousStep('password')
              setStep(previousStep || 'initial')
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <OnboardingContainer>
      <ChakraProvider>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          pt={10}
        >
          <Box
            bg="white"
            p={8}
            borderRadius="lg"
            boxShadow="lg"
            width="sm"
            position="relative"
          >
            {renderCurrentStep()}
          </Box>
        </Flex>
      </ChakraProvider>
    </OnboardingContainer>
  )
}

export default Signup
