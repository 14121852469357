"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentIntakeFormResource = void 0;
const BaseResource_1 = require("../BaseResource");
class AppointmentIntakeFormResource extends BaseResource_1.Resource {
    /**
     * Creates a new intake form for an appointment.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/appointment-intake-forms', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Updates an existing intake form with question responses.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/appointment-intake-forms/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.AppointmentIntakeFormResource = AppointmentIntakeFormResource;
