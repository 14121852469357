import { Modal, ModalProps } from 'react-bootstrap'
import UploadInsuranceInfo from './UploadInsuranceInfo'

interface UploadInsuranceCardFormProps extends ModalProps {
  onHide: () => void
}

const UploadInsuranceCardModal: React.FC<UploadInsuranceCardFormProps> = ({
  show,
  onHide,
}) => {
  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      centered
      className="upload-insurance-card-modal"
    >
      <Modal.Header className="fs-6" closeButton>
        Upload New Insurance Card
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center p-0 w-100 align-items-center">
        <UploadInsuranceInfo onSubmit={onHide} />
      </Modal.Body>
    </Modal>
  )
}

export default UploadInsuranceCardModal
