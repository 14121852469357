import {
  Box,
  Heading,
  Text,
  Checkbox,
  FormControl,
  Button,
} from '@chakra-ui/react'
import useStripePortal from 'hooks/useStripePortal'
import LoadingState from 'components/Spinner'
import { formatDateToString, formatNumberToDate } from 'containers/Core/utils'
import { ChakraPrimaryButton } from 'components/Button'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import useUser from 'hooks/useUser/useUser'
import { PROGRAMS, useIsInsurancePatient } from 'utils/program.utils'

interface NoDiscountConfirmationProps {
  cancellationReason: string
}

const NoDiscountConfirmation = ({
  cancellationReason,
}: NoDiscountConfirmationProps) => {
  const { membershipInfo, isLoading, cancelSubscription } = useStripePortal()
  const history = useHistory()
  const { user } = useUser()
  const isInsuranceUser = useIsInsurancePatient(user)

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      try {
        cancelSubscription({ cancellationReason })
        const optionalToast = {
          title: isInsuranceUser
            ? 'Deactivation request received'
            : 'Cancellation request received',
          status: 'success',
        }
        history.push(RoutePaths.DASHBOARD_PROFILE, { optionalToast })
      } catch (error) {
        const optionalToast = {
          title: 'There was an error processing your request',
          description: 'Please reach out to Patient Concierge for assistance.',
          status: 'error',
        }
        history.push(RoutePaths.DASHBOARD_PROFILE, { optionalToast })
        console.error(error)
      }
    },
  })

  return (
    <Box m={10}>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <Heading as="h4" size="m" fontWeight={'semibold'}>
            {isInsuranceUser
              ? 'Deactivate my account'
              : 'Cancel my subscription'}
          </Heading>
          <Box mt={6} color={'var(--gray-dark)'}>
            <Text mb={1}>BILLING INFO:</Text>
            <Text>
              Outside services deemed necessary to your care, such as lab tests
              or imaging, may have been ordered by your Allara provider (you
              would have been advised in advance if such services were ordered).
              You may receive a separate bill directly from the outside services
              provider for any services rendered.
            </Text>
          </Box>
          {!isInsuranceUser && (
            <Box mt={6} color={'var(--gray-dark)'}>
              <Text mb={1}>CANCELLATION DATE</Text>
              <Text>
                Your subscription will be cancelled{' '}
                {formatDateToString(
                  formatNumberToDate(
                    membershipInfo?.subscription.currentPeriodEnd
                  )
                )}
              </Text>
            </Box>
          )}
          <form onSubmit={formik.handleSubmit}>
            <FormControl isRequired>
              <Checkbox>
                I acknowledge I am responsible for any charges charges from past
                appointments assigned by my insurance or outside provider
              </Checkbox>
            </FormControl>
            <Box mt={6}>
              <Button
                mr={4}
                onClick={() => history.push(RoutePaths.DASHBOARD_PROFILE)}
              >
                Cancel
              </Button>
              <ChakraPrimaryButton type="submit">
                {isInsuranceUser
                  ? 'Confirm deactivation'
                  : 'Confirm cancellation'}
              </ChakraPrimaryButton>
            </Box>
          </form>
        </>
      )}
    </Box>
  )
}

export default NoDiscountConfirmation
