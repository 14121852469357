import logo from 'assets/images/logo-blue.svg'
import { ReactNode } from 'react'
import { ChakraProvider, Image as ChakraImage } from '@chakra-ui/react'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import './styles.scss'

interface OnboardingContainerProps {
  children: ReactNode
  onClose?: () => void
}

const OnboardingContainer: React.FC<OnboardingContainerProps> = ({
  children,
  onClose,
}) => {
  return (
    <ChakraProvider>
      <div className="onboarding">
        {onClose && (
          <CloseIcon
            className="cursor-pointer d-block position-absolute top-2 right-3 m-3 fs-1"
            onClick={onClose}
            width="18px"
            height="18px"
          />
        )}
        <div className="logo">
          <ChakraImage src={logo} mb={10} />
        </div>
        {children}
      </div>
    </ChakraProvider>
  )
}

export default OnboardingContainer
