import { RoutePaths } from 'containers/Core/Routes'
import { useHistory } from 'react-router-dom'
import InsuranceCoveredYellow from 'assets/images/insurance-covered-yellow.png'
import OnboardingContainer from '../OnboardingContainer/index'

import './styles.scss'
import { useEffect, useMemo } from 'react'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from 'utils/amplitude.utils'
import Linkify from 'components/Linkify'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Image,
  Text,
} from '@chakra-ui/react'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { PROGRAMS } from 'utils/program.utils'

interface InsuranceCoverageProps {
  coverage: 'eligible' | 'ineligible' | 'unknown'
}

const InsuranceCoverage: React.FC<InsuranceCoverageProps> = ({ coverage }) => {
  const history = useHistory()
  const { trackPageView } = UseOnboardingPageView()
  const { variantLabel, setSelectedProgram } = useOnboardingContext()
  const flags = useFlags()
  const { noFeeInsuranceMembership } = flags

  const handleClick = () => {
    Amplitude.insuranceCoverage(
      coverage === 'eligible' ? 'covered' : 'testing-covered',
      variantLabel
    )

    if (coverage === 'eligible' && noFeeInsuranceMembership) {
      setSelectedProgram(PROGRAMS.INSURANCE)
      history.push(
        `${RoutePaths.CONSENT_TO_TELEHEALTH}${window.location.search}`
      )
    } else {
      history.push(`${RoutePaths.PROGRAMS}${window.location.search}`)
    }
  }

  // Track page view
  useEffect(() => {
    trackPageView(coverage === 'eligible' ? 'coveredInsurance' : 'coveredLabs')
    Amplitude.insuranceCoverageLoaded(
      coverage === 'eligible'
        ? 'covered'
        : coverage === 'ineligible'
        ? 'testing-covered'
        : 'more-info',
      variantLabel
    )
  }, [])

  const title = useMemo(() => {
    switch (coverage) {
      case 'unknown':
        return 'We need more information'
    }
  }, [])

  const subtitle = useMemo(() => {
    switch (coverage) {
      case 'eligible':
        return 'Great news, we’re in network with your insurance'
      case 'unknown':
        return undefined
      case 'ineligible':
      default:
        return "Let's Get Started"
    }
  }, [])

  const text: string[] = useMemo(() => {
    switch (coverage) {
      case 'eligible':
        return [
          'We’ll bill all covered services like visits, labs, and prescriptions through your insurance. Your exact costs will depend on your plan coverage, copay, and deductible.',
        ]
      case 'unknown':
        return [
          "To better understand your eligibility, please reach out to Billing@allarahealth.com. Our team can help you navigate your coverage for Allara's services.",
          'To speed up the process, please include your state of residence and a photo of the front and back of your insurance card.',
        ]
      case 'ineligible':
      default:
        return [
          "You'll pay a subscription fee that covers the cost your monthly care and gives you convenient access to concierge support.",
          'Labs and prescriptions may be covered by your insurance. The amount you pay will be dependent on your plan.',
        ]
    }
  }, [])

  const note = useMemo(() => {
    switch (coverage) {
      case 'ineligible':
        return (
          <Text fontFamily="Inter" fontSize="12px" mb={0}>
            <Text as="span" fontWeight="bold">
              Note:
            </Text>{' '}
            If you have a high deductible plan for labs or a closed network
            insurance provider (such as Kaiser), we have negotiated discounted
            lab rates for you and will work with you to understand your benefits
            and get the most affordable cost of care. To learn more information,
            visit our{' '}
            <Text
              as="a"
              href="https://allarahealth.tawk.help/article/what-if-my-labs-aren%E2%80%99t-covered-by-insurance"
              target="_blank"
              rel="noopener noreferrer"
              color="blue.500"
              textDecoration="underline"
              _hover={{ color: 'blue.600' }}
            >
              FAQs.
            </Text>
          </Text>
        )
      case 'eligible':
      case 'unknown':
        return null
    }
  }, [])

  const canContinue = useMemo(() => coverage !== 'unknown', [])

  return (
    <OnboardingContainer>
      <Box
        backgroundColor="white"
        borderRadius="lg"
        padding={['16px', '32px']}
        width={['95%', '80%', '60%', '30%']}
        maxWidth="600px"
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Image
          src={InsuranceCoveredYellow}
          alt="coverage-icon"
          alignSelf="center"
          width="66px"
        />
        <Text fontFamily="Instrument Serif" fontSize="32px" textAlign="center">
          {title}
        </Text>
        {subtitle && (
          <Text
            fontFamily="Instrument Serif"
            fontSize="24px"
            textAlign="center"
            alignSelf="center"
          >
            {subtitle}
          </Text>
        )}
        {text.map((line, i) => (
          <Linkify key={i}>
            <Text fontFamily="Inter" fontSize="16px">
              {line}
            </Text>
          </Linkify>
        ))}
        <div className="spacer-1"></div>
        {canContinue && (
          <>
            <Button
              onClick={handleClick}
              width={'100%'}
              backgroundColor={'var(--blue-dark)'}
              color={'white'}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
                color: 'white',
              }}
              mb={8}
            >
              Continue
            </Button>
            {note}
          </>
        )}
        {coverage === 'eligible' && (
          <div>
            <Text fontFamily="Instrument Serif" fontSize="24px">
              FAQs
            </Text>

            <Accordion defaultIndex={[0]} allowMultiple mt={2}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      How much will I pay out of pocket?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  This depends on your individual insurance plan. We will bill
                  your insurance for services including visits, lab work,
                  imaging, and prescription medications. You will be responsible
                  for the cost of copays, coinsurance, or deductible fees
                  associated with your visits.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Need more help understanding your insurance coverage?{' '}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Email us at billing@allarahealth.com and we are happy to help.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        )}
      </Box>
    </OnboardingContainer>
  )
}

export default InsuranceCoverage
