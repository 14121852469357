import { useMemo, useState } from 'react'
import { Formik, Form } from 'formik'
import { insuranceFormSchema } from './utils'
import useInsurance from 'hooks/useInsuranceCard'
import usePayers from 'hooks/usePayers'
import {
  ModalOverlay,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  useToast,
  ModalBody,
} from '@chakra-ui/react'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { Links, RelationshipToInsured } from './constants'
import {
  Box,
  Button,
  Text,
  SimpleGrid,
  Input,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
} from '@chakra-ui/react'
import { STATES } from 'containers/Core/utils'

export interface InsuranceFormValues {
  insured_person_dob?: string
  insured_person_first_name?: string
  insured_person_last_name?: string
  insured_person_address_line_1?: string
  insured_person_address_line_2?: string
  insured_person_city?: string
  insured_person_state?: string
  insured_person_zip?: string
  relationship_to_insured: string
  group_id?: string
  member_id: string
  payer_id?: string
  other_payer?: string
  rank: 'primary' | 'secondary' | 'tertiary'
}

const initialValues: InsuranceFormValues = {
  insured_person_dob: '',
  insured_person_first_name: '',
  insured_person_last_name: '',
  relationship_to_insured: 'Self',
  group_id: '',
  member_id: '',
  payer_id: '',
  other_payer: '',
  rank: 'primary',
}

const OTHER_PAYER_VALUE = 'other'

type InsuranceInfoFormProps = {
  title?: string
  subTitle?: string
  supportComponent?: React.ReactNode
  onSubmit?: () => void
}

const InsuranceInfoForm: React.FC<InsuranceInfoFormProps> = ({
  title,
  subTitle,
  supportComponent,
  onSubmit,
}) => {
  const toast = useToast()
  const { payers } = usePayers()
  const { setAlertText } = useAlert()
  const { handleUploadInsuranceData } = useInsurance()
  const [modalOpen, setModalOpen] = useState(false)

  const payerOptions = useMemo(
    () => payers.map((p) => ({ label: p.name, id: p.id })),
    [payers]
  )

  const onSubmitHandler = async (
    values: InsuranceFormValues,
    { setSubmitting }: any
  ) => {
    try {
      const addressLine2 = values.insured_person_address_line_2
        ? ` ${values.insured_person_address_line_2}`
        : ''
      const insured_person_address = values.insured_person_address_line_1
        ? `${values.insured_person_address_line_1}${addressLine2}`
        : undefined

      const dataUploadResponse = await handleUploadInsuranceData({
        ...values,
        payer_id:
          values.payer_id === OTHER_PAYER_VALUE ? undefined : values.payer_id,
        insured_person_address,
        relationship_to_insured:
          values.relationship_to_insured === 'Self'
            ? undefined
            : values.relationship_to_insured,
      })

      if (
        !dataUploadResponse ||
        (dataUploadResponse.status !== 201 && dataUploadResponse.status !== 200)
      ) {
        setSubmitting(false)
        toast({
          title: 'An error occurred',
          description:
            'We were unable to upload your insurance card. Please try again or email concierge@allarahealth.com if the problem persists',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      setSubmitting(false)
      onSubmit?.()
    } catch (error) {
      setSubmitting(false)
      toast({
        title: 'An error occurred',
        description:
          'We were unable to upload your insurance card. Please try again or email concierge@allarahealth.com if the problem persists',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Box
      backgroundColor="white"
      borderRadius="lg"
      padding={['16px', '24px']}
      mx="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {title && (
        <Text
          fontSize="32px"
          fontWeight="medium"
          textAlign="center"
          mb={4}
          fontFamily="Instrument Serif"
        >
          {title}
        </Text>
      )}
      <Text textAlign="center" mb={6}>
        {subTitle ?? 'Please share your insurance information'}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={insuranceFormSchema}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.payer_id && touched.payer_id}>
                <FormLabel>Payer</FormLabel>
                <Select
                  name="payer_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.payer_id}
                >
                  <option value="">Select payer</option>
                  {payerOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                  <option value={OTHER_PAYER_VALUE}>Other</option>
                </Select>
                <FormErrorMessage>{errors.payer_id}</FormErrorMessage>
              </FormControl>

              {values.payer_id === OTHER_PAYER_VALUE && (
                <FormControl
                  isInvalid={!!errors.other_payer && touched.other_payer}
                >
                  <FormLabel>Other insurance carrier name*</FormLabel>
                  <Input
                    name="other_payer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.other_payer}
                  />
                  <FormErrorMessage>{errors.other_payer}</FormErrorMessage>
                </FormControl>
              )}

              <SimpleGrid columns={[1, 2]} spacing={4} w="100%">
                <FormControl
                  isInvalid={!!errors.member_id && touched.member_id}
                >
                  <FormLabel>Member ID*</FormLabel>
                  <Input
                    name="member_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.member_id}
                  />
                  <FormErrorMessage>{errors.member_id}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.group_id && touched.group_id}>
                  <FormLabel>Group ID</FormLabel>
                  <Input
                    name="group_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.group_id}
                  />
                  <FormErrorMessage>{errors.group_id}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <FormControl
                isInvalid={
                  !!errors.relationship_to_insured &&
                  touched.relationship_to_insured
                }
              >
                <FormLabel>Patient&apos;s relation to policyholder*</FormLabel>
                <Select
                  name="relationship_to_insured"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.relationship_to_insured}
                >
                  {RelationshipToInsured.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.relationship_to_insured}
                </FormErrorMessage>
              </FormControl>

              {values.relationship_to_insured !== 'Self' && (
                <Box w="100%">
                  <Text fontWeight="medium" mb={2}>
                    Insured Person Details
                  </Text>
                  <Text fontSize="sm" mb={4}>
                    Please complete the following personal details about the
                    policy holder
                  </Text>
                  <VStack spacing={4}>
                    <SimpleGrid columns={[1, 2]} spacing={4} w="100%">
                      <FormControl
                        isInvalid={
                          !!errors.insured_person_first_name &&
                          touched.insured_person_first_name
                        }
                      >
                        <FormLabel>First Name*</FormLabel>
                        <Input
                          name="insured_person_first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.insured_person_first_name}
                        />
                        <FormErrorMessage>
                          {errors.insured_person_first_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!errors.insured_person_last_name &&
                          touched.insured_person_last_name
                        }
                      >
                        <FormLabel>Last Name*</FormLabel>
                        <Input
                          name="insured_person_last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.insured_person_last_name}
                        />
                        <FormErrorMessage>
                          {errors.insured_person_last_name}
                        </FormErrorMessage>
                      </FormControl>
                    </SimpleGrid>

                    <FormControl
                      isInvalid={
                        !!errors.insured_person_dob &&
                        touched.insured_person_dob
                      }
                    >
                      <FormLabel>Date of Birth*</FormLabel>
                      <Input
                        type="date"
                        name="insured_person_dob"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.insured_person_dob}
                      />
                      <FormErrorMessage>
                        {errors.insured_person_dob}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!errors.insured_person_address_line_1 &&
                        touched.insured_person_address_line_1
                      }
                    >
                      <FormLabel>Address*</FormLabel>
                      <Input
                        name="insured_person_address_line_1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.insured_person_address_line_1}
                      />
                      <FormErrorMessage>
                        {errors.insured_person_address_line_1}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Apartment, Suite, Etc</FormLabel>
                      <Input
                        name="insured_person_address_line_2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.insured_person_address_line_2}
                      />
                    </FormControl>

                    <SimpleGrid columns={[1, 3]} spacing={4} w="100%">
                      <FormControl
                        isInvalid={
                          !!errors.insured_person_city &&
                          touched.insured_person_city
                        }
                      >
                        <FormLabel>City*</FormLabel>
                        <Input
                          name="insured_person_city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.insured_person_city}
                        />
                        <FormErrorMessage>
                          {errors.insured_person_city}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!errors.insured_person_state &&
                          touched.insured_person_state
                        }
                      >
                        <FormLabel>State*</FormLabel>
                        <Select
                          name="insured_person_state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.insured_person_state}
                        >
                          {STATES.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {errors.insured_person_state}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!errors.insured_person_zip &&
                          touched.insured_person_zip
                        }
                      >
                        <FormLabel>Zipcode*</FormLabel>
                        <Input
                          name="insured_person_zip"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.insured_person_zip}
                        />
                        <FormErrorMessage>
                          {errors.insured_person_zip}
                        </FormErrorMessage>
                      </FormControl>
                    </SimpleGrid>
                  </VStack>
                </Box>
              )}

              <Button
                type="submit"
                backgroundColor={'var(--blue-dark)'}
                color="white"
                _hover={{
                  backgroundColor: 'var(--blue-dark-hover)',
                  color: 'white',
                }}
                isLoading={isSubmitting}
                w="100%"
                mt={6}
                isDisabled={
                  isSubmitting ||
                  !(
                    values.payer_id &&
                    (values.payer_id === OTHER_PAYER_VALUE
                      ? values.other_payer
                      : true) &&
                    values.member_id &&
                    values.relationship_to_insured &&
                    (values.relationship_to_insured !== 'Self'
                      ? values.insured_person_dob &&
                        values.insured_person_first_name &&
                        values.insured_person_last_name &&
                        values.insured_person_address_line_1 &&
                        values.insured_person_city &&
                        values.insured_person_state &&
                        values.insured_person_zip
                      : true)
                  )
                }
              >
                Submit
              </Button>

              <Button
                onClick={() => setModalOpen(true)}
                variant="link"
                textDecoration={'underline'}
              >
                Why do we need this?
              </Button>

              <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                size="lg"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Why do we collect insurance info?</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <>
                      <Text>
                        We&apos;ve confirmed your insurance is eligible, but we
                        still need to verify your specific benefits. This means
                        we&apos;ll review your insurance details to determine
                        your exact coverage. This verification process is at no
                        additional cost to you, and we won&apos;t be contacting
                        your insurance for billing at this stage.
                      </Text>
                      <Text>
                        While we strive to provide accurate cost estimates, the
                        final amount determined by your insurance may vary. You
                        will be responsible for any copay/coinsurance amount set
                        by your insurance provider, which will be billed after
                        your first medical visit is complete.
                      </Text>
                      <Text>
                        If we discover your insurance doesn&apos;t cover our
                        services, we&apos;ll contact you promptly to discuss
                        self-pay options you may qualify for. We&apos;re
                        committed to providing transparent information about
                        your coverage and ensuring you understand your financial
                        responsibility.
                      </Text>
                    </>
                  </ModalBody>
                </ModalContent>
              </Modal>

              {supportComponent ?? (
                <>
                  <Text fontSize="sm" textAlign="center" mt={4} mb={0}>
                    Note: If you have any Blue plan, please select the
                    &apos;Blue Cross Blue Shield&apos; option in the drop down.
                  </Text>
                  <Text fontSize="sm" textAlign="center">
                    If you do not see your carrier listed in the dropdown or
                    need some help, please email{' '}
                    <a
                      href={`mailto: ${Links.ContactEmail}`}
                      style={{ textDecoration: 'underline' }}
                    >
                      {Links.ContactEmail}
                    </a>
                    .
                  </Text>
                </>
              )}
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default InsuranceInfoForm
