import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingContainer from '../OnboardingContainer'
import InsuranceProviderSelect from 'components/Select/InsuranceProviderSelect'
import useEligibilityInfo from 'hooks/useUser/useEligibilityInfo'
import { WaitlistUsersDto } from '../../../hooks/useUser/useEligibilityInfo'
import produce from 'immer'
import withEligibility, { WithEligibilityProps } from 'hoc/withEligibility'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { RoutePaths } from '../../Core/Routes'
import './styles.scss'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from 'utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { Box, Button, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { Formik, Form } from 'formik'

const InsuranceEligibility: React.FC<WithEligibilityProps> = ({
  waitlistUser,
}) => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const { trackPageView } = UseOnboardingPageView()
  const { handleUpdateWaitlistUser } = useEligibilityInfo()
  const [loading, setLoading] = useState<boolean>(false)
  const [showProviderInput, setShowProviderInput] = useState(false)
  const { variantLabel } = useOnboardingContext()

  const [selectedInsuranceProvider, setSelectedInsuranceProvider] = useState<
    string | undefined
  >(undefined)

  const handlePlanTypeSelection = (isNonCommercePlan: boolean) => {
    if (isNonCommercePlan) {
      Amplitude.insurancePlanEligibility('yes', variantLabel)
      onContinue('NONCOMMERCE')
    } else {
      Amplitude.insurancePlanEligibility('no', variantLabel)
      setShowProviderInput(true)
    }
  }

  const onContinue = useCallback(
    (planType: 'NONCOMMERCE' | 'COMMERCE', provider?: string) => {
      setLoading(true)
      const waitlistUserDto: WaitlistUsersDto = { ...waitlistUser }
      const nextWaitlistUser = produce(waitlistUserDto, (draftWaitlistUser) => {
        draftWaitlistUser.insurancePlan = planType

        if (planType === 'COMMERCE') {
          draftWaitlistUser.insuranceProvider = provider
          draftWaitlistUser.insuranceStateCode = waitlistUser.state.code
        }
      })

      if (provider) {
        Amplitude.insuranceEligibility(
          waitlistUser.state.code,
          provider,
          variantLabel
        )
      }

      handleUpdateWaitlistUser(nextWaitlistUser)
        .then((res) => {
          setLoading(false)

          if (res) {
            const { eligibleProducts, insurancePlan } = res.data

            const isInsuranceEligible = eligibleProducts.find(
              (prod) =>
                prod.type === PROGRAMS.INSURANCE ||
                prod.type === PROGRAMS.INSURANCE_DIAGNOSTIC
            )

            let path = RoutePaths.INSURANCE_NOT_COVERED

            if (isInsuranceEligible !== undefined) {
              path =
                insurancePlan !== 'NONCOMMERCE'
                  ? RoutePaths.INSURANCE_COVERED
                  : RoutePaths.INSURANCE_NOT_COVERED
            }

            history.push(`${path}${window.location.search}`)
          }
        })
        .catch(() => {
          setLoading(false)
          setAlertText('Error checking for eligibility', 'An Error Occurred')
        })
    },
    [waitlistUser]
  )

  // Track page view
  useEffect(() => {
    trackPageView('insuranceEligibility')
  }, [])

  return (
    <OnboardingContainer>
      <Box
        backgroundColor="white"
        borderRadius="lg"
        padding={['16px', '24px']}
        width={['95%', '80%', '60%', '30%']}
        maxWidth="600px"
        mx="auto"
      >
        {!showProviderInput ? (
          <>
            <Text
              fontFamily="Instrument Serif"
              fontSize="32px"
              textAlign="center"
            >
              Do you have one of the following plan types?
            </Text>
            <UnorderedList alignItems="left" pl={4}>
              <ListItem>Medicaid</ListItem>
              <ListItem>Medicare</ListItem>
              <ListItem>Tricare</ListItem>
              <ListItem>HMO</ListItem>
            </UnorderedList>
            <Button
              onClick={() => handlePlanTypeSelection(false)}
              width="100%"
              mb={4}
              backgroundColor={'var(--blue-dark)'}
              color={'white'}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
                color: 'white',
              }}
            >
              No
            </Button>
            <Button
              variant="outline"
              onClick={() => handlePlanTypeSelection(true)}
              width="100%"
              borderColor={'var(--blue-dark)'}
              color={'var(--blue-dark)'}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
                color: 'white',
              }}
            >
              Yes
            </Button>
          </>
        ) : (
          <Formik
            initialValues={{
              insuranceProvider: selectedInsuranceProvider || '',
            }}
            onSubmit={(values) => {
              const provider = values.insuranceProvider
              setSelectedInsuranceProvider(provider)
              onContinue('COMMERCE', provider)
            }}
          >
            {({ values, isValid }) => (
              <Form>
                <Text
                  fontFamily="Instrument Serif"
                  fontSize="32px"
                  textAlign="center"
                >
                  Select your insurance provider:
                </Text>
                <Box width="100%" mb={8}>
                  <InsuranceProviderSelect name="insuranceProvider" />
                </Box>
                <Button
                  type="submit"
                  disabled={!values.insuranceProvider}
                  width="100%"
                  mb={4}
                  backgroundColor={'var(--blue-dark)'}
                  color={'white'}
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                    color: 'white',
                  }}
                  isLoading={loading}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </OnboardingContainer>
  )
}

export default withEligibility(InsuranceEligibility)
