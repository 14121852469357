import { CTACard } from './CTACard'

type InitialRdCTACardProps = {
  clickHandler: () => void
}

export const InitialRdCTACard = ({ clickHandler }: InitialRdCTACardProps) => {
  const imageSrc = '/images/Allara_Illustration_Nutrition-10.png'
  const cardText = `Book with a Registered Dietitian for an introduction to Allara's nutrition program. At your first appointment, you'll work with your RD to craft achievable goals that meet you where you are.`
  const buttonText = 'Book a nutrition visit'
  return (
    <CTACard
      imageSrc={imageSrc}
      clickHandler={clickHandler}
      cardText={cardText}
      buttonText={buttonText}
    />
  )
}
