import { memo, useCallback, useMemo, useState, useEffect } from 'react'
import { Formik } from 'formik'
import ResetPasswordModal from './ResetPasswordModal'
import { ChakraPrimaryButton as PrimaryButton } from 'components/Button'
import useUser, { UpdateUserDto } from 'hooks/useUser/useUser'
import useStripePortal from 'hooks/useStripePortal'
import { AxiosError } from 'axios'
import {
  Box,
  Grid,
  GridItem,
  Button,
  Icon,
  VStack,
  ButtonGroup,
  useToast,
  useBreakpointValue,
  Input,
  HStack,
  Link,
} from '@chakra-ui/react'
import { unformatPhoneNumber } from 'containers/Core/utils'
import { AiFillEdit } from 'react-icons/ai'
import { FormInputs } from 'components/Form'
import validator from 'validator'
import { User, AddressType } from 'hooks/useUser/useUser'
import { useHistory, Prompt } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Address from 'components/Address'
import { useStytch, useStytchSession } from '@stytch/react'
import { ddLog } from 'utils/logs.utils'
import { StytchAPIError } from '@stytch/vanilla-js'
import VerificationModal from './VerificationModal'
import UnsavedChangesModal from './UnsavedChangesModal'

export interface optionalToast {
  title?: string
  description?: string
  status: 'info' | 'warning' | 'success' | 'error' | 'loading' | undefined
}

interface EditProfileFormProps {
  setIsLoading: (isLoading: boolean) => void
  user: User
}

const EditProfileForm = ({ setIsLoading, user }: EditProfileFormProps) => {
  const { stripePortalLink } = useStripePortal()
  const { addresses, handleUpdateProfile } = useUser()
  const [edit, setEdit] = useState<boolean>(false)
  const { session } = useStytchSession()
  const [showModal, setShowModal] = useState<boolean>(false)
  const toast = useToast()
  const history = useHistory()
  const { selfServiceCancellation } = useFlags()
  const stytchClient = useStytch()
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [verificationMethod, setVerificationMethod] = useState<'sms' | 'email'>(
    'email'
  )
  const [verifyingField, setVerifyingField] = useState<
    'email' | 'phoneNumber' | null
  >(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [methodId, setMethodId] = useState('')
  const [loading, setLoading] = useState(false)
  const [showUnsavedModal, setShowUnsavedModal] = useState(false)
  const [nextLocation, setNextLocation] = useState<string | null>(null)

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (edit) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    if (edit) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [edit])

  const handleNavigationBlocking = useCallback(
    (nextLoc: any) => {
      if (edit) {
        setNextLocation(nextLoc.pathname)
        setShowUnsavedModal(true)
        return false
      }

      return true
    },
    [edit]
  )

  const handleConfirmNavigation = useCallback(() => {
    setEdit(false)
    setShowUnsavedModal(false)

    if (nextLocation) {
      history.push(nextLocation)
    }
  }, [nextLocation, history])

  const handleCancelNavigation = useCallback(() => {
    setShowUnsavedModal(false)
    setNextLocation(null)
  }, [])

  const initialValues = useMemo<UpdateUserDto>(
    () => ({
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      preferredName: user.preferredName ?? '',
      email: user.email ?? '',
      phoneNumber: user.phoneNumber ?? '',
      addresses: [
        {
          address_line1: addresses?.[0]?.address_line1 ?? '',
          address_line2: addresses?.[0]?.address_line2 ?? '',
          city: addresses?.[0]?.city ?? '',
          state: addresses?.[0]?.state ?? '',
          zip: addresses?.[0]?.zip ?? '',
          type: AddressType.PRIMARY,
        },
      ],
    }),
    [
      user.firstName,
      user.lastName,
      user.preferredName,
      user.email,
      user.phoneNumber,
      addresses,
    ]
  )

  const exitEditMode = useCallback(() => setEdit(false), [])
  const enterEditMode = useCallback(() => {
    const factors = session?.authentication_factors || []

    if (factors.length === 1) {
      const factor = factors[0]

      if (factor.delivery_method === 'email') {
        setVerificationMethod('sms')
        setShowVerificationModal(true)
      } else if (factor.delivery_method === 'sms') {
        setVerificationMethod('email')
        setShowVerificationModal(true)
      } else {
        setEdit(true)
      }
    } else {
      setEdit(true)
    }
  }, [session])

  const hideResetPasswordModal = useCallback(() => setShowModal(false), [])

  const onSubmit = useCallback((updateUserDto: UpdateUserDto) => {
    const updateUserDtoWithUnformattedPhone = {
      ...updateUserDto,
      phoneNumber: unformatPhoneNumber(updateUserDto.phoneNumber),
    }
    setIsLoading(true)
    handleUpdateProfile(updateUserDtoWithUnformattedPhone)
      .then(() => {
        setIsLoading(false)
        // TODO: Look into why component isn't re-rendering after successful submission
        history.push(RoutePaths.DASHBOARD)
        exitEditMode()
        toast({
          title: 'Profile updated.',
          description: 'Your profile has been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
      .catch((err) => {
        setIsLoading(false)
        const typedErr = err as AxiosError
        let error = 'Changes not saved. Please try again.'

        if (typedErr.response?.status === 409) {
          error = `Cannot update email to ${updateUserDto.email} because another account exists under that email. Please contact concierge@allarahealth.com to fix this issue.`
        } else if (typedErr.response?.status) {
          error = `Unable to update user information, please contact concierge@allarahealth.com for help.`
        }

        toast({
          title: 'An error occurred.',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }, [])

  const handleResetPassword = useCallback(() => {
    stytchClient.passwords
      .resetByEmailStart({
        reset_password_template_id: 'password_reset',
        email: user.email,
      })
      .then(() => setShowModal(true))
      .catch((error) => {
        toast({
          title: 'An error occurred.',
          description:
            'Unable to send password reset link. Please email concierge@allarahealth.com.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })

        if (error instanceof StytchAPIError) {
          ddLog('info', 'Stytch Error::edit profile password reset error', {
            id: user.id,
            errorMessage: error.error_message,
            requestId: error.request_id,
          })
        }
      })
  }, [stytchClient, setShowModal])

  const handleVerificationSuccess = useCallback(() => {
    setShowVerificationModal(false)
    setEdit(true)
  }, [])

  const handleContactVerification = useCallback(
    async (field: 'email' | 'phoneNumber', value: string) => {
      try {
        setLoading(true)

        if (field === 'email') {
          const response = await stytchClient.otps.email.send(value)
          setMethodId(response.method_id)
        } else {
          const response = await stytchClient.otps.sms.send(`+1${value}`)
          setMethodId(response.method_id)
        }

        setVerifyingField(field)
        toast({
          title: 'Verification code sent',
          status: 'success',
          duration: 3000,
        })
      } catch (error) {
        toast({
          title: 'Error sending verification code',
          status: 'error',
          duration: 5000,
        })
      } finally {
        setLoading(false)
      }
    },
    [stytchClient, toast]
  )

  const verifyCode = useCallback(async () => {
    try {
      setLoading(true)
      await stytchClient.otps.authenticate(verificationCode, methodId, {
        session_duration_minutes: 60,
      })
      setVerifyingField(null)
      setVerificationCode('')
      toast({
        title: 'Verification successful',
        status: 'success',
        duration: 3000,
      })
    } catch (error) {
      toast({
        title: 'Invalid verification code',
        status: 'error',
        duration: 5000,
      })
    } finally {
      setLoading(false)
    }
  }, [verificationCode, methodId, stytchClient])

  const templateAreas = useBreakpointValue({
    base: `"form" "tools"`,
    md: `"form tools"`,
  })

  const handleSaveAndNavigate = useCallback(
    async (values: UpdateUserDto) => {
      try {
        setIsLoading(true)
        await handleUpdateProfile({
          ...values,
          phoneNumber: unformatPhoneNumber(values.phoneNumber),
        })
        setEdit(false)
        setShowUnsavedModal(false)

        if (nextLocation) {
          history.push(nextLocation)
        }

        toast({
          title: 'Profile updated.',
          description: 'Your profile has been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } catch (err) {
        const typedErr = err as AxiosError
        let error = 'Changes not saved. Please try again.'

        if (typedErr.response?.status) {
          error = `Unable to update user information, please contact concierge@allarahealth.com for help.`
        }

        toast({
          title: 'An error occurred.',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [handleUpdateProfile, history, nextLocation, toast]
  )

  return (
    <Box borderWidth="1px" borderRadius="lg">
      <Prompt when={edit} message={handleNavigationBlocking} />
      <Grid
        templateAreas={templateAreas}
        gap={[5, 40]}
        templateColumns={{ base: '1fr', md: '2fr 1fr' }}
        pt={[1, 5]}
        pb={[1, 5]}
      >
        <GridItem area="form">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleSubmit, errors, getFieldProps, handleReset, values }) => (
              <form onSubmit={handleSubmit}>
                <VStack spacing={6} pl={5} align="start">
                  <Grid
                    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    gap={6}
                    alignSelf="stretch"
                  >
                    <GridItem>
                      <FormInputs
                        htmlFor="firstName"
                        label="First Name"
                        inputtype="text"
                        id="firstName"
                        {...getFieldProps('firstName')}
                        isDisabled={!edit}
                      />
                    </GridItem>
                    <GridItem>
                      <FormInputs
                        htmlFor="lastName"
                        label="Last Name"
                        inputtype="text"
                        id="lastName"
                        {...getFieldProps('lastName')}
                        isDisabled={!edit}
                      />
                    </GridItem>
                    <GridItem>
                      <FormInputs
                        htmlFor="preferredName"
                        label="Preferred First Name (Optional)"
                        inputtype="text"
                        id="preferredName"
                        {...getFieldProps('preferredName')}
                        isDisabled={!edit}
                      />
                    </GridItem>
                    <GridItem>
                      <FormInputs
                        htmlFor="email"
                        label="Email Address"
                        inputtype="text"
                        id="email"
                        {...getFieldProps('email')}
                        isDisabled={!edit}
                        validate={(value) => {
                          let error

                          if (validator.isEmail(value) === false) {
                            error = 'Must be a valid email address'
                          }

                          return error
                        }}
                        errors={errors.email}
                        rightElement={
                          edit &&
                          !verificationCode &&
                          values.email !== user.email &&
                          verifyingField !== 'email' ? (
                            <Button
                              onClick={() =>
                                handleContactVerification(
                                  'email',
                                  values.email ?? ''
                                )
                              }
                              isDisabled={!!errors.email}
                              backgroundColor="var(--blue-dark)"
                              color="white"
                              _hover={{
                                backgroundColor: 'var(--blue-dark-hover)',
                              }}
                            >
                              Next
                            </Button>
                          ) : null
                        }
                      />
                      {verifyingField === 'email' && (
                        <HStack mt={2} align="start">
                          <Input
                            placeholder="******"
                            value={verificationCode}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                          />
                          <Button
                            onClick={verifyCode}
                            isLoading={loading}
                            backgroundColor="var(--blue-dark)"
                            color="white"
                            _hover={{
                              backgroundColor: 'var(--blue-dark-hover)',
                            }}
                          >
                            Verify
                          </Button>
                        </HStack>
                      )}
                      {verifyingField === 'email' && (
                        <Link
                          color="var(--blue-dark)"
                          onClick={() =>
                            handleContactVerification(
                              'email',
                              values.email ?? ''
                            )
                          }
                        >
                          Resend code
                        </Link>
                      )}
                    </GridItem>
                    <GridItem>
                      <FormInputs
                        htmlFor="phoneNumber"
                        label="Phone Number"
                        inputtype="tel"
                        id="phoneNumber"
                        {...getFieldProps('phoneNumber')}
                        isDisabled={!edit}
                        validate={(value) => {
                          let error

                          if (validator.isMobilePhone(value) === false) {
                            error = 'Must be a valid phone number'
                          }

                          return error
                        }}
                        errors={errors.phoneNumber}
                        rightElement={
                          edit &&
                          values.phoneNumber !== user.phoneNumber &&
                          verifyingField !== 'phoneNumber' ? (
                            <Button
                              onClick={() =>
                                handleContactVerification(
                                  'phoneNumber',
                                  values.phoneNumber ?? ''
                                )
                              }
                              isDisabled={!!errors.phoneNumber}
                              backgroundColor="var(--blue-dark)"
                              color="white"
                              _hover={{
                                backgroundColor: 'var(--blue-dark-hover)',
                              }}
                            >
                              Next
                            </Button>
                          ) : null
                        }
                      />
                      {verifyingField === 'phoneNumber' && (
                        <HStack mt={2} align="start">
                          <Input
                            placeholder="******"
                            value={verificationCode}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                          />
                          <Button
                            onClick={verifyCode}
                            isLoading={loading}
                            backgroundColor="var(--blue-dark)"
                            color="white"
                            _hover={{
                              backgroundColor: 'var(--blue-dark-hover)',
                            }}
                          >
                            Verify
                          </Button>
                        </HStack>
                      )}
                      {verifyingField === 'phoneNumber' && (
                        <Link
                          color="var(--blue-dark)"
                          fontSize="sm"
                          mt={1}
                          onClick={() =>
                            handleContactVerification(
                              'phoneNumber',
                              values.phoneNumber ?? ''
                            )
                          }
                          display="block"
                          _hover={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          Resend code
                        </Link>
                      )}
                    </GridItem>
                  </Grid>
                  <Grid
                    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    gap={6}
                    alignSelf="stretch"
                  >
                    {' '}
                    <Address formIsDisabled={!edit} />
                  </Grid>
                  {!edit ? (
                    <>
                      <PrimaryButton
                        leftIcon={<Icon as={AiFillEdit} />}
                        onClick={enterEditMode}
                        width="auto"
                        alignSelf={'flex-start'}
                      >
                        Edit
                      </PrimaryButton>
                    </>
                  ) : (
                    <ButtonGroup
                      variant="outline"
                      spacing="6"
                      alignSelf={'flex-start'}
                    >
                      <PrimaryButton type="submit">Save</PrimaryButton>
                      <Button
                        variant="link"
                        onClick={() => {
                          handleReset()
                          exitEditMode()
                        }}
                        color="var(--blue-dark)"
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  )}
                </VStack>
                <UnsavedChangesModal
                  isOpen={showUnsavedModal}
                  onClose={handleCancelNavigation}
                  onConfirm={handleConfirmNavigation}
                  onSave={() => handleSaveAndNavigate(values)}
                />
              </form>
            )}
          </Formik>
        </GridItem>
        <GridItem area="tools" p={8}>
          <Box>
            <VStack align="flex-start" spacing={5}>
              {selfServiceCancellation && (
                <Button
                  variant="link"
                  onClick={() => {
                    history.push(RoutePaths.MANAGE_MEMBERSHIP)
                  }}
                  color="var(--blue-dark)"
                  fontWeight={400}
                >
                  Manage account
                </Button>
              )}
              <Button
                variant="link"
                onClick={handleResetPassword}
                color="var(--blue-dark)"
                fontWeight={400}
              >
                Reset password
              </Button>
              <a href={stripePortalLink} target="_blank" rel="noreferrer">
                <Button
                  variant="link"
                  color="var(--blue-dark)"
                  fontWeight={400}
                >
                  My billing information
                </Button>
              </a>
            </VStack>
          </Box>
        </GridItem>
      </Grid>
      <ResetPasswordModal
        email={user.email}
        show={showModal}
        onHide={hideResetPasswordModal}
      />
      <VerificationModal
        isOpen={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        method={verificationMethod}
        onSuccess={handleVerificationSuccess}
      />
    </Box>
  )
}

export default memo(EditProfileForm)
