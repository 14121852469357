import { useCallback } from 'react'
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg'
import { memo } from 'react'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatDate } from 'utils/time.utils'
import useLabOrders, { LabOrder } from 'hooks/useLabOrders'
import { Amplitude } from 'utils/amplitude.utils'

interface LabOrderRowProps {
  labOrder: LabOrder
}

const LabOrderRow: React.FC<LabOrderRowProps> = ({ labOrder }) => {
  const { downloadLabOrder } = useLabOrders()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const date = new Date(labOrder.signedDate)
  const dateString = formatDate(date, 'mm/dd/yyyy')

  const titleText = `Lab ordered on ${dateString}`

  const handleDownload = useCallback(() => {
    setIsDownloading(true)
    downloadLabOrder(labOrder).then(() => {
      setIsDownloading(false)
      Amplitude.labResultDownloadDone({
        lab_title: titleText,
        lab_id: labOrder.id,
        lab_type: 'Lab Order',
      })
    })
  }, [downloadLabOrder, setIsDownloading])

  return (
    <div className="d-flex align-items-center justify-content-between mb-1">
      <p className="mb-0">{titleText}</p>
      <div className="d-flex">
        {isDownloading ? (
          <OverlayTrigger
            placement="right"
            show={true}
            overlay={<Tooltip id="downloading-tooltip">Downloading...</Tooltip>}
          >
            <DownloadIcon className="download-icon disabled" />
          </OverlayTrigger>
        ) : (
          <DownloadIcon className="download-icon" onClick={handleDownload} />
        )}

        {/* NOTE: these are needed to align with labs */}
        <div className="calendar-icon-spacer" />
        <div className="calendar-icon-spacer" />
      </div>
    </div>
  )
}

export default memo(LabOrderRow)
