export enum BookingEventSource {
  DASHBOARD_ACTION = 'dashboard_action',
  EMAIL = 'email',
  APP_NAVIGATION = 'app_navigation',
  INSURANCE_USER_ONBOARDING = 'onboarding',
  MY_HEALTH_APPOINTMENTS = 'my_health_appointments',
  INITIAL_MD_BOOK_DONE = 'intitial_md_book_done',
  SMV_BOOK_DONE = 'smv_book_done',
  UNKNOWN = 'unknown',
  RESCHEDULE = 'reschedule',
}

export type GenericAppointmentEventProps = {
  appointment_type_name: string // e.g. 'Initial Medical Visit'
  patient_action_id: string | null // null if we don't know/have the action that drove the patient's behavior
  patient_action_definition: string | null // same as above
  source: BookingEventSource
}

export type NetNewAppointmentEventProps = GenericAppointmentEventProps & {
  appointment_id: null
  appointment_time: null
  is_reschedule: false
}

export type ExistingAppointmentEventProps = GenericAppointmentEventProps & {
  appointment_id: string
  appointment_time: string
  is_reschedule: boolean // false if the user just booked the appointment
}

export type NewOrExistingAppointmentEventProps =
  | NetNewAppointmentEventProps
  | ExistingAppointmentEventProps

export type AppointmentBookDoneProps = NewOrExistingAppointmentEventProps & {
  days_since_nearest_slot?: number
}

export type AppointmentBookStartEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
>

export type AppointmentBookStateSelectedEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  state_selected: string // e.g. 'CA'
  state_on_file: string // e.g. 'CA'
  is_valid_selection: boolean
}

export type AppointmentBookAvailabilityClickEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  slot_time: string
  available_providers_cnt: number
  available_providers: string[]
}

const UserIdPrefix = 'usr' as const

export type ProviderId = `${typeof UserIdPrefix}_${string}`

export type AppointmentBookAvailabilityShowEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  nearest_slot_time: string
  slots_date: string
  available_slots_cnt: number
  current_month_available_slots_cnt: number
  current_month_available_slot_dates_cnt: number
  available_slot_times: string[]
  available_providers_cnt?: number
  available_providers?: ProviderId[]
  available_providers_and_slots?: string
  state: string
  carrier: string
  is_first_availability?: boolean
  days_to_nearest_slot?: number
}

export type AppointmentBookSlotSelectedEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  slot_time: string
  provider_id: string
  days_since_nearest_slot?: number
}

export type AppointmentModifyEventProps = Omit<
  ExistingAppointmentEventProps,
  'is_reschedule' | 'patient_action_id' | 'patient_action_definition' | 'source'
>

export type AppointmentJoinStartEventProps = Omit<
  ExistingAppointmentEventProps,
  'is_reschedule'
>

export type MessageThreadOpenEventProps = {
  thread_id: string
  is_unread: boolean
}

export type MessageSendDoneEventProps = {
  attachments_num: number
  message_id: string
  is_new_message: boolean
  selected_topic: string | null
}

export type MessageAttachStartEventProps = {
  thread_id: string
}

export type AppointmentNoteOpenEventProps = {
  appointment_note_id: string
  appointment_id: string
  appointment_type_name: string
  source: string
}

export type LabResultDownloadDoneEventProps = {
  lab_title: string
  lab_id: string
  lab_type: string
}

export type DashboardActionClickEventProps = {
  patient_action_id: string
  patient_action_definition: string
}
