import withAccount, { WithAccountProps } from 'hoc/withAccount'
import OnboardingContainer from '../OnboardingContainer'
import InsuranceInfoForm from './InsuranceForm'
import { useCallback, useEffect, useMemo } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import { useHistory } from 'react-router-dom'

import './styles.scss'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import { PROGRAMS } from 'utils/program.utils'
import useAppointments from 'hooks/useAppointments/useAppointments'
import useInsurance from 'hooks/useInsuranceCard'
import { Amplitude } from 'utils/amplitude.utils'

const InsuranceInfo: React.FC<WithAccountProps> = ({ user }) => {
  const history = useHistory()
  const { selectedProgram } = useOnboardingContext()
  const { nonCancelledAppointments } = useAppointments()
  const { insurance, isLoading: isLoadingInsurance } = useInsurance()
  const {
    appointmentTypeId: fullProgramAppointmentTypeId,
  } = useAppointmentTypeId('Initial MD')
  const { appointmentTypeId: careTranferTypeId } = useAppointmentTypeId(
    'Care Transfer MD'
  )
  const { variantLabel } = useOnboardingContext()
  const startTime = useMemo(() => Date.now(), [])

  const handleOnSubmit = useCallback(() => {
    if (!isLoadingInsurance && insurance?.success === true) {
      Amplitude.insuranceDetails(
        Math.round((Date.now() - startTime) / 1000),
        variantLabel
      )
      const userProductType = user?.waitlistUser?.selectedProduct?.type

      if (
        selectedProgram === PROGRAMS.INSURANCE ||
        selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC ||
        userProductType === PROGRAMS.INSURANCE ||
        userProductType === PROGRAMS.INSURANCE_DIAGNOSTIC
      ) {
        const filteredAppointments = nonCancelledAppointments.length
        const isReactivatedUser =
          user.accountStatus === 'ACTIVE' &&
          user.cancellationReason !== null &&
          filteredAppointments > 0

        const appointmentTypeId = (() => {
          if (isReactivatedUser) {
            return careTranferTypeId
          } else if (
            selectedProgram === PROGRAMS.INSURANCE ||
            userProductType === PROGRAMS.INSURANCE
          ) {
            return fullProgramAppointmentTypeId
          }

          return null
        })()

        if (appointmentTypeId) {
          return history.push(
            `${RoutePaths.SCHEDULING_REDIRECT}?type=${appointmentTypeId}`
          )
        }
      }

      return history.push(`${RoutePaths.DASHBOARD}${window.location.search}`)
    }
  }, [
    insurance,
    isLoadingInsurance,
    selectedProgram,
    fullProgramAppointmentTypeId,
    careTranferTypeId,
    user?.accountStatus,
    user?.cancellationReason,
    user?.stripeCustomer?.product?.type,
    nonCancelledAppointments.length,
    history,
    variantLabel,
  ])

  // This effect will handle the navigation once insurance is loaded
  useEffect(() => {
    if (!isLoadingInsurance && insurance?.success === true) {
      handleOnSubmit()
    }
  }, [insurance, isLoadingInsurance, handleOnSubmit])

  return (
    <OnboardingContainer>
      <div className="d-none conversion-email">{user?.email}</div>
      <InsuranceInfoForm
        title="Verifying your benefits"
        subTitle="Please share your insurance information so we can confirm your coverage"
      />
    </OnboardingContainer>
  )
}

export default withAccount(InsuranceInfo)
