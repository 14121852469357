import { memo, useState, useMemo, useEffect } from 'react'
import {
  ChakraProvider,
  Text,
  Stack,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import useSourceSlot from 'hooks/useAppointments/useSourceSlot'
import { Slot, User } from '@allara-health/source-health-client'
import { endOfMonth, startOfMonth } from 'date-fns'
import { format } from 'date-fns'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import Calendar, {
  Chip,
  ControlHeader,
} from '../../components/Scheduling/Calendar'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import LoadingState from 'components/Spinner'
import { RoutePaths } from 'containers/Core/Routes'
import ProviderDetails from 'components/Scheduling/ProviderDetails'
import useSourcePrecheck from 'hooks/useSourcePrecheck'
import { useHistory } from 'react-router-dom'
import { ChakraPrimaryButton } from 'components/Button'
import {
  AppointmentBookAvailabilityShowEventProps,
  AppointmentBookSlotSelectedEventProps,
  ProviderId,
} from 'utils/amplitude.types'
import { Amplitude } from 'utils/amplitude.utils'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { useIsInsurancePatient } from 'utils/program.utils'

/**
 * Enhanced provider type that includes availability information
 */
export type EnhancedProvider = User & {
  availableSlots: Slot[]
}

/**
 * Maps slots to a structure where keys are dates (in YYYY-MM-DD format) and values
 * are arrays of all providers
 *
 * @param slots - Array of slot objects from the API
 * @param timeZone - User's timezone for proper date conversion
 * @returns A map with dates as keys and arrays of all providers as values
 */
export function mapProvidersToDatesByAvailability(
  slots: Slot[] | undefined,
  timeZone: string | null
): Record<string, Array<EnhancedProvider>> {
  if (!slots || !slots.length) {
    return {}
  }

  const dateProviderMap: Record<
    string,
    Record<
      string,
      {
        provider: User
        slots: Slot[]
      }
    >
  > = {}

  // Helper function to extract date string in YYYY-MM-DD format
  const getDateKey = (isoTimestamp: string): string => {
    if (!timeZone) {
      return isoTimestamp.split('T')[0]
    }

    const date = utcToZonedTime(isoTimestamp, timeZone)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}`
  }

  // builds an object that maps dates to providers and their slots
  slots.forEach((slot) => {
    const dateKey = getDateKey(slot.start_at)

    if (!dateProviderMap[dateKey]) {
      dateProviderMap[dateKey] = {}
    }

    slot.available.forEach((expandableUser) => {
      const provider = expandableUser as User

      if (!provider.id) {
        return
      }

      if (!dateProviderMap[dateKey][provider.id]) {
        dateProviderMap[dateKey][provider.id] = {
          provider,
          slots: [],
        }
      }

      dateProviderMap[dateKey][provider.id].slots.push(slot)
    })
  })

  const result: Record<string, Array<EnhancedProvider>> = {}

  Object.keys(dateProviderMap).forEach((dateKey) => {
    const providers = Object.values(dateProviderMap[dateKey])
    result[dateKey] = providers.map((item) => ({
      ...item.provider,
      availableSlots: item.slots,
    }))
  })

  return result
}

const BookScreen: React.FC<
  WithAccountProps & {
    setDaysSinceNearestSlot: (days: number) => void
    firstAvailabilityForBookingSession: string | null
    setFirstAvailabilityForBookingSession: (date: string) => void
  }
> = ({
  user,
  setDaysSinceNearestSlot,
  firstAvailabilityForBookingSession,
  setFirstAvailabilityForBookingSession,
}) => {
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [
    hasSentShowAvailabilityEvent,
    setHasSentShowAvailabilityEvent,
  ] = useState(false)
  const [providersByDate, setProvidersByDate] = useState<
    Record<string, Array<EnhancedProvider>>
  >({})
  const [isLoadingProviders, setIsLoadingProviders] = useState(true)
  const [expandedProviders, setExpandedProviders] = useState<
    Record<string, Record<string, boolean>>
  >({})

  const [viewState, setViewState] = useState<
    | 'loading'
    | 'preCheckResultIsFalse'
    | 'noSlots'
    | 'slotsAvailable'
    | 'pastPlanningHorizon'
  >('loading')

  const { isOpen, onClose, onOpen } = useDisclosure()
  const isInsurancePatient = useIsInsurancePatient(user)

  const history = useHistory()
  /**
   * Fetches the precheck result to determine if the user can book an appointment
   */
  const {
    result: precheckResult,
    isLoading: isPrecheckLoading,
  } = useSourcePrecheck()

  const { slots, isLoading } = useSourceSlot(
    startOfMonth(calendarDate).toISOString(),
    endOfMonth(calendarDate).toISOString()
  )

  const {
    amplitudeBookingMetadata,
    appointment,
    appointmentType,
    isReschedule,
  } = useSchedulingContext()

  const latestEnd = new Date()
  latestEnd.setDate(
    latestEnd.getDate() + (appointmentType?.planning_horizon ?? 0)
  )

  /**
   * Handles the click event for the dashboard button in the modal.
   */
  const onClickDashboardButton = () => {
    history.push(RoutePaths.DASHBOARD + `?from=scheduling`)
  }

  /**
   * Handles the click event for the contact concierge button in the modal.
   */
  const onClickContactConciergeButton = () => {
    if (isInsurancePatient && !user?.stripeCustomer?.product) {
      window.location.href = `mailto:concierge@allarahealth.com?subject=${encodeURIComponent(
        'Potential patient not seeing availability'
      )}`
      return
    } else {
      history.push(RoutePaths.DASHBOARD_NEW_THREAD + '?sbj=8')
    }
  }

  // Function to handle the pre-check failure logic with delay
  const handlePreCheckFailure = () => {
    const timeOutId = setTimeout(() => {
      setViewState('preCheckResultIsFalse')
      onOpen() // Open the modal after setting the state
    }, 3000) // 3-second delay
    return () => clearTimeout(timeOutId)
  }

  // Update the view state based on the loading state and the availability of slots
  useEffect(() => {
    if (precheckResult?.result === false) {
      handlePreCheckFailure()
    } else if (isPrecheckLoading || isLoading) {
      setViewState('loading')
    } else if (noSlotsAvailableOnDay()) {
      setViewState('noSlots')
    } else if (latestEnd < calendarDate) {
      setViewState('pastPlanningHorizon')
    } else {
      setViewState('slotsAvailable')
    }
  }, [calendarDate, slots, isLoading, precheckResult, isPrecheckLoading])

  const {
    currentScreen,
    timeZone,
    selectedSlot: contextSelectedSlot,
    provider,
    setSelectedSlot: setContextSelectedSlot,
    setProvider,
    setDateTime,
    nextScreen,
  } = useSchedulingContext()

  const chipState = (
    state:
      | 'active'
      | 'selected'
      | 'disabled'
      | 'disabled-with-border'
      | 'active-no-border'
  ) => {
    switch (state) {
      case 'active':
        return 'chip chip-with-border'
      case 'selected':
        return 'chip chip-picked text-white'
      case 'disabled':
        return 'chip chip-disabled'
      case 'active-no-border':
        return 'chip chip-no-border'
      case 'disabled-with-border':
        return 'chip chip-with-border chip-disabled'
      default:
        break
    }
  }

  const slotsForSelectedDate = useMemo(
    () =>
      slots?.filter((slot) => {
        const date = utcToZonedTime(slot.start_at, timeZone ?? '')
        return date.toDateString() === calendarDate.toDateString()
      }) || [],
    [slots, calendarDate, timeZone]
  )

  const daysBetweenDates = (earlierDate: Date, laterDateAsIso: string) => {
    // Create Date objects for the slot time and current time in UTC
    const slotTime = new Date(laterDateAsIso)
    const earlierTime = earlierDate

    // Set both times to midnight UTC to calculate whole days
    const slotDate = new Date(
      Date.UTC(
        slotTime.getUTCFullYear(),
        slotTime.getUTCMonth(),
        slotTime.getUTCDate()
      )
    )

    const earlier = new Date(
      Date.UTC(
        earlierTime.getUTCFullYear(),
        earlierTime.getUTCMonth(),
        earlierTime.getUTCDate()
      )
    )

    // Calculate the difference in milliseconds
    const differenceMs = slotDate.getTime() - earlier.getTime()

    // Convert to days, rounding down
    const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24))

    // Return 0 if the dates are the same (today)
    return Math.max(0, days)
  }

  // Get providers for the current date
  const providersForCurrentDate = useMemo(() => {
    if (!calendarDate) {
      return null
    }

    const dateKey = timeZone
      ? format(utcToZonedTime(calendarDate, timeZone), 'yyyy-MM-dd')
      : format(calendarDate, 'yyyy-MM-dd')

    return providersByDate[dateKey] ?? null
  }, [calendarDate, providersByDate, timeZone])

  useEffect(() => {
    /*
    we have to be somewhat careful here because we make an amplitude call every time this runs.
    to limit the risk of accidental spamming, we only make the call if the slotsForSelectedDate are not loading and there are slots available, and manage the state of the call with hasSentShowAvailabilityEvent.
    */

    if (
      isLoadingProviders ||
      hasSentShowAvailabilityEvent ||
      providersForCurrentDate === null
    ) {
      return
    }

    const nearestSlotForSelectedDate =
      [...slotsForSelectedDate].sort(
        (a, b) =>
          new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
      )[0] ?? undefined

    let isFirstAvailability = false

    if (
      firstAvailabilityForBookingSession === null &&
      nearestSlotForSelectedDate
    ) {
      setFirstAvailabilityForBookingSession(
        nearestSlotForSelectedDate?.start_at
      )
      isFirstAvailability = true
    }

    const availableProvidersAndSlots = providersForCurrentDate?.reduce(
      (acc, provider) => ({
        ...acc,
        [provider.id as ProviderId]: provider.availableSlots.map(
          (slot) => slot.start_at
        ),
      }),
      {} as Record<ProviderId, string[]>
    )

    setHasSentShowAvailabilityEvent(true)
    const amplitudeEventProps: AppointmentBookAvailabilityShowEventProps = {
      appointment_id: appointment?.id ?? '',
      appointment_type_name: appointmentType?.name ?? '',
      is_reschedule: isReschedule ?? false,
      slots_date: nearestSlotForSelectedDate?.start_at?.split('T')[0] ?? '',
      nearest_slot_time: nearestSlotForSelectedDate?.start_at ?? '',
      available_slots_cnt: slotsForSelectedDate?.length ?? 0,
      available_slot_times:
        slotsForSelectedDate?.map((slot) => slot.start_at) ?? [],
      available_providers_cnt: providersForCurrentDate?.length ?? 0,
      available_providers:
        providersForCurrentDate?.map((provider) => provider.id as ProviderId) ??
        [],
      available_providers_and_slots: JSON.stringify(availableProvidersAndSlots),
      current_month_available_slots_cnt: slots?.length ?? 0,
      current_month_available_slot_dates_cnt: datesFromSlots.length,
      state: user.waitlistUser.state.code,
      carrier: user.waitlistUser.insuranceProvider ?? '',
      is_first_availability: isFirstAvailability,
      days_to_nearest_slot: daysBetweenDates(
        new Date(),
        nearestSlotForSelectedDate?.start_at ?? ''
      ),
      ...amplitudeBookingMetadata,
    }
    Amplitude.appointmentBookAvailabilityShow(amplitudeEventProps)
  }, [
    calendarDate,
    slotsForSelectedDate,
    providersForCurrentDate,
    isLoadingProviders,
  ])

  const noSlotsAvailableOnDay = (): boolean => {
    if (calendarDate < latestEnd) {
      const todaySlotsLength = slotsForSelectedDate.length
      return todaySlotsLength === 0
    }

    return false
  }

  const changeCalendar = (month = 0, day = 0) => {
    setCalendarDate(
      new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth() + month,
        calendarDate.getDate() + day
      )
    )
    setHasSentShowAvailabilityEvent(false)
    setIsLoadingProviders(true)
  }

  const pickDate = (date: Date) => {
    setCalendarDate(date)
    setHasSentShowAvailabilityEvent(false)

    // only set isLoadingProviders to true if the date has changed to a different month
    if (date.getMonth() !== calendarDate.getMonth()) {
      setIsLoadingProviders(true)
    }
  }

  const pickSlot = (slot: Slot, provider: EnhancedProvider) => {
    setProvider(provider)
    setDateTime(new Date(slot.start_at))
    setContextSelectedSlot(slot)
    const daysSinceNearestSlot = daysBetweenDates(
      new Date(firstAvailabilityForBookingSession ?? ''),
      slot.start_at
    )
    setDaysSinceNearestSlot(daysSinceNearestSlot)
    const amplitudeEventProps: AppointmentBookSlotSelectedEventProps = {
      appointment_id: appointment?.id ?? '',
      appointment_type_name: appointmentType?.name ?? '',
      is_reschedule: isReschedule ?? false,
      slot_time: slot.start_at,
      provider_id: provider.id,
      days_since_nearest_slot: daysSinceNearestSlot,
      ...amplitudeBookingMetadata,
    }
    Amplitude.appointmentBookSlotSelected(amplitudeEventProps)

    // Navigate to the next screen
    nextScreen()
  }

  const datesFromSlots: string[] = useMemo(() => {
    // Gets unique dates from slots
    const dates = Array.from(
      new Set(
        slots?.map((slot) =>
          utcToZonedTime(slot.start_at, timeZone ?? '').toDateString()
        )
      )
    )

    if (dates.length > 0) {
      // Sets calendar date to the first available date with slots
      const firstAvailableDate = dates.find(
        (val) => new Date(val).getMonth() === calendarDate.getMonth()
      )
      setCalendarDate(new Date(firstAvailableDate ?? ''))
    }

    return dates
  }, [slots])

  useEffect(() => {
    // Automatically set provider and slot if any exist in the context
    setContextSelectedSlot(contextSelectedSlot)
    setProvider(provider)
    setCalendarDate(
      utcToZonedTime(
        contextSelectedSlot?.start_at ?? new Date(),
        timeZone ?? ''
      )
    )
  }, [currentScreen])

  // Generate the providers map when slots load
  useEffect(() => {
    if (!isLoading && slots && slots.length > 0) {
      const providersMap = mapProvidersToDatesByAvailability(
        slots,
        timeZone ?? ''
      )
      setProvidersByDate(providersMap)
      setIsLoadingProviders(false)
    }
  }, [slots, isLoading, timeZone])

  const getDateKey = (date: Date) => {
    return timeZone
      ? format(utcToZonedTime(date, timeZone), 'yyyy-MM-dd')
      : format(date, 'yyyy-MM-dd')
  }

  // Function to render provider's time slots in rows of 3
  const renderProviderTimeSlots = (provider: EnhancedProvider) => {
    const handleExpandClick = () => {
      // Force setting to true instead of toggling
      setExpandedProviders((prev) => {
        const newState = { ...prev }

        if (!newState[dateKey]) {
          newState[dateKey] = {}
        }

        newState[dateKey][provider.id] = true

        return newState
      })
    }

    // Get current date key
    const dateKey = getDateKey(calendarDate)

    // Determine if we should auto-expand based on total number of providers
    const shouldAutoExpand = (providersForCurrentDate?.length ?? 0) <= 2

    // Check if this provider is expanded for the current date
    const isExpanded =
      shouldAutoExpand || expandedProviders[dateKey]?.[provider.id] || false

    // Sort slots by time
    const sortedSlots = [...provider.availableSlots].sort(
      (a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
    )

    // Determine how many rows to display based on expanded state
    const totalRows = Math.ceil(sortedSlots.length / 3)
    const visibleRows = isExpanded ? totalRows : Math.min(2, totalRows)
    const hasMoreSlots = sortedSlots.length > 6

    return (
      <div className="provider-slots-container mt-2">
        {/* Render the slots in rows of 3 */}
        {Array.from({ length: visibleRows }).map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className="d-flex mb-2">
            {/* Always create 3 columns per row */}
            {Array.from({ length: 3 }).map((_, colIndex) => {
              const slotIndex = rowIndex * 3 + colIndex
              const slot =
                slotIndex < sortedSlots.length
                  ? sortedSlots[slotIndex]
                  : undefined

              return (
                <div
                  key={`slot-${rowIndex}-${colIndex}`}
                  className="flex-1 mx-1"
                  style={{ flex: '1 0 30%' }} // This ensures equal width
                >
                  {slot && (
                    <Chip
                      onClick={() => pickSlot(slot, provider)}
                      key={slot.start_at}
                      text={formatInTimeZone(
                        new Date(slot.start_at),
                        timeZone ?? '',
                        'h:mm aaa'
                      )}
                      state={`${chipState(
                        'active'
                      )} chip-slot w-100 time-slot-chip`}
                    />
                  )}
                </div>
              )
            })}
          </div>
        ))}

        {hasMoreSlots && !isExpanded && !shouldAutoExpand && (
          <div className="d-flex justify-content-center mt-3">
            <div
              className="cursor-pointer ff-inter-medium link see-more-availability"
              onClick={handleExpandClick}
            >
              See more availability
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <ChakraProvider>
      <div className="screen-container">
        <div className="appointment d-flex flex-column">
          <h1 className="ff-inter-medium fs-8">Appointment</h1>
          <p>Date & Time</p>
          <div className="d-flex align-items-start appointment-sub">
            <div>
              <ControlHeader
                previous={() => {
                  changeCalendar(-1)
                }}
                next={() => {
                  changeCalendar(1)
                }}
                title={`${format(calendarDate, 'EEEE')}, ${format(
                  calendarDate,
                  'MMMM'
                )} ${calendarDate.getDate()}`}
              />
              <Calendar
                datesFromSlot={datesFromSlots}
                date={calendarDate}
                setDate={pickDate}
                slots={datesFromSlots}
              />
            </div>
            <div className="appointment-container-scheduling ml-md-5 ">
              {(() => {
                switch (viewState) {
                  case 'loading':
                    return <LoadingState />
                  case 'preCheckResultIsFalse':
                    return (
                      <>
                        <Modal isOpen={isOpen} onClose={onClose} isCentered>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>
                              Why don&apos;t I see any available appointments?
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              <Text>
                                To ensure that you don&apos;t incur out of
                                network charges, our insurance team needs to
                                confirm a few details of your plan and match you
                                with participating providers. This may take up
                                to one business day.
                              </Text>
                              <Text>
                                In the meantime, if you have any questions
                                please reach out to our Patient Concierge Team.
                              </Text>
                            </ModalBody>

                            <ModalFooter justifyContent="center" gap="1rem">
                              <ChakraPrimaryButton
                                onClick={onClickContactConciergeButton}
                              >
                                Contact Concierge
                              </ChakraPrimaryButton>
                              {!isInsurancePatient && (
                                <ChakraPrimaryButton
                                  onClick={onClickDashboardButton}
                                >
                                  Go to Dashboard
                                </ChakraPrimaryButton>
                              )}
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    )
                  case 'noSlots':
                    return (
                      <Stack justifyContent="center" mt={2} display="flex">
                        <Text fontSize="sm">No appointments available.</Text>
                        <Text fontSize="sm">
                          Please select another date to view available
                          appointments.
                        </Text>
                      </Stack>
                    )

                  case 'slotsAvailable':
                    return (
                      <>
                        <div className="providers-list-container">
                          {providersForCurrentDate?.length ?? 0 > 0 ? (
                            <div className="d-flex flex-column">
                              {providersForCurrentDate?.map((provider) => (
                                <div
                                  key={provider.id}
                                  className="provider-container mb-4"
                                >
                                  <ProviderDetails
                                    provider={provider}
                                    className="provider-details-book-screen"
                                  />
                                  {renderProviderTimeSlots(provider)}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="text-center py-4">
                              <Text fontSize="sm">
                                No providers available for this date.
                              </Text>
                              <Text fontSize="sm">
                                Please select another date to view available
                                appointments.
                              </Text>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  case 'pastPlanningHorizon':
                    return (
                      <Stack justifyContent="center" display="flex" mt={2}>
                        <Text fontSize="sm">
                          You have reached the end of the booking window.
                        </Text>
                        <Text fontSize="sm">
                          If you were not able to find an appointment that works
                          for you, please reach out to{' '}
                          <Link
                            href={RoutePaths.DASHBOARD_NEW_THREAD + '?sbj=8'}
                            color={'var(--blue-light)'}
                          >
                            Patient Concierge
                          </Link>{' '}
                          for scheduling assistance.
                        </Text>
                      </Stack>
                    )
                  default:
                    return <LoadingState />
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </ChakraProvider>
  )
}

export default memo(withAccount(BookScreen))
