import {
  Actions as ActionsRoute,
  CompleteAction,
  ActionQueryKey,
} from 'api/timeline/routes'
import { useMemo } from 'react'
import {
  useMutation,
  UseMutateFunction,
  useQuery,
  useQueryClient,
  UseMutateAsyncFunction,
} from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { Automation as AutomationRoute } from 'api/automations/routes'
import { Content } from 'hooks/useLibrary'
import produce from 'immer'
import { useStytch, useStytchSession } from '@stytch/react'

export type Action = {
  id: string
  status: 'active' | 'inactive' | 'completed' | 'complete'
  definition: string
  complete?: Date
  properties?: {
    jwt?: string
    typeId?: string
    date?: string
    videoCallLink?: string
    appointmentId?: string
    calendarId?: string
    promoCodes?: {
      type: '3-months' | '6-months' | 'insurance'
      code: string
    }[]
    formUrl?: string
  }
  completeCondition?: {
    code: string
    webhookSource: string
    webhookEvent: string
  }
}

export type ActionDefinition = {
  id: string
  type: 'info' | 'form' | 'book-appt' | 'join-now' | 'content' | 'formsort'
  title: string
  description: string
  completedDescription: string
  icon: string // URL
  buttonText: string
  buttonLink?: string
  content?: Content[]
}

export const isCompleteFormsortIntakeFormAction = (
  action: Action,
  actionDefinition: ActionDefinition | undefined,
  actionState: Action['status']
): action is Action & { properties: { formUrl: string } } => {
  return (
    actionDefinition?.id === 'complete-intake' &&
    action.status === actionState &&
    !!action.properties?.formUrl
  )
}

interface UseActionsInterface {
  isLoading: boolean
  error: unknown
  activeActions: Action[]
  completedActions: Action[]
  actionDefinitions: ActionDefinition[]
  handleCompleteAction: UseMutateAsyncFunction<
    AxiosResponse<string> | null,
    unknown,
    string,
    unknown
  >
  handleRespondHowLongLabs: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    'more' | 'less',
    unknown
  >
  handleRespondAtHomeTesting: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    'home' | 'labcorp' | 'quest' | 'unsure',
    unknown
  >
  completeLabsActions: (path: string) => Promise<string | null>
  refetchActions: () => void
}

interface ActionMetadata {
  active: Action[]
  completed: Action[]
  actionDefinitions: ActionDefinition[]
}

export type InsuranceInfo = {
  rank: 'primary' | 'secondary' | 'tertiary'
  carrier: string
  member_id: string
  group_id?: string
  plan: string
}

export type AddressInfo = {
  address_line1: string
  address_line2: string
  city: string
  state: string
  zip: string
}

const useActions = (): UseActionsInterface => {
  const queryClient = useQueryClient()
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchActions = async (): Promise<ActionMetadata> => {
    const defaultMetdata = { active: [], completed: [], actionDefinitions: [] }

    if (!session) {
      return defaultMetdata
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${ActionsRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          if (!data.active.length && !data.completed.length) {
            throw new Error('Timeline has not been initialized yet')
          }

          return data
        } else {
          return defaultMetdata
        }
      })
      .catch((err) => {
        throw err
      })
  }

  const { isLoading, error, data } = useQuery(ActionQueryKey, fetchActions, {
    retry: 8,
    retryDelay: (attempt) => 500 * 1.5 ** attempt,
  })

  const actionDefinitions = useMemo(() => data?.actionDefinitions ?? [], [data])

  const activeActions = useMemo(
    () => data?.active.filter((a) => !a.definition.endsWith('-mobile')) ?? [],
    [data]
  )

  const completedActions = useMemo(
    () =>
      data?.completed.filter((a) => !a.definition.endsWith('-mobile')) ?? [],
    [data]
  )

  const completeAction = async (id: string) => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}${CompleteAction(id)}`, null, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .catch(() => null)
  }

  const mutateComplete = useMutation(completeAction, {
    onSettled: () => queryClient.invalidateQueries(ActionQueryKey),
    onMutate: async (id: string) => {
      await queryClient.cancelQueries(ActionQueryKey)

      const previousData = queryClient.getQueryData<ActionMetadata>(
        ActionQueryKey
      )

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          const action = draftState.active.find((a) => a.id === id)
          draftState.active = draftState.active.filter((a) => a.id !== id)

          if (action) {
            draftState.completed.unshift({
              ...action,
              complete: new Date(),
              status: 'completed',
            })
          }
        })
        queryClient.setQueryData<ActionMetadata>(ActionQueryKey, nextState)
      }

      return { previousData }
    },
  })

  const handleCompleteAction = mutateComplete.mutateAsync

  const respondHowLongLabs = async (response: 'more' | 'less') => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/how-long-labs/${response}`,
        null,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .catch(() => null)
  }

  const mutateRespondHowLongLabs = useMutation(respondHowLongLabs, {
    onSettled: () => queryClient.invalidateQueries(ActionQueryKey),
    onMutate: async () => {
      await queryClient.cancelQueries(ActionQueryKey)

      const previousData = queryClient.getQueryData<ActionMetadata>(
        ActionQueryKey
      )

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          const action = draftState.active.find(
            (a) => a.definition === 'how-long-labs'
          )

          if (action) {
            draftState.active = draftState.active.filter(
              (a) => a.id !== action.id
            )
            draftState.completed.unshift({
              ...action,
              complete: new Date(),
              status: 'completed',
            })
          }
        })
        queryClient.setQueryData<ActionMetadata>(ActionQueryKey, nextState)
      }

      return { previousData }
    },
  })

  const handleRespondHowLongLabs = mutateRespondHowLongLabs.mutate

  const respondAtHomeTesting = async (
    response: 'home' | 'labcorp' | 'quest' | 'unsure'
  ) => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/at-home-testing/${response}`,
        null,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .catch(() => null)
  }

  const mutateRespondAtHomeTesting = useMutation(respondAtHomeTesting, {
    onSettled: () => queryClient.invalidateQueries(ActionQueryKey),
    onMutate: async () => {
      await queryClient.cancelQueries(ActionQueryKey)

      const previousData = queryClient.getQueryData<ActionMetadata>(
        ActionQueryKey
      )

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          const action = draftState.active.find(
            (a) => a.definition === 'at-home-testing'
          )

          if (action) {
            draftState.active = draftState.active.filter(
              (a) => a.id !== action.id
            )
            draftState.completed.unshift({
              ...action,
              complete: new Date(),
              status: 'completed',
            })
          }
        })
        queryClient.setQueryData<ActionMetadata>(ActionQueryKey, nextState)
      }

      return { previousData }
    },
  })

  const handleRespondAtHomeTesting = mutateRespondAtHomeTesting.mutate

  const completeLabsActions = async (path: string): Promise<string | null> => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/${path}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      await queryClient.invalidateQueries(ActionQueryKey)
      return response.text()
    } catch (error) {
      console.error('Error scheduling labs:', error)
      return null
    }
  }

  const refetchActions = () => {
    queryClient.invalidateQueries(ActionQueryKey)
  }

  return {
    isLoading,
    error,
    activeActions,
    completedActions,
    actionDefinitions,
    handleCompleteAction,
    handleRespondHowLongLabs,
    handleRespondAtHomeTesting,
    completeLabsActions,
    refetchActions,
  }
}

export default useActions
