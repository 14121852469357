import {
  Heading,
  Text,
  Box,
  Button,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Textarea,
  UnorderedList,
  ListItem,
  Link,
  Grid,
  GridItem,
  useBreakpointValue,
  Icon,
  Flex,
  FormControl,
} from '@chakra-ui/react'
import { ChakraPrimaryButton } from 'components/Button'
import { RoutePaths } from 'containers/Core/Routes'
import useStripePortal, { CouponData } from 'hooks/useStripePortal'
import {
  formatDateToString,
  formatNumberToCurrency,
} from 'containers/Core/utils'
import LoadingState from 'components/Spinner'
import { FaLightbulb } from 'react-icons/fa'
import { useState } from 'react'
import { useFormik } from 'formik'
import useUser from 'hooks/useUser/useUser'
import { PROGRAMS, useIsInsurancePatient } from 'utils/program.utils'

interface AreYouSureYoudLikeToCancelProps {
  setFlow: (flow: string) => void
  setChosenCoupon: (coupon: CouponData) => void
  setUpdateToAnnual?: (updateToAnnual: boolean) => void
  setCancellationReason: (reason: string) => void
}

const AreYouSureYoudLikeToCancel = ({
  setFlow,
  setChosenCoupon,
  setUpdateToAnnual,
  setCancellationReason,
}: AreYouSureYoudLikeToCancelProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { discountData, membershipInfo, isLoading } = useStripePortal()
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [shortFormText, setShortFormText] = useState<string>('')
  const { user } = useUser()
  const isInsuranceUser = useIsInsurancePatient(user)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value)
  }

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setShortFormText(event.target.value)
  }

  const updateToAnnual: boolean =
    membershipInfo?.subscription.priceId ===
    process.env.REACT_APP_STRIPE_MONTHLY_INSURANCE_PRICE_ID

  const templateColumns = useBreakpointValue({
    base: '1fr',
    md: '8fr 2fr',
  })

  const boxWidth = useBreakpointValue({ base: '100%', md: '50%' })
  const buttonPadding = useBreakpointValue({ base: 2, md: 0 })
  const buttonWidth = useBreakpointValue({ base: '100%', md: 'auto' })

  const displayText = updateToAnnual
    ? 'updateToAnnual'
    : discountData && discountData?.length > 0
    ? 'discountData'
    : 'noDiscountData'

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      const cancellationReason = shortFormText
        ? `${selectedOption}: ${shortFormText}`
        : selectedOption
      setCancellationReason(`${cancellationReason}`)
      setFlow('NoDiscountConfirmation')
      onClose()
    },
  })

  const PatientConciergeLanguage = () => {
    return (
      <Text mb={0} ml={2}>
        We’d love for you to stick around, so let us know if there’s anything
        else we can help with. Please, contact{' '}
        <Link
          href={RoutePaths.DASHBOARD_NEW_THREAD + '?sbj=4'}
          color={'var(--blue-light)'}
        >
          Patient Concierge
        </Link>
        .
      </Text>
    )
  }

  return (
    <Box m={10}>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <Heading as="h4" size="m" fontWeight={'semibold'}>
            Are you sure you&apos;d like to{' '}
            {isInsuranceUser ? 'deactivate' : 'cancel'}?
          </Heading>
          {(() => {
            switch (displayText) {
              case 'updateToAnnual':
                return (
                  <>
                    <Text>Choose the best option for you</Text>
                    <Box
                      borderRadius="md"
                      backgroundColor="var(--gray-light)"
                      width={boxWidth}
                      p={4}
                    >
                      <Grid gridTemplateColumns={templateColumns}>
                        <GridItem>
                          <Box>
                            <Text
                              fontSize={'md'}
                              fontWeight={'semibold'}
                              mb={0}
                            >
                              Save 50% by switching annual subscription
                            </Text>
                            <Text mb={0} fontSize={'sm'}>
                              Will resume to $180 per year on{' '}
                              {formatDateToString(
                                new Date(
                                  new Date().getFullYear() + 1,
                                  new Date().getMonth(),
                                  new Date().getDate()
                                )
                              )}{' '}
                            </Text>
                          </Box>
                        </GridItem>
                        <GridItem
                          gridColumnStart={'0 8'}
                          alignSelf={'center'}
                          pt={buttonPadding}
                        >
                          <ChakraPrimaryButton
                            onClick={() => {
                              setFlow('DiscountConfirmation')

                              if (discountData) {
                                setChosenCoupon(discountData[0])
                              }

                              if (setUpdateToAnnual) {
                                setUpdateToAnnual(true)
                              }
                            }}
                            width={buttonWidth}
                          >
                            Continue
                          </ChakraPrimaryButton>
                        </GridItem>
                      </Grid>
                    </Box>
                  </>
                )
              case 'discountData':
                return (
                  <>
                    <Text>Choose the best option for you</Text>
                    {discountData?.map((discount) => {
                      return (
                        <Box
                          borderRadius="md"
                          backgroundColor="var(--gray-light)"
                          width={boxWidth}
                          p={4}
                          key={discount.id}
                        >
                          <Grid gridTemplateColumns={templateColumns}>
                            <GridItem>
                              <Box>
                                <Text
                                  fontSize={'lg'}
                                  fontWeight={'semibold'}
                                  mb={0}
                                >
                                  {discount?.name}
                                </Text>
                                <Text mb={0} fontSize={'sm'}>
                                  Will resume to{' '}
                                  {formatNumberToCurrency(
                                    membershipInfo?.subscription?.amount
                                  )}{' '}
                                  per{' '}
                                  {membershipInfo?.subscription?.interval ===
                                  'month'
                                    ? ' month'
                                    : ' year'}{' '}
                                  on{' '}
                                  {formatDateToString(discount.discountEndDate)}
                                </Text>
                              </Box>
                            </GridItem>
                            <GridItem
                              gridColumnStart={'0 8'}
                              alignSelf={'center'}
                              pt={buttonPadding}
                            >
                              <ChakraPrimaryButton
                                onClick={() => {
                                  setFlow('DiscountConfirmation')
                                  setChosenCoupon(discount)
                                }}
                                width={buttonWidth}
                              >
                                Continue
                              </ChakraPrimaryButton>
                            </GridItem>
                          </Grid>
                        </Box>
                      )
                    })}
                  </>
                )
              case 'noDiscountData':
                return (
                  <Box
                    backgroundColor={'var(--gray-extra-light)'}
                    width={boxWidth}
                    borderRadius="lg"
                  >
                    <Flex alignItems="center" p={4}>
                      <Icon as={FaLightbulb} boxSize={6} />
                      <PatientConciergeLanguage />
                    </Flex>
                  </Box>
                )
            }
          })()}
          <Button
            color={'var(--gray-dark)'}
            variant="link"
            fontWeight={'normal'}
            fontSize={'md'}
            pt={6}
            onClick={onOpen}
            textDecoration="underline"
            _hover={{ textDecoration: 'none' }}
          >
            {isInsuranceUser
              ? 'Confirm account deactivation'
              : 'Continue with cancellation'}
          </Button>
          <Box mt={8}>
            {isInsuranceUser ? (
              <Text mb={1}>
                By deactivating your account, you&apos;ll lose access to:
              </Text>
            ) : (
              <Text mb={1}>
                By cancelling your subscription, you&apos;ll lose access to:
              </Text>
            )}
            <UnorderedList spacing={1}>
              <ListItem>1:1 visits with your providers</ListItem>
              <ListItem>Concierge support and messaging</ListItem>
              <ListItem>Prescription renewals</ListItem>
              <ListItem>
                Exclusive perks from partner brands & discounts on Allara
                supplements
              </ListItem>
            </UnorderedList>
          </Box>
          {discountData && discountData?.length > 0 && (
            <PatientConciergeLanguage />
          )}
          <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <form onSubmit={formik.handleSubmit}>
              <ModalContent>
                <ModalHeader>Your feedback matters</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text mb={2}>
                    Select your reason for{' '}
                    {isInsuranceUser ? 'deactivating' : 'cancelling'}
                  </Text>
                  <FormControl isRequired>
                    <Select
                      placeholder="Select"
                      mb={2}
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <option value="time">Time commitment</option>
                      <option value="price">Price/value</option>
                      <option value="insurance">
                        Insurance coverage/eligibility
                      </option>
                      <option value="labs">Lab or imaging costs</option>
                      <option value="in-person">
                        Moving to in person provider
                      </option>
                      <option value="expectations">
                        Negative experience with provider
                      </option>
                      <option value="pregnant">Pregnant</option>
                      <option value="other">Other</option>
                    </Select>
                  </FormControl>
                  <Text mb={2}>Additional feedback</Text>
                  <Textarea
                    placeholder="Optional feedback"
                    value={shortFormText}
                    onChange={handleTextareaChange}
                  />
                </ModalBody>
                <ModalFooter>
                  <ChakraPrimaryButton type="submit">Save</ChakraPrimaryButton>
                  <Button ml={4} onClick={onClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </>
      )}
    </Box>
  )
}

export default AreYouSureYoudLikeToCancel
