/* eslint-disable react/no-children-prop */
import React from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Center,
  InputGroup,
  InputLeftAddon,
  Checkbox,
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import validator from 'validator'
import { RoutePaths } from 'containers/Core/Routes'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface InitialSignupProps {
  onSubmit: (values: {
    email: string
    phoneNumber: string
    consentToMarketingSMS: string
  }) => void
  initialEmail?: string
  initialPhoneNumber?: string
  isLoading: boolean
}

const InitialSignup: React.FC<InitialSignupProps> = ({
  onSubmit,
  initialEmail,
  initialPhoneNumber,
  isLoading,
}) => {
  const { showConsentToMarketing } = useFlags()
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .min(10, 'Phone number must be 10 digits')
      .max(10, 'Phone number must be 10 digits')
      .test('is-valid-phone', 'Must be a valid phone number', (value) =>
        validator.isMobilePhone(value || '')
      )
      .required('Phone number is required'),
  })

  return (
    <Box>
      <Text
        color="gray.700"
        as="h5"
        fontFamily={'Instrument Serif'}
        fontSize="32px"
      >
        Create your account
      </Text>
      <Text fontSize="sm" mb={6}>
        Please create an account below to save your progress.
      </Text>

      <Formik
        initialValues={{
          email: initialEmail || '',
          phoneNumber: initialPhoneNumber || '',
          consentToMarketingSMS: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, handleChange, values }) => (
          <Form>
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Field as={Input} id="email" name="email" type="email" />
              <ErrorMessage name="email" component={FormErrorMessage} />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="phoneNumber">Phone number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+1" />
                <Field
                  as={Input}
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  maxLength={10}
                  pattern="[0-9]*"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replace(/[^\d]/g, '')
                    e.target.value = value
                    handleChange(e)
                  }}
                  borderLeftRadius={0}
                />
              </InputGroup>
              <ErrorMessage name="phoneNumber" component={FormErrorMessage} />
            </FormControl>
            {showConsentToMarketing && (
              <FormControl>
                <Checkbox
                  id="consentToMarketingSMS"
                  name="consentToMarketingSMS"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.checked
                      ? new Date().toISOString()
                      : ''
                    e.target.value = value
                    handleChange(e)
                  }}
                  size="lg"
                >
                  <Text
                    fontSize="xs"
                    paddingLeft={2}
                    paddingBottom={0}
                    marginBottom={0}
                  >
                    By checking this box, you consent to receive marketing texts
                    from Allara. Consent is not a condition to receiving our
                    products and services. Text STOP at any time to end. Message
                    and data rates may apply.
                  </Text>
                </Checkbox>
              </FormControl>
            )}
            <Button
              type="submit"
              backgroundColor={'var(--blue-dark)'}
              color="white"
              width="full"
              mt={4}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
              }}
              isDisabled={!isValid || !(values.email && values.phoneNumber)}
              isLoading={isLoading}
            >
              Create account
            </Button>
            <Center mt={2}>
              <Text fontSize="md" display="inline">
                Already have an account?{' '}
                <Button
                  variant="link"
                  onClick={() =>
                    (window.location.href =
                      window.location.origin + RoutePaths.LOGIN)
                  }
                  display="inline"
                  height="auto"
                  size="md"
                  minWidth="auto"
                  padding={0}
                  marginInlineStart={0}
                  verticalAlign="baseline"
                >
                  Sign in
                </Button>
              </Text>
            </Center>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default InitialSignup
