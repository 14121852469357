import { ChakraPrimaryButton } from 'components/Button'
import { RoutePaths } from 'containers/Core/Routes'
import { ReactNode, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import useUser from 'hooks/useUser/useUser'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AddIcon } from '@chakra-ui/icons'
import { Amplitude } from 'utils/amplitude.utils'

interface ChatContainerProps {
  children: ReactNode
}

const ChatContainer: React.FC<ChatContainerProps> = ({ children }) => {
  const history = useHistory()
  const { user } = useUser()

  const isCreateNewThread = useMemo(
    () => window.location.pathname === RoutePaths.DASHBOARD_NEW_THREAD,
    [window.location]
  )

  const handleCreateThread = useCallback(() => {
    Amplitude.messageNewClick()
    history.push(RoutePaths.DASHBOARD_NEW_THREAD)
  }, [])

  const isCreateNewMessageButtonDisabled = user?.messagingDeactivated
  const createNewMessageButton = (
    <ChakraPrimaryButton
      onClick={handleCreateThread}
      disabled={isCreateNewMessageButtonDisabled}
      leftIcon={<AddIcon boxSize={2} />}
    >
      New Message
    </ChakraPrimaryButton>
  )

  return (
    <div className="messaging h-100">
      {isCreateNewThread ? (
        <div className="d-none d-lg-flex justify-content-center align-items-center">
          <p className="h1 mb-0 fs-10 text-center">New Message</p>
        </div>
      ) : (
        <div className="d-none d-lg-flex justify-content-between align-items-center">
          <p className="h1 mb-0 fs-14">Messages</p>
          {isCreateNewMessageButtonDisabled ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tool-tip">
                  <div>To enable messaging, please navigate to</div> Account
                  -&gt; Manage membership,
                  <div>or reach out to Concierge@allarahealth.com</div>
                </Tooltip>
              }
            >
              <span>{createNewMessageButton}</span>
            </OverlayTrigger>
          ) : (
            <>{createNewMessageButton}</>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default ChatContainer
