import { Box, Flex } from '@chakra-ui/react'
import { ChakraPrimaryButton } from 'components/Button'
import { Image, Text } from '@chakra-ui/react'

type CTACardProps = {
  imageSrc: string
  clickHandler: () => void
  cardText?: string
  buttonText: string
}

export const CTACard = ({
  imageSrc,
  clickHandler,
  cardText,
  buttonText,
}: CTACardProps) => {
  return (
    <Box
      bg="white"
      borderRadius="8px"
      marginTop="30px"
      marginBottom="30px"
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      p={4}
    >
      <Flex>
        <Image
          src={imageSrc}
          height="100%"
          objectFit="contain"
          paddingTop="1"
        />
        <Box>
          <h1 className="ff-inter-medium fs-6">Keep Going!</h1>
          <Text fontSize="14px">{cardText}</Text>
          <ChakraPrimaryButton
            styleProps={{ size: 'sm' }}
            onClick={clickHandler}
          >
            {buttonText}
          </ChakraPrimaryButton>
        </Box>
      </Flex>
    </Box>
  )
}
