import OnboardingContainer from '../OnboardingContainer'
import { Links } from '../Payment/constants'
import { useCallback, useState } from 'react'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import useUser from 'hooks/useUser/useUser'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'

import './styles.scss'
import { Amplitude } from '../../../utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, Text, Button, Checkbox } from '@chakra-ui/react'
import { Formik, Form as FormikForm } from 'formik'

const ConsentToTelehealth: React.FC = () => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const { handleAcceptTerms, createPatient, user } = useUser()
  const {
    acceptedTerms,
    selectedProgram,
    toggleAcceptedTerms,
    variantLabel,
  } = useOnboardingContext()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { noFeeInsuranceMembership } = useFlags()

  const handleAccept = useCallback(() => {
    if (!acceptedTerms) {
      return
    }

    Amplitude.consentToTelehealth(variantLabel)

    setIsSubmitting(true)

    handleAcceptTerms()
      .then(() => {
        if (
          selectedProgram === PROGRAMS.INSURANCE ||
          selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC ||
          user?.waitlistUser?.selectedProduct.type ===
            PROGRAMS.INSURANCE_DIAGNOSTIC ||
          user?.waitlistUser?.selectedProduct.type === PROGRAMS.INSURANCE
        ) {
          if (noFeeInsuranceMembership) {
            createPatient(PROGRAMS.INSURANCE)
          }

          history.push(RoutePaths.INSURANCE_INFO)
        } else {
          history.push(RoutePaths.HOW_DID_YOU_HEAR)
        }
      })
      .catch(() => {
        setIsSubmitting(false)
        setAlertText(
          'Unable to electronically accept terms. Please refresh the page and try again',
          'An Error Occurred'
        )
      })
  }, [history, acceptedTerms])

  return (
    <OnboardingContainer>
      <Box
        backgroundColor="white"
        borderRadius="lg"
        padding={['16px', '24px']}
        width={['95%', '80%', '60%', '30%']}
        maxWidth="600px"
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text
          fontFamily="Instrument Serif"
          fontSize="32px"
          textAlign="center"
          width="75%"
        >
          Consent To Telehealth and Privacy Policy
        </Text>
        <Formik
          initialValues={{ acceptedTerms: acceptedTerms }}
          onSubmit={handleAccept}
        >
          {({ values, setFieldValue }) => (
            <FormikForm style={{ width: '100%' }}>
              <Box pt={3}>
                <Checkbox
                  isChecked={values.acceptedTerms}
                  onChange={(e) => {
                    setFieldValue('acceptedTerms', e.target.checked)
                    toggleAcceptedTerms()
                  }}
                  size="md"
                >
                  By checking this box you acknowledge that you have received
                  the Allara Medical Group&apos;s{' '}
                  <a
                    href={Links.NoticeOfPrivacyPractices}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    Notice of Privacy Practices
                  </a>{' '}
                  &amp; you have{' '}
                  <a
                    href={Links.TelehealthConsent}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    consented to Telehealth
                  </a>
                </Checkbox>
              </Box>
              <Button
                type="submit"
                width="100%"
                mt={4}
                mb={4}
                backgroundColor={'var(--blue-dark)'}
                color={'white'}
                _hover={{
                  backgroundColor: 'var(--blue-dark-hover)',
                  color: 'white',
                }}
                isDisabled={isSubmitting || !values.acceptedTerms}
                isLoading={isSubmitting}
              >
                Accept
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Box>
    </OnboardingContainer>
  )
}

export default ConsentToTelehealth
