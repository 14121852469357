import {
  Card,
  CardBody,
  Heading,
  Box,
  Text,
  Icon,
  UnorderedList,
  ListItem,
  Flex,
  Spacer,
  useBreakpointValue,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { ReactComponent as InsuranceIcon } from 'assets/images/insurance-plan.svg'
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaCreditCard,
} from 'react-icons/fa'
import useStripePortal from 'hooks/useStripePortal'
import {
  PROGRAMS,
  getProgramName,
  getProgramFeatures,
} from 'utils/program.utils'
import {
  formatDateToString,
  titleCase,
  formatNumberToCurrency,
  formatNumberToDate,
} from 'containers/Core/utils'
import LoadingState from 'components/Spinner'
import { ChakraPrimaryButton } from 'components/Button'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useEffect, useState } from 'react'
import useUser from 'hooks/useUser/useUser'
import RenewMembershipCard from './RenewMembershipCard'

const CardContent = () => {
  const {
    membershipInfo,
    isLoading,
    stripePortalUpdatePaymentLink,
    reactivateCancelledSubscription,
    refetch,
  } = useStripePortal()
  const [discountEndDate, setDiscountEndDate] = useState<Date | null>(null)
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null)

  const history = useHistory()

  useEffect(() => {
    const coupon = membershipInfo?.subscription?.discount?.coupon

    if (membershipInfo?.subscription.currentPeriodEnd && coupon) {
      const discountEndDateAsDate = new Date(
        membershipInfo?.subscription?.currentPeriodEnd * 1000
      )

      if (
        coupon?.duration === 'once' &&
        membershipInfo?.subscription?.interval === 'year'
      ) {
        discountEndDateAsDate.setFullYear(
          discountEndDateAsDate.getFullYear() + 1
        )
      } else if (
        coupon?.duration === 'once' &&
        membershipInfo?.subscription?.interval === 'month'
      ) {
        discountEndDateAsDate.setMonth(discountEndDateAsDate.getMonth() + 1)
      } else {
        discountEndDateAsDate.setMonth(
          discountEndDateAsDate.getMonth() + (coupon?.duration_in_months ?? 0)
        )
      }

      const getDiscount = () => {
        if (!membershipInfo?.subscription?.amount) {
          return 0
        }

        if (!coupon) {
          return membershipInfo?.subscription?.amount
        }

        if (
          coupon.amount_off &&
          coupon.amount_off < membershipInfo?.subscription?.amount
        ) {
          return membershipInfo?.subscription?.amount - coupon.amount_off
        }

        if (coupon.percent_off) {
          return (
            membershipInfo?.subscription?.amount * (coupon.percent_off / 100)
          )
        }

        return membershipInfo?.subscription?.amount
      }

      const discount = getDiscount()

      setDiscountedPrice(discount)
      setDiscountEndDate(discountEndDateAsDate)
    }
  }, [
    membershipInfo?.subscription.amount,
    membershipInfo?.subscription?.discount?.coupon,
    membershipInfo?.subscription?.currentPeriodEnd,
    membershipInfo?.subscription?.interval,
  ])

  // We want to refetch the membership info when the component mounts
  useEffect(() => {
    refetch()
  }, [])

  const reactivateMembership = () => {
    try {
      reactivateCancelledSubscription()
      const optionalToast = {
        title: 'Membership reactivated',
        status: 'success',
      }
      history.push(RoutePaths.DASHBOARD_PROFILE, { optionalToast })
    } catch (error) {
      const optionalToast = {
        title: 'There was an error processing your request',
        description: 'Please reach out to Patient Concierge for assistance.',
        status: 'error',
      }
      history.push(RoutePaths.DASHBOARD_PROFILE, { optionalToast })
      console.error(error)
    }
  }

  const programMapping = () => {
    switch (membershipInfo?.product.productionId) {
      case 'prod_Jiqi4o7NxSmwu3':
        return PROGRAMS.FULL_PROGRAM
      case 'prod_JzTtffer1gzJ8G':
        return PROGRAMS.NUTRITION_ONLY
      case 'prod_MGJOf4kMbWscQd':
        return PROGRAMS.INSURANCE
    }
  }

  const programName = getProgramName(programMapping())
  const programFeatures = getProgramFeatures(programMapping())
  const renewelDate = formatNumberToDate(
    membershipInfo?.subscription?.currentPeriodEnd
  )

  const paymentBrand = membershipInfo?.payment?.brand
  const cancelButtonText = membershipInfo?.subscription.cancelAtPeriodEnd
    ? 'Cancellation scheduled'
    : 'Cancel membership'

  const paymentIconMapping = (() => {
    switch (paymentBrand) {
      case 'visa':
        return FaCcVisa
      case 'mastercard':
        return FaCcMastercard
      case 'amex':
        return FaCcAmex
      case 'discover':
        return FaCcDiscover
      default:
        return FaCreditCard
    }
  })()

  if (isLoading) {
    return <LoadingState />
  }

  const isDiscounted = discountEndDate && discountedPrice

  const renewalText = (
    <>
      {formatNumberToCurrency(membershipInfo?.subscription?.amount)} per
      {membershipInfo?.subscription?.interval === 'month' ? ' month' : ' year'}
    </>
  )

  return (
    <>
      <Box textAlign={'center'}>
        <Icon as={InsuranceIcon} boxSize={10} />
        <Heading as="h3" size="m" fontWeight={'semibold'} mt={4}>
          {programName}
        </Heading>
        {isDiscounted ? (
          <>
            <Text as="s">
              {formatNumberToCurrency(membershipInfo?.subscription?.amount)} per
              {membershipInfo?.subscription?.interval === 'month'
                ? ' month'
                : ' year'}
            </Text>
            <Text>
              {formatNumberToCurrency(discountedPrice)} until{' '}
              {formatDateToString(discountEndDate)}{' '}
            </Text>
          </>
        ) : (
          <Text>{renewalText}</Text>
        )}
      </Box>
      <Box>
        <Text color={'var(--gray-medium)'}>Plan details</Text>
        <Box marginBottom={3}>
          {membershipInfo?.subscription.cancelAtPeriodEnd ? (
            <Text fontStyle="italic" mb={0}>
              Cancels on {formatDateToString(renewelDate)}
            </Text>
          ) : (
            <Text fontStyle="italic" mb={0}>
              Renews on {formatDateToString(renewelDate)} for{' '}
              {isDiscounted
                ? formatNumberToCurrency(discountedPrice)
                : renewalText}
            </Text>
          )}
        </Box>
        <UnorderedList spacing={3}>
          {programFeatures.map((fact, index) => (
            <ListItem key={index}>{fact}</ListItem>
          ))}
        </UnorderedList>
      </Box>
      <Box>
        <Text color={'var(--gray-medium)'}>Payment method</Text>
        <Flex>
          <Icon as={paymentIconMapping} boxSize={6} />
          <Text ml={2}>
            {titleCase(paymentBrand)} •••• {membershipInfo?.payment?.lastFour}
          </Text>
          <Spacer />
          <div>{membershipInfo?.payment.expiration}</div>
        </Flex>
        <a
          href={stripePortalUpdatePaymentLink}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            color={'var(--gray-dark)'}
            variant="link"
            leftIcon={<AddIcon />}
            fontWeight={'normal'}
            fontSize={'sm'}
          >
            Add payment method
          </Button>
        </a>
      </Box>
      <Box mt={8}>
        <ChakraPrimaryButton
          onClick={() => history.push(RoutePaths.MANAGE_MEMBERSHIP_CANCEL)}
          disabled={membershipInfo?.subscription.cancelAtPeriodEnd}
        >
          {cancelButtonText}
        </ChakraPrimaryButton>
        {membershipInfo?.subscription.cancelAtPeriodEnd && (
          <Button
            variant="link"
            color={'var(--gray-dark)'}
            pl={4}
            onClick={reactivateMembership}
          >
            Reactivate membership
          </Button>
        )}
      </Box>
    </>
  )
}

const CurrentMembershipCard = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { user } = useUser()
  const { noSubscription } = useStripePortal()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const product = user?.waitlistUser?.selectedProduct?.type ?? ''

  useEffect(() => {
    if (noSubscription) {
      onOpen()
    }
  }, [noSubscription])

  if (noSubscription) {
    return (
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>No active subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>You do not have an active subscription.</Text>
            <Text mb={2}>
              Please reach out to our{' '}
              <Link href={RoutePaths.DASHBOARD_NEW_THREAD + '?sbj=4'}>
                Patient Concierge
              </Link>{' '}
              team for assistance.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  const content =
    user?.accountStatus === 'CANCELLED' ? (
      <RenewMembershipCard product={product} />
    ) : (
      <CardContent />
    )

  if (isMobile) {
    return <Box m={10}>{content}</Box>
  } else {
    return (
      <Card m={10}>
        <CardBody>{content}</CardBody>
      </Card>
    )
  }
}

export default CurrentMembershipCard
