import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import { useLoginRedirect } from 'hooks/useUser/useLoginRedirect'
import NewMethod from './NewMethod'
import { useSignupFlow } from 'hooks/useUser/useSignupFlow'
import TitleWithBack from 'components/TitleWithBack'
import { Amplitude } from 'utils/amplitude.utils'

interface EmailOTPProps {
  initialEmail: string
  mode?: 'login' | 'signup'
  phoneNumber?: string
  consentToMarketingSMS?: Date
  responderUuid?: string
  variantLabel?: string
  redirectUri?: string
  onBack?: () => void
}

const EmailOTP: React.FC<EmailOTPProps> = ({
  initialEmail,
  mode = 'login',
  phoneNumber,
  consentToMarketingSMS,
  responderUuid,
  variantLabel,
  redirectUri,
  onBack,
}) => {
  const [code, setCode] = useState('')
  const stytchClient = useStytch()
  const toast = useToast()
  const { handleSuccessfulLogin } = useLoginRedirect()
  const [methodId, setMethodId] = useState('')
  const hasRun = useRef(false)
  const [showNewMethod, setShowNewMethod] = useState(false)
  const { handleSignupSuccess } = useSignupFlow()
  const [isLoading, setIsLoading] = useState(false)

  const sendEmailOTP = useCallback(async () => {
    setIsLoading(true)

    try {
      if (mode === 'signup') {
        const response = await stytchClient.otps.email.loginOrCreate(
          initialEmail,
          {
            expiration_minutes: 5,
          }
        )
        setMethodId(response.method_id)
      } else {
        const response = await stytchClient.otps.email.send(initialEmail, {
          expiration_minutes: 5,
        })
        setMethodId(response.method_id)
      }
    } catch (error) {
      if (error instanceof StytchAPIError) {
        let description = error.error_message
        Amplitude.loginErrorShow(error.error_type)

        if (error.error_type === 'email_not_found') {
          setShowNewMethod(true)
          return
        } else if (error.error_type === 'invalid_email') {
          description = 'Please enter a valid email address.'
        } else if (error.error_type === 'duplicate_email') {
          description =
            'There was an error verifying your email. Please reach out to the concierge team from your dashboard for support.'
        }

        toast({
          title: 'Error sending code',
          description: description,
          status: 'error',
          duration: 5000,
        })
        ddLog('error', 'Stytch Error::email OTP send error', {
          errorMessage: error.error_message,
          requestId: error.request_id,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [initialEmail, stytchClient, toast])

  useEffect(() => {
    if (!hasRun.current) {
      sendEmailOTP()
      hasRun.current = true
    }
  }, [sendEmailOTP])

  const verifyCode = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await stytchClient.otps.authenticate(code, methodId, {
        session_duration_minutes: 60,
      })

      if (response.status_code === 200) {
        if (mode === 'signup') {
          await handleSignupSuccess({
            email: initialEmail,
            authId: response.user_id,
            phoneNumber: phoneNumber || '',
            consentToMarketingSMS,
            responderUuid: responderUuid || '',
            variantLabel: variantLabel || '',
            redirectUri: redirectUri || '',
            loginOption: 'otp_email',
          })
        } else {
          Amplitude.userLogsIn('otp_email')
          handleSuccessfulLogin()
        }
      }
    } catch (error) {
      if (error instanceof StytchAPIError) {
        let description = error.error_message

        if (
          error.error_type === 'otp_code_not_found' ||
          error.error_type === 'unable_to_auth_otp_code'
        ) {
          description =
            'The code you entered is invalid. Please try again or resend yourself a code.'
        }

        toast({
          title: 'Error verifying code',
          description: description,
          status: 'error',
          duration: 5000,
        })
        ddLog('error', 'Stytch Error::email OTP verify error', {
          errorMessage: error.error_message,
          requestId: error.request_id,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [code, methodId, stytchClient, toast, handleSuccessfulLogin])

  if (showNewMethod) {
    return <NewMethod newType="sms" />
  }

  const headerText =
    mode === 'signup' ? 'Check your email' : 'Log in with one time password'
  const descriptionText =
    mode === 'signup'
      ? `Please enter the code we sent to ${initialEmail} to finish creating your account. Note this will expire in 5 minutes.`
      : `Please enter the code we sent to ${initialEmail}. Note that it will expire in 5 minutes.`
  const buttonText = mode === 'signup' ? 'Create account' : 'Log in'
  return (
    <Box>
      <TitleWithBack
        title={headerText}
        subtitle={descriptionText}
        onBack={onBack}
      />
      <FormControl mb={4}>
        <FormLabel>Verification Code</FormLabel>
        <Input
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter code"
        />
      </FormControl>
      <Button
        onClick={verifyCode}
        backgroundColor={'var(--blue-dark)'}
        color="white"
        isLoading={isLoading}
        loadingText="Verifying"
        _hover={{
          backgroundColor: 'var(--blue-dark-hover)',
        }}
        width="full"
        mb={4}
        isDisabled={!code}
      >
        {buttonText}
      </Button>
      <Button
        colorScheme={'var(--blue-dark)'}
        variant="link"
        width="full"
        onClick={() => {
          sendEmailOTP()
          toast({
            title: 'Code resent',
            description: 'A new code has been sent to your email',
            status: 'success',
            duration: 10000,
          })
        }}
      >
        Resend code
      </Button>
    </Box>
  )
}

export default EmailOTP
