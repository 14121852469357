import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg'
import { PillButton } from 'components/Button'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import {
  Program,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import {
  PROGRAMS,
  getProgramFeatures,
  getProgramName,
} from 'utils/program.utils'

import './styles.scss'
import { Amplitude } from 'utils/amplitude.utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import usePaymentPlans from 'hooks/usePayment/usePaymentPlans'

interface CompleteProgramCardProps extends ProgramCardProps {
  program: Program
  subTitle?: string
  icon: string
  secondaryInfoTitle?: string
  onSelect?: (program: Program) => void
  description?: string
}

interface ProgramCardProps {
  isRecommended?: boolean
  isMobile?: boolean
}

const ProgramCard: React.FC<CompleteProgramCardProps> = ({
  program,
  subTitle,
  icon,
  isRecommended,
  onSelect,
  description,
}) => {
  const history = useHistory()
  const {
    setSelectedProgram,
    setSelectedPlan,
    variantLabel,
  } = useOnboardingContext()
  const { noFeeInsuranceMembership } = useFlags()
  const { plans } = usePaymentPlans(program)
  const features = useMemo(() => getProgramFeatures(program), [program])

  const chipText = useMemo(() => {
    if (isRecommended) {
      return 'Recommended For You'
    } else if (program === PROGRAMS.MNC) {
      return "What's included"
    }
  }, [program, isRecommended])

  const handleClick = useCallback(() => {
    if (onSelect) {
      onSelect(program)
      return
    }

    setSelectedProgram(program)

    if (plans?.length) {
      setSelectedPlan(plans[0])
    }

    Amplitude.userSelectsProduct(program, variantLabel)

    if (
      (program === PROGRAMS.INSURANCE ||
        program === PROGRAMS.INSURANCE_DIAGNOSTIC) &&
      noFeeInsuranceMembership
    ) {
      history.push(
        `${RoutePaths.CONSENT_TO_TELEHEALTH}${window.location.search}`
      )
    } else {
      history.push(`${RoutePaths.PAYMENT_PLANS}${window.location.search}`)
    }
  }, [window.location.search, noFeeInsuranceMembership, plans])

  return (
    <Flex>
      <Box
        mb={8}
        display="flex"
        flexDirection="column"
        justifyContent={
          program !== PROGRAMS.INSURANCE ? 'space-between' : 'flex-start'
        }
        className="insurance-program-card"
      >
        <Box>
          <Box position="relative" pb={3} overflow="hidden">
            <Box className="curve" />
            <VStack pt={4} className="insurance-program-card-header">
              <Box className="insurance-program-card-icon">
                <Image
                  className={
                    program !== PROGRAMS.INSURANCE ? 'non-insurance-icon' : ''
                  }
                  src={icon}
                  alt={getProgramName(program)}
                />
              </Box>
              <Box>
                <Text as="h2" textAlign="center" fontFamily="Instrument Serif">
                  {getProgramName(program)}
                </Text>
                {subTitle && (
                  <Text
                    textAlign="center"
                    fontSize="sm"
                    fontFamily="Inter-Medium"
                    className="sub-header"
                    marginBottom={6}
                  >
                    {subTitle}
                  </Text>
                )}
              </Box>
              {chipText && <Text className="included-text">{chipText}</Text>}
            </VStack>
          </Box>
          {description && (
            <Text fontSize="sm" textAlign="center" mb={6} mx={2}>
              {description}
            </Text>
          )}

          <Flex flexDirection="column" alignItems="center" mt={6}>
            <Button
              width="90%"
              mb={4}
              backgroundColor="var(--blue-dark)"
              color="white"
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
                color: 'white',
              }}
              onClick={handleClick}
            >
              Select
            </Button>
          </Flex>

          <Box px={4}>
            {features.map((feature, i) => (
              <Flex key={feature + i} alignItems="center" mb={3}>
                <Box className="feature-check-icon">
                  <CheckIcon />
                </Box>
                <Text fontSize="sm" mb={0} className="program-card-feature">
                  {feature}
                </Text>
              </Flex>
            ))}
          </Box>
        </Box>
        {program === PROGRAMS.FULL_PROGRAM && (
          <Flex flexDirection="column" alignItems="center">
            <Text fontSize="10px" px={2} color="var(--gray-dark)">
              We will bill your insurance for services including visits, lab
              work, imaging, and prescription medications.
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

export default ProgramCard
