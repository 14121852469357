import Loader from 'components/Loader'
import { WithAccountProps } from 'hoc/withAccount'
import useActions, {
  isCompleteFormsortIntakeFormAction,
} from 'hooks/useTimeline/useActions'
import { Fragment, memo, useMemo, useState } from 'react'
import ActionCard from './ActionCard'
import useAppointments from 'hooks/useAppointments/useAppointments'
import _ from 'lodash'

const ComingUp: React.FC<WithAccountProps> = (props) => {
  const { isLoading, activeActions, actionDefinitions } = useActions()
  const { appointments } = useAppointments()

  const [maxNumberShown, setMaxNumberShown] = useState<number>(3)
  const [hiddenActions, setHiddenActions] = useState<Set<string>>(new Set())

  const handleShowMore = () => setMaxNumberShown((n) => n + 3)
  const handleShowLess = () => setMaxNumberShown((n) => n - 3)

  const actionsToShow = useMemo(() => activeActions.slice(0, maxNumberShown), [
    activeActions,
    maxNumberShown,
  ]).map((action) => ({ ...action, isHidden: hiddenActions.has(action.id) }))

  const hideAction = (actionId: string) => {
    setHiddenActions((prev) => new Set(prev).add(actionId))
  }

  return (
    <div>
      <p className="h1 py-2 fs-8">To Do</p>
      {!isLoading &&
        activeActions.length >= 1 &&
        actionsToShow.map((action) => {
          const originalActionDefinition = actionDefinitions.find(
            (a) => a.id === action.definition
          )

          const isActiveCompleteIntakeFormAction = isCompleteFormsortIntakeFormAction(
            action,
            originalActionDefinition,
            'active'
          )

          const actionDefinition = isActiveCompleteIntakeFormAction
            ? _.cloneDeep(originalActionDefinition)
            : originalActionDefinition

          if (isActiveCompleteIntakeFormAction && actionDefinition) {
            if (
              action.properties.formUrl.includes('initial-rd') ||
              action.properties.formUrl.includes('nutrition-only')
            ) {
              actionDefinition.title = 'Complete your nutrition intake forms'
              actionDefinition.description =
                'Please complete the following intake forms before meeting with your registered dietitian. This should only take 5 minutes.'
            } else if (action.properties.formUrl.includes('med')) {
              actionDefinition.title = 'Complete your medical intake forms'
              actionDefinition.description =
                'Please complete the following intake forms before meeting with your medical provider. This should only take 5 minutes.'
            } else {
              actionDefinition.title = 'Complete your intake forms'
              actionDefinition.description =
                'Please complete the following intake forms before your visit. This should only take about 5 minutes.'
            }

            actionDefinition.buttonText = 'Complete forms'
            actionDefinition.buttonLink = action.properties.formUrl
          }

          if (actionDefinition && !action.isHidden) {
            return (
              <ActionCard
                key={action.id}
                action={action}
                actionDefinition={actionDefinition}
                {...props}
                appointments={appointments}
                onClickOverride={
                  isActiveCompleteIntakeFormAction
                    ? () => hideAction(action.id)
                    : undefined
                }
              />
            )
          } else {
            return <Fragment key={action.id}></Fragment>
          }
        })}
      {!isLoading && activeActions.length >= 1 && (
        <div className="d-flex justify-content-between">
          <p className="show-more-less-text" onClick={handleShowLess}>
            {maxNumberShown === 3 ? '' : 'Show Less'}
          </p>
          <p className="show-more-less-text" onClick={handleShowMore}>
            {activeActions.length > maxNumberShown ? 'Show More' : ''}
          </p>
        </div>
      )}
      {!isLoading && activeActions.length === 0 && (
        <div>
          <p className="h2 py-1 fs-4">You&apos;re all caught up!</p>
          <p>
            You&apos;ve completed all of your upcoming tasks. When you have new
            tasks, you&apos;ll see them here.
          </p>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  )
}

export default memo(ComingUp)
