import Select from 'components/Select'
import StateSelect from 'components/Select/StateSelect'
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import momentTZ from 'moment-timezone'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import useMember from 'hooks/useUser/useMember'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { PrimaryButton } from 'components/Button'
import { Link } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { formatTimeZone, parseGmtOffset, usTimeZones } from 'utils/time.utils'
import { AppointmentBookStateSelectedEventProps } from 'utils/amplitude.types'
import { Amplitude } from 'utils/amplitude.utils'
import { Button } from '@chakra-ui/react'

const LocationScreen: React.FC<WithAccountProps> = ({ user }) => {
  const { source } = useSourceContext()
  const { member, handleUpdateTimeZone } = useMember()
  const {
    amplitudeBookingMetadata,
    appointment,
    appointmentType,
    isReschedule,
    timeZone,
    nextScreen,
    setTimeZone,
  } = useSchedulingContext()
  const [state, setState] = useState<string | undefined>(undefined)

  const timeZonesOptions = useMemo(() => {
    return usTimeZones()
      .sort((a, b) => parseGmtOffset(b) - parseGmtOffset(a))
      .map((timeZone) => ({ value: timeZone, label: formatTimeZone(timeZone) }))
  }, [])

  // Set default time zone
  useEffect(() => {
    if (!timeZone && member) {
      setTimeZone(member.time_zone ?? momentTZ.tz.guess())
    }
  }, [timeZone, member])

  const handleStateChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const stateCode = e.target.value
      const isMatch = stateCode === user.waitlistUser.state.code
      const amplitudeEventProps: AppointmentBookStateSelectedEventProps = {
        appointment_id: appointment?.id ?? null,
        appointment_type_name: appointmentType?.name ?? '',
        is_reschedule: isReschedule ?? false,
        state_selected: stateCode,
        state_on_file: user.waitlistUser.state.code,
        is_valid_selection: isMatch,
        ...amplitudeBookingMetadata,
      }
      Amplitude.appointmentBookStateSelected(amplitudeEventProps)
      setState(e.target.value)
    },
    [appointment, appointmentType, user, setState]
  )

  const handleTimeZoneChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => setTimeZone(e.target.value),
    [setTimeZone]
  )

  const handleSubmit = useCallback(() => {
    if (timeZone) {
      handleUpdateTimeZone(timeZone)
    }

    nextScreen()
  }, [source, timeZone, user.sourceHealthId])

  const stateExistsAndIsValid = useMemo(
    () => state && user.waitlistUser.state.code === state,
    [state, user.waitlistUser.state.code]
  )

  const isSubmitButtonDisabled = useMemo(
    () => (!timeZone || !stateExistsAndIsValid ? true : false),
    [timeZone, stateExistsAndIsValid]
  )

  const submitButton = (
    <Button
      type="submit"
      width="100%"
      mb={0}
      mt={5}
      backgroundColor="var(--blue-dark)"
      color="white"
      _hover={{
        backgroundColor: 'var(--blue-dark-hover)',
        color: 'white',
      }}
      isDisabled={isSubmitButtonDisabled}
    >
      Next
    </Button>
  )

  return (
    <div className="location screen-container">
      <Form onSubmit={handleSubmit} noValidate>
        <h1 className="ff-inter-medium fs-8">Location</h1>
        {state && user.waitlistUser.state.code !== state && (
          <div className="alert ff-inter fs-2">
            The state you selected does not match what we have on file. Please
            reach out to{' '}
            <Link to={`${RoutePaths.DASHBOARD_NEW_THREAD}?sbj=5`}>
              Patient Concierge
            </Link>
          </div>
        )}
        <StateSelect
          label="Select State"
          value={state}
          onChange={handleStateChange}
          controlId="state"
          containerClassName="state-select"
        />
        {stateExistsAndIsValid && (
          <Select
            label="Select Timezone"
            value={timeZone}
            onChange={handleTimeZoneChange}
            controlId="timezone"
            containerClassName="timezone-select"
            options={timeZonesOptions}
          />
        )}
        <div className="submit-button-container">
          {isSubmitButtonDisabled && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="submit-button-tool-tip">
                  Please ensure you have selected a state and a time zone!
                </Tooltip>
              }
            >
              <span>{submitButton}</span>
            </OverlayTrigger>
          )}
          {!isSubmitButtonDisabled && submitButton}
        </div>
      </Form>
    </div>
  )
}

export default withAccount(LocationScreen)
