"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.allResources = void 0;
const factories_1 = require("../factories");
const Account_1 = require("./Account");
const AppointmentIntakeForm_1 = require("./AppointmentIntakeForm");
const CareTeam_1 = require("./CareTeam");
const Comment_1 = require("./Comment");
const Credentials_1 = require("./Credentials");
const Document_1 = require("./Document");
const Event_1 = require("./Event");
const Field_1 = require("./Field");
const File_1 = require("./File");
const Group_1 = require("./Group");
const Intent_1 = require("./Intent");
const License_1 = require("./License");
const Location_1 = require("./Location");
const Member_1 = require("./Member");
const NotificationPreferences_1 = require("./NotificationPreferences");
const Payer_1 = require("./Payer");
const Queue_1 = require("./Queue");
const Relationship_1 = require("./Relationship");
const Tag_1 = require("./Tag");
const Task_1 = require("./Task");
const TaskDefinition_1 = require("./TaskDefinition");
const User_1 = require("./User");
const Webhook_1 = require("./Webhook");
const CarePlan_1 = require("./clinical/CarePlan");
const Encounter_1 = require("./clinical/Encounter");
const EncounterType_1 = require("./clinical/EncounterType");
const Note_1 = require("./clinical/Note");
const Message_1 = require("./communications/Message");
const Thread_1 = require("./communications/Thread");
const Form_1 = require("./forms/Form");
const FormResponse_1 = require("./forms/FormResponse");
const FormVersion_1 = require("./forms/FormVersion");
const Device_1 = require("./monitoring/Device");
const Measurement_1 = require("./monitoring/Measurement");
const Order_1 = require("./monitoring/Order");
const Appointment_1 = require("./scheduling/Appointment");
const AppointmentType_1 = require("./scheduling/AppointmentType");
const Availability_1 = require("./scheduling/Availability");
const Slot_1 = require("./scheduling/Slot");
exports.allResources = (0, factories_1.resourceNamespace)({
    accounts: (0, factories_1.resourceFactory)(Account_1.AccountResource),
    appointmentIntakeForms: (0, factories_1.resourceFactory)(AppointmentIntakeForm_1.AppointmentIntakeFormResource),
    careTeams: (0, factories_1.resourceFactory)(CareTeam_1.CareTeamResource),
    comments: (0, factories_1.resourceFactory)(Comment_1.CommentResource),
    credentials: (0, factories_1.resourceFactory)(Credentials_1.CredentialResource),
    documents: (0, factories_1.resourceFactory)(Document_1.DocumentResource),
    events: (0, factories_1.resourceFactory)(Event_1.EventResource),
    fields: (0, factories_1.resourceFactory)(Field_1.FieldResource),
    files: (0, factories_1.resourceFactory)(File_1.FileResource),
    groups: (0, factories_1.resourceFactory)(Group_1.GroupResource),
    intents: (0, factories_1.resourceFactory)(Intent_1.IntentResource),
    licenses: (0, factories_1.resourceFactory)(License_1.LicenseResource),
    locations: (0, factories_1.resourceFactory)(Location_1.LocationResource),
    members: (0, factories_1.resourceFactory)(Member_1.MemberResource),
    notificationPreferences: (0, factories_1.resourceFactory)(NotificationPreferences_1.NotificationPreferencesResource),
    payers: (0, factories_1.resourceFactory)(Payer_1.PayerResource),
    queues: (0, factories_1.resourceFactory)(Queue_1.QueueResource),
    relationships: (0, factories_1.resourceFactory)(Relationship_1.RelationshipResource),
    tags: (0, factories_1.resourceFactory)(Tag_1.TagResource),
    tasks: (0, factories_1.resourceFactory)(Task_1.TaskResource),
    taskDefinitions: (0, factories_1.resourceFactory)(TaskDefinition_1.TaskDefinitionResource),
    users: (0, factories_1.resourceFactory)(User_1.UserResource),
    webhooks: (0, factories_1.resourceFactory)(Webhook_1.WebhookResource),
    scheduling: (0, factories_1.resourceNamespace)({
        appointments: (0, factories_1.resourceFactory)(Appointment_1.AppointmentResource),
        appointmentTypes: (0, factories_1.resourceFactory)(AppointmentType_1.AppointmentTypeResource),
        availabilities: (0, factories_1.resourceFactory)(Availability_1.AvailabilityResource),
        slots: (0, factories_1.resourceFactory)(Slot_1.SlotResource),
    }),
    clinical: (0, factories_1.resourceNamespace)({
        carePlans: (0, factories_1.resourceFactory)(CarePlan_1.CarePlanResource),
        encounters: (0, factories_1.resourceFactory)(Encounter_1.EncounterResource),
        encounterTypes: (0, factories_1.resourceFactory)(EncounterType_1.EncounterTypeResource),
        notes: (0, factories_1.resourceFactory)(Note_1.NoteResource),
    }),
    communications: (0, factories_1.resourceNamespace)({
        messages: (0, factories_1.resourceFactory)(Message_1.MessageResource),
        threads: (0, factories_1.resourceFactory)(Thread_1.ThreadResource),
    }),
    monitoring: (0, factories_1.resourceNamespace)({
        devices: (0, factories_1.resourceFactory)(Device_1.DeviceResource),
        measurements: (0, factories_1.resourceFactory)(Measurement_1.MeasurementResource),
        orders: (0, factories_1.resourceFactory)(Order_1.OrderResource),
    }),
    forms: (0, factories_1.resourceNamespace)({
        forms: (0, factories_1.resourceFactory)(Form_1.FormResource),
        formResponses: (0, factories_1.resourceFactory)(FormResponse_1.FormResponseResource),
        formVersions: (0, factories_1.resourceFactory)(FormVersion_1.FormVersionResource),
    }),
});
__exportStar(require("./Account"), exports);
__exportStar(require("./ApiKey"), exports);
__exportStar(require("./AppointmentIntakeForm"), exports);
__exportStar(require("./CareTeam"), exports);
__exportStar(require("./Comment"), exports);
__exportStar(require("./CompoundConditional"), exports);
__exportStar(require("./ConcreteConditional"), exports);
__exportStar(require("./Conditional"), exports);
__exportStar(require("./ContactPoint"), exports);
__exportStar(require("./Document"), exports);
__exportStar(require("./Error"), exports);
__exportStar(require("./Event"), exports);
__exportStar(require("./Field"), exports);
__exportStar(require("./File"), exports);
__exportStar(require("./Group"), exports);
__exportStar(require("./Integration"), exports);
__exportStar(require("./Intent"), exports);
__exportStar(require("./License"), exports);
__exportStar(require("./Location"), exports);
__exportStar(require("./Member"), exports);
__exportStar(require("./NoteContentNode"), exports);
__exportStar(require("./NotificationPreferences"), exports);
__exportStar(require("./Payer"), exports);
__exportStar(require("./Product"), exports);
__exportStar(require("./Question"), exports);
__exportStar(require("./Queue"), exports);
__exportStar(require("./Relationship"), exports);
__exportStar(require("./shared"), exports);
__exportStar(require("./Tag"), exports);
__exportStar(require("./Task"), exports);
__exportStar(require("./TaskDefinition"), exports);
__exportStar(require("./TaskQueueEntry"), exports);
__exportStar(require("./ThreadStatus"), exports);
__exportStar(require("./User"), exports);
__exportStar(require("./Webhook"), exports);
__exportStar(require("./WebhookEvents"), exports);
__exportStar(require("./clinical/CarePlan"), exports);
__exportStar(require("./clinical/Encounter"), exports);
__exportStar(require("./clinical/EncounterType"), exports);
__exportStar(require("./clinical/Note"), exports);
__exportStar(require("./clinical/NoteVersion"), exports);
__exportStar(require("./communications/Channel"), exports);
__exportStar(require("./communications/Message"), exports);
__exportStar(require("./communications/Thread"), exports);
__exportStar(require("./Credentials"), exports);
__exportStar(require("./forms/Form"), exports);
__exportStar(require("./forms/FormResponse"), exports);
__exportStar(require("./forms/FormVersion"), exports);
__exportStar(require("./monitoring/Device"), exports);
__exportStar(require("./monitoring/DeviceModel"), exports);
__exportStar(require("./monitoring/Measurement"), exports);
__exportStar(require("./monitoring/Order"), exports);
__exportStar(require("./scheduling/Appointment"), exports);
__exportStar(require("./scheduling/AppointmentType"), exports);
__exportStar(require("./scheduling/Availability"), exports);
__exportStar(require("./scheduling/RecurringSlot"), exports);
__exportStar(require("./scheduling/Slot"), exports);
